import React, { useState } from 'react';
import {
    Card,
    CardBody,
    Typography,
    Chip,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
    Button,
    Alert,
} from '@material-tailwind/react';
import { Link } from 'react-router-dom';
import { Order } from '../../types';
import {
    statusBadgeColor,
    paymentStatusColor,
    paymentMethodBadgeColor,
    paymentMethodIcon,
    statusOptions,
    paymentStatusOptions,
    paymentMethodOptions,
} from '../../utils';
import dayjs from 'dayjs';
import {t} from "i18next";

interface Props {
    orders: Order[];
    isAdmin: boolean;
    onOrderUpdate: (orderId: string, field: string, value: string) => Promise<void>;
}

const OrdersCards: React.FC<Props> = ({ orders, isAdmin, onOrderUpdate }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
    const [fieldToUpdate, setFieldToUpdate] = useState<string | null>(null);
    const [updating, setUpdating] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const openModal = (field: string, order: Order) => {
        setFieldToUpdate(field);
        setSelectedOrder(order);
        setIsModalOpen(true);
        setError(null);
    };

    const handleUpdate = async (newValue: string) => {
        if (!selectedOrder || !fieldToUpdate) return;

        setUpdating(true);
        setError(null);

        try {
            await onOrderUpdate(selectedOrder.id, fieldToUpdate, newValue);
            setIsModalOpen(false);
        } catch {
            setError('Failed to update order. Please try again.');
        } finally {
            setUpdating(false);
        }
    };

    const getOptionsForField = () => {
        switch (fieldToUpdate) {
            case 'status':
                return statusOptions;
            case 'paymentStatus':
                return paymentStatusOptions;
            case 'paymentMethod':
                return paymentMethodOptions;
            default:
                return [];
        }
    };

    return (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
            {orders.map((order) => (
                <Card key={order.id} className="flex flex-col">
                    <CardBody className="flex-1 flex flex-col justify-between">
                        {/**/}
                        <div className="flex items-center justify-between mb-4">
                            <Link to={`/orders/${order.id}`} className="text-primary">
                                <Typography variant="h4" className="font-bold">
                                    #{order.orderNumber}
                                </Typography>
                            </Link>

                            <Typography>
                                {dayjs(order.createdAt).fromNow()}
                            </Typography>
                        </div>
                        <div className={`pt-4`}>
                            <Typography variant="body2" className="mb-2 mt-4">
                                <strong>{t('common.restaurant')}:</strong>{' '}
                                <Link
                                    to={`/restaurants/${order.restaurantId}`}
                                    className="text-primary underline"
                                >
                                    {order.restaurantName}
                                </Link>
                            </Typography>
                            <Typography variant="body2" className="mb-2">
                                <strong>{t('common.location')}:</strong>{' '}
                                <Link
                                    to={`/locations/${order.locationId}`}
                                    className="text-primary underline"
                                >
                                    {order.locationName}
                                </Link>
                            </Typography>

                            <div className="chips flex flex-col lg:flex-row gap-4 my-6 justify-between">
                                <Chip
                                    value={`${order.status.replace(/_/g, ' ').replace(/\b\w/g, (l) => l.toUpperCase())}`}
                                    color={statusBadgeColor(order.status)}
                                    className="text-sm font-semibold cursor-pointer text-center lg:w-1/3"
                                    onClick={() => isAdmin && openModal('status', order)}
                                />

                                <Chip
                                    value={`${order.paymentStatus.replace(/\b\w/g, (l) => l.toUpperCase())}`}
                                    color={paymentStatusColor(order.paymentStatus)}
                                    className="text-sm font-semibold cursor-pointer text-center lg:w-1/3"
                                    onClick={() => isAdmin && openModal('paymentStatus', order)}
                                />

                                <Chip
                                    value={order.paymentMethod.replace(/\b\w/g, (l) => l.toUpperCase())}
                                    color={paymentMethodBadgeColor(order.paymentMethod)}
                                    icon={paymentMethodIcon(order.paymentMethod)}
                                    className="text-sm font-semibold cursor-pointer text-center lg:w-1/3"
                                    onClick={() => isAdmin && openModal('paymentMethod', order)}
                                />
                            </div>
                        </div>
                    </CardBody>
                </Card>
            ))}

            {orders.length === 0 && (
                <div className="flex justify-center items-center w-full mt-8">
                    <Card
                        className="flex flex-col items-center justify-center p-6 shadow-md bg-white text-center max-w-md">
                        <div className="text-gray-400 mb-4">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-16 w-16"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth={2}
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M3 3v18h18M3 7h18M7 7v14m10-14v14M5 3h14M3 5h18"
                                />
                            </svg>
                        </div>
                        <Typography variant="h6" className="text-gray-700 mb-2">
                            No Orders Available
                        </Typography>
                        <Typography variant="body2" className="text-gray-500">
                            Orders will appear here once placed. Check back later!
                        </Typography>
                    </Card>
                </div>
            )}

            {/* Update Status Modal */}
            <Dialog size="sm" open={isModalOpen} handler={() => setIsModalOpen(false)}>
                <DialogHeader>
                    Update {fieldToUpdate?.replace(/([A-Z])/g, ' $1').toLowerCase()}
                </DialogHeader>
                <DialogBody divider>
                    {error && (
                        <Alert color="red" className="mb-4">
                            {error}
                        </Alert>
                    )}
                    <div className="flex flex-col gap-3">
                        {getOptionsForField().map((option) => (
                            <Button
                                key={option.value}
                                color={option.color}
                                variant="gradient"
                                onClick={() => handleUpdate(option.value)}
                                disabled={updating}
                                className="normal-case"
                            >
                                {option.label}
                            </Button>
                        ))}
                    </div>
                </DialogBody>
                <DialogFooter>
                    <Button
                        variant="outline"
                        color="red"
                        onClick={() => setIsModalOpen(false)}
                        disabled={updating}
                    >
                        Cancel
                    </Button>
                </DialogFooter>
            </Dialog>
        </div>
    );
};

export default OrdersCards;
