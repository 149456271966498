import React, { useEffect, useState } from 'react';
import { Typography, Button, Alert } from '@material-tailwind/react';
import { PlusIcon } from '@heroicons/react/24/outline';
import api from '../../services/api';
import {Link, useNavigate} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { enforceCardView, setViewMode } from '../../store/slices/viewSlice';
import OrdersTable from '../../components/Tables/OrdersTable';
import ViewModeToggle from '../../components/Common/ViewModeToggle';
import { Order } from '../../types/Order';
import OrdersCards from "../../components/Cards/OrdersCards.tsx";
import {t} from "i18next";

const OrderList: React.FC = () => {
    const [orders, setOrders] = useState<Order[]>([]);
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();
    const user = useSelector((state: RootState) => state.auth.user);
    const userRole = useSelector((state: RootState) => state.auth.user?.role?.name);
    const viewMode = useSelector((state: RootState) => state.view.mode);

    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [updateError, setUpdateError] = useState<string | null>(null);

    const isAdmin = userRole === 'Admin';

    useEffect(() => {
        const fetchOrders = async () => {
            let url = '/orders';

            if (userRole === 'RestaurantManager') {
                const restaurantId = user?.restaurant?.id;
                url = `/restaurants/${restaurantId}/orders`;
            }

            try {
                const response = await api.get(url);
                setOrders(response.data);
            } catch (error) {
                console.error('Error fetching orders:', error);
            }
        };

        fetchOrders();
    }, [user, userRole]);

    useEffect(() => {
        const storedViewMode = localStorage.getItem('viewMode');
        if (storedViewMode === 'card' || storedViewMode === 'list') {
            dispatch(setViewMode(storedViewMode));
        } else {
            dispatch(setViewMode('list'));
        }

        const handleResize = () => {
            if (window.innerWidth < 1024) {
                dispatch(enforceCardView());
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [dispatch]);

    const handleFieldUpdate = async (id: string, field: string, value: string) => {
        let endpoint = '';
        let payload: Record<string, string> = {};

        switch (field) {
            case 'status':
                endpoint = `/orders/${id}/update/status`;
                payload = { status: value };
                break;
            case 'paymentStatus':
                endpoint = `/orders/${id}/update/payment-status`;
                payload = { paymentStatus: value };
                break;
            case 'paymentMethod':
                endpoint = `/orders/${id}/update/payment-method`;
                payload = { paymentMethod: value };
                break;
            default:
                return;
        }

        try {
            await api.put(endpoint, payload);
            setOrders((prevOrders) =>
                prevOrders.map((order) =>
                    order.id === id ? { ...order, [field]: value } : order
                )
            );
            setSuccessMessage(`Successfully updated ${field.replace(/([A-Z])/g, ' $1').toLowerCase()}.`);
            setTimeout(() => setSuccessMessage(null), 3000);
        } catch (error) {
            setUpdateError('Failed to update. Please try again.');
            setTimeout(() => setUpdateError(null), 3000);
        }
    };

    return (
        <div className="container mx-auto px-4 py-6">
            <div className="mb-4">
                {successMessage && <Alert color="green">{successMessage}</Alert>}
                {updateError && <Alert color="red">{updateError}</Alert>}
            </div>

            <div className="flex flex-col sm:flex-row justify-between items-center mb-6">
                <Typography variant="h4" className="text-neutralDark">
                    {t('sections.orders')}
                </Typography>
            </div>

            {viewMode === 'card' ? (
                <OrdersCards orders={orders} isAdmin={isAdmin} onOrderUpdate={handleFieldUpdate} />
            ) : (
                <OrdersTable
                    orders={orders}
                    isAdmin={isAdmin}
                    setOrders={setOrders}
                    onFieldUpdate={handleFieldUpdate}
                />
            )}
        </div>
    );
};

export default OrderList;
