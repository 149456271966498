import {useEffect, useState} from "react";

export const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(
        window.matchMedia('(max-width: 1024px)').matches
    );

    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 1024px)');
        const handler = (event: MediaQueryListEvent) => setIsMobile(event.matches);
        mediaQuery.addEventListener('change', handler);

        return () => mediaQuery.removeEventListener('change', handler);
    }, []);

    return isMobile;
};
