// File: src/pages/NotFound.tsx
import React from 'react'
import { Typography, Button } from '@material-tailwind/react'
import { useNavigate } from 'react-router-dom'

const NotFound: React.FC = () => {
    const navigate = useNavigate()

    return (
        <div className="flex flex-col items-center justify-center h-full">
            <Typography variant="h2" color="blue-gray" className="mb-4">
                404
            </Typography>
            <Typography variant="h5" color="gray" className="mb-6">
                Page Not Found
            </Typography>
            <Button onClick={() => navigate('/')} color="blue">
                Go to Dashboard
            </Button>
        </div>
    )
}

export default NotFound
