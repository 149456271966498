// src/pages/Locations/components/LocationStats.tsx

import React from 'react';
import { Card, CardBody, Typography } from '@material-tailwind/react';
import {
    ShoppingBagIcon,
    CurrencyDollarIcon,
    ChartBarIcon,
    DocumentCheckIcon
} from '@heroicons/react/24/outline';
import { Order, Restaurant } from '../../../types';
import PriceTag from "../../../components/Common/Price.tsx";

interface Stats {
    totalOrders: number;
    totalRevenue: number;
    averageOrderValue: number;
    menuItemsSold: number;
}

interface DateRange {
    startDate: Date;
    endDate: Date;
}

type TimeRange = 'today' | 'yesterday' | 'last7days' | 'last30days' | 'all';

interface Props {
    restaurant: Restaurant;
    orders: Order[];
    timeRange: TimeRange;
    dateRange: DateRange;
}

const LocationStats: React.FC<Props> = ({ restaurant, orders, timeRange, dateRange }) => {
    const calculateStats = (): Stats => {
        if (!orders.length) {
            return {
                totalOrders: 0,
                totalRevenue: 0,
                averageOrderValue: 0,
                menuItemsSold: 0
            };
        }

        const filteredOrders = orders.filter(order => {
            const orderDate = new Date(order.createdAt);
            return orderDate >= dateRange.startDate && orderDate <= dateRange.endDate;
        });

        const revenue = filteredOrders.reduce((sum, order) => sum + Number(order.totalAmount), 0);
        const menuItemsSold = filteredOrders.reduce((sum, order) => sum + (order.orderItems?.length || 0), 0);

        return {
            totalOrders: filteredOrders.length,
            totalRevenue: revenue,
            averageOrderValue: filteredOrders.length ? revenue / filteredOrders.length : 0,
            menuItemsSold: menuItemsSold
        };
    };

    const stats = calculateStats();

    const statCards = [
        {
            title: 'Revenue',
            value: <PriceTag
                item={{
                    price: stats.totalRevenue.toFixed(2),
                    restaurant: restaurant as any
                }}
                style="font-bold text-lg"
            />,
            icon: CurrencyDollarIcon,
            color: 'text-green-500',
            bgColor: 'bg-green-50',
        },
        {
            title: 'Avg. Order Value',
            value: <PriceTag
                item={{
                    price: stats.averageOrderValue.toFixed(2),
                    restaurant: restaurant as any
                }}
                style="font-bold text-lg"
            />,
            icon: ChartBarIcon,
            color: 'text-purple-500',
            bgColor: 'bg-purple-50',
        },
        {
            title: 'Items Sold',
            value: stats.menuItemsSold.toString(),
            icon: DocumentCheckIcon,
            color: 'text-teal-500',
            bgColor: 'bg-teal-50',
        },
        {
            title: 'Total Orders',
            value: stats.totalOrders.toString(),
            icon: ShoppingBagIcon,
            color: 'text-blue-500',
            bgColor: 'bg-blue-50',
        }
    ];

    return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-8">
            {statCards.map((stat) => (
                <Card key={stat.title} className="shadow-sm">
                    <CardBody className="p-4">
                        <div className="flex items-center gap-4 w-full">
                            <div className={`p-3 rounded-xl ${stat.bgColor}`}>
                                <stat.icon className={`h-6 w-6 ${stat.color}`} />
                            </div>
                            <div className="flex flex-row justify-between w-full">
                                <Typography className="text-gray-600 text-sm">
                                    {stat.title}
                                </Typography>
                                <Typography className="font-bold text-xl">
                                    {stat.value}
                                </Typography>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            ))}
        </div>
    );
};

export default LocationStats;
