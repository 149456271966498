import React from 'react';
import {Card, Typography, Button} from '@material-tailwind/react';
import {ChevronLeftIcon, PencilIcon} from '@heroicons/react/24/outline';
import {useNavigate} from 'react-router-dom';
import {Location} from '../types';

interface Props {
    location: Location;
    isAdmin: boolean;
}

const LocationHeader: React.FC<Props> = ({location, isAdmin}) => {
    const navigate = useNavigate();

    return (
        <div className="mb-8 flex flex-col gap-6">
            {/* Navigation and Title */}
            <div className="flex justify-between items-center  flex-col lg:flex-row">
                <div className="buttons flex gap-4 flex-col lg:flex-row mb-4 lg:mb-0">
                    <Button
                        variant="text"
                        className="flex items-center gap-2 text-neutralLight  hover:text-neutralDark"
                        onClick={() => navigate(`/restaurants/${location.restaurantId}`)}
                    >
                        <ChevronLeftIcon className="h-4 w-4"/>
                        Back to Restaurant
                    </Button>
                    <Button
                        variant="text"
                        className="flex items-center gap-2 text-neutralLight bg-blue-500 hover:text-neutralDark"
                        onClick={() => navigate(`/locations`)}
                    >
                        <ChevronLeftIcon className="h-4 w-4"/>
                        Back to Locations
                    </Button>
                </div>
                {isAdmin && (
                    <Button
                        className="flex items-center gap-2 bg-primary text-neutralLight hover:text-neutralDark"
                        variant={`text`}
                        onClick={() => navigate(`/locations/edit/${location.id}`)}
                    >
                        <PencilIcon className="h-4 w-4"/>
                        Edit Location
                    </Button>
                )}
            </div>

            {/* Location Info */}
            <Card className="p-6">
                <div className="flex flex-col md:flex-row justify-between gap-6">
                    <div className="space-y-2 text-left">
                        <Typography variant="h3" className="text-2xl font-bold text-gray-800">
                            {location.restaurant.name}
                        </Typography>
                        <Typography variant="h4" className="text-2xl font-bold text-gray-800">
                            {location.name}
                        </Typography>
                        <Typography className="text-gray-600">
                            {location.address}
                        </Typography>
                        <Typography className="text-gray-600">
                            Phone: {location.phoneNumber}
                        </Typography>
                    </div>
                </div>
            </Card>
        </div>
    );
};

export default LocationHeader;
