import { StylesConfig } from 'react-select';
import { SelectOption } from './types';

export const selectStyles: StylesConfig<SelectOption, true> = {
    control: (base) => ({
        ...base,
        borderColor: '#e5e7eb',
        boxShadow: 'none',
        minHeight: '42px',
        '&:hover': {
            borderColor: '#d1d5db'
        }
    }),
    menu: (base) => ({
        ...base,
        zIndex: 50
    }),
    multiValue: (base) => ({
        ...base,
        backgroundColor: '#e5e7eb'
    }),
    multiValueLabel: (base) => ({
        ...base,
        color: '#374151',
        fontSize: '0.875rem'
    }),
    multiValueRemove: (base) => ({
        ...base,
        ':hover': {
            backgroundColor: '#d1d5db',
            color: '#374151'
        }
    }),
    placeholder: (base) => ({
        ...base,
        color: '#9CA3AF'
    }),
    input: (base) => ({
        ...base,
        color: '#374151'
    }),
    option: (base, state) => ({
        ...base,
        backgroundColor: state.isSelected ? '#2563EB' : state.isFocused ? '#DBEAFE' : 'white',
        color: state.isSelected ? 'white' : '#374151',
        ':active': {
            backgroundColor: state.isSelected ? '#2563EB' : '#DBEAFE'
        }
    })
};
