// File: src/routes/index.tsx
import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Layout from '../components/Layout/Layout';
import Dashboard from '../pages/Dashboard';
import Login from '../pages/Login';
import Register from '../pages/Register';
import NotFound from '../pages/NotFound';
import RestaurantList from '../pages/Restaurants/RestaurantList';
import RestaurantDetail from '../pages/Restaurants/RestaurantDetail.tsx';
import RestaurantForm from '../pages/Restaurants/RestaurantForm';
import LocationList from '../pages/Locations/LocationList';
import LocationDetail from '../pages/Locations/LocationDetail';
import LocationForm from '../pages/Locations/LocationForm';
import OrderList from '../pages/Orders/OrderList';
import OrderDetail from '../pages/Orders/OrderDetail';
import OrderForm from '../pages/Orders/OrderForm';
import UserList from '../pages/Users/UserList';
import UserDetail from '../pages/Users/UserDetail';
import UserForm from '../pages/Users/UserForm';
import MenuList from '../pages/Menus/MenuList';
import MenuForm from '../pages/Menus/MenuForm';
import MenuItemList from '../pages/MenuItems/MenuItemList';
import MenuItemForm from '../pages/MenuItems/MenuItemForm';
import PrivateRoute from '../utils/PrivateRoute';
import UserProfile from "../pages/Profile/UserProfile.tsx";
import RoleGuard from "../components/Guards/RoleGuard.tsx";
import RoleProtectedRoute from "../components/RoleProtectedRoute.tsx";
import MenuItemDetail from "../pages/MenuItems/MenuItemDetail.tsx";
import MenuDetail from "../pages/Menus/MenuDetail.tsx";

const AppRoutes: React.FC = () => (
    <Router>
        <Routes>
            <Route path="/login" element={<Login/>}/>
            <Route path="/register" element={<Register/>}/>
            <Route
                path="/"
                element={
                    <PrivateRoute>
                        <Layout/>
                    </PrivateRoute>
                }
            >
                <Route index element={<Dashboard/>}/>

                <Route path="restaurants">
                    <Route
                        index
                        element={<RoleProtectedRoute allowedRoles={['Admin']} Component={RestaurantList} />}
                    />
                    <Route path=":id" element={<RestaurantDetail/>}/>
                    <Route
                        path="create"
                        element={<RoleProtectedRoute allowedRoles={['Admin']} Component={RestaurantForm} />}
                    />
                    <Route
                        path="edit/:id"
                        element={<RoleProtectedRoute allowedRoles={['Admin']} Component={RestaurantForm} />}
                    />
                </Route>

                <Route path="locations">
                    <Route index element={<LocationList/>}/>
                    <Route path="create" element={<LocationForm/>}/>
                    <Route path="edit/:id" element={<LocationForm/>}/>
                    <Route path=":id" element={<LocationDetail/>}/>
                </Route>
                <Route path="menus">
                    <Route index element={<MenuList/>}/>
                    <Route path="create" element={<MenuForm/>}/>
                    <Route path="edit/:menuId" element={<MenuForm/>}/>
                    <Route path=":id" element={<MenuDetail/>}/>
                </Route>
                <Route path="menu-items">
                    <Route index element={<MenuItemList/>}/>
                    <Route path="create" element={<MenuItemForm/>}/>
                    <Route path="edit/:itemId" element={<MenuItemForm/>}/>
                    <Route path=":id" element={<MenuItemDetail/>}/>
                </Route>
                <Route path="orders">
                    <Route index element={<OrderList/>}/>
                    <Route path="create" element={<OrderForm/>}/>
                    <Route path="edit/:id" element={<OrderForm/>}/>
                    <Route path=":id" element={<OrderDetail/>}/>
                </Route>
                <Route path="users">
                    <Route index element={<UserList/>}/>
                    <Route path="create" element={<UserForm/>}/>
                    <Route path="edit/:id" element={<UserForm/>}/>
                    <Route path=":id" element={<UserDetail/>}/>
                </Route>
                <Route path="profile" element={<UserProfile/>}/>
                <Route path="*" element={<NotFound/>}/>
            </Route>
        </Routes>
    </Router>
);

export default AppRoutes;
