import React from 'react';
import { Card, CardBody, Typography, Button, Spinner } from '@material-tailwind/react';
import { PlusIcon, ClipboardDocumentListIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import { DetailedMenu } from '../../../types';
import ActionButtons from '../../../components/Tables/ActionButtons';
import { Alert } from '@material-tailwind/react';
import {getTranslation} from "../../../utils/translation.utils.tsx";

interface Props {
    activeMenu: DetailedMenu | null;
    isAdmin: boolean;
    onChangeMenu?: () => void;
    setActiveMenu: (menu: DetailedMenu | null) => Promise<void>;
    isLoading: boolean;
    error: string | null;
    currentLanguage: string;
}

const LocationActiveMenu: React.FC<Props> = ({
                                                 activeMenu,
                                                 isAdmin,
                                                 onChangeMenu,
                                                 setActiveMenu,
                                                 isLoading,
                                                 error,
                                                 currentLanguage
                                             }) => {

    if (isLoading) {
        return (
            <div className="mb-8">
                <div className="flex justify-between items-center mb-4">
                    <Typography variant="h5" className="text-gray-800 font-bold">
                        Current Menu
                    </Typography>
                </div>
                <Card className="shadow-sm">
                    <CardBody className="p-6 flex justify-center items-center min-h-[200px]">
                        <Spinner className="h-8 w-8" />
                    </CardBody>
                </Card>
            </div>
        );
    }

    if (error) {
        return (
            <div className="mb-8">
                <div className="flex justify-between items-center mb-4">
                    <Typography variant="h5" className="text-gray-800 font-bold">
                        Current Menu
                    </Typography>
                </div>
                <Alert color="red">{error}</Alert>
            </div>
        );
    }

    return (
        <div className="mb-8">
            <div className="flex justify-between items-center mb-4">
                <Typography variant="h5" className="text-gray-800 font-bold">
                    Current Menu
                </Typography>
                {isAdmin && onChangeMenu && (
                    <Button
                        className="flex items-center gap-2 bg-primary text-sm md:text-base"
                        onClick={onChangeMenu}
                        size="sm"
                    >
                        <PlusIcon className="h-4 w-4" />
                        Change Menu
                    </Button>
                )}
            </div>

            {activeMenu ? (
                <Card className="shadow-sm">
                    <CardBody className="p-6">
                        <div className="flex flex-col md:flex-row md:justify-between md:items-start">
                            <div className="space-y-4 flex-grow">
                                <div>
                                    <Typography
                                        variant="h6"
                                        className="text-lg md:text-xl font-semibold"
                                    >
                                        {getTranslation(
                                            activeMenu.translations,
                                            'name',
                                            activeMenu.name,
                                            currentLanguage
                                        )}
                                    </Typography>
                                    <Typography className="text-gray-600 text-sm md:text-base">
                                        {getTranslation(
                                            activeMenu.translations,
                                            'description',
                                            activeMenu.description || 'No description available',
                                            currentLanguage
                                        )}
                                    </Typography>
                                </div>

                                {/* Categories Summary */}
                                {activeMenu.categories && activeMenu.categories.length > 0 && (
                                    <div className="mt-4">
                                        <Typography
                                            variant="small"
                                            className="font-medium text-gray-700 mb-2"
                                        >
                                            Categories ({activeMenu.categories.length})
                                        </Typography>
                                        <div className="flex flex-wrap gap-2">
                                            {activeMenu.categories.map(category => (
                                                <div
                                                    key={category.id}
                                                    className="px-3 py-1 bg-blue-gray-50 rounded-full text-sm text-blue-gray-700"
                                                >
                                                    {getTranslation(
                                                        category.translations,
                                                        'name',
                                                        category.name,
                                                        currentLanguage
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}

                                {/* Restaurant Info if available */}
                                {activeMenu.restaurant && (
                                    <div className="mt-4">
                                        <Link
                                            to={`/restaurants/${activeMenu.restaurant.id}`}
                                            className="flex items-center gap-2 text-primary hover:text-primary-dark text-sm md:text-base"
                                        >
                                            <ClipboardDocumentListIcon className="h-4 w-4" />
                                            <Typography variant="small" className="font-medium">
                                                {getTranslation(
                                                    activeMenu.restaurant.translations,
                                                    'name',
                                                    activeMenu.restaurant.name,
                                                    currentLanguage
                                                )}
                                            </Typography>
                                        </Link>
                                    </div>
                                )}
                            </div>

                            {isAdmin && (
                                <div className="flex-shrink-0 mt-4 md:mt-0 md:ml-4">
                                    <ActionButtons
                                        id={activeMenu.id}
                                        name={getTranslation(
                                            activeMenu.translations,
                                            'name',
                                            activeMenu.name,
                                            currentLanguage
                                        )}
                                        type="menu"
                                        setItems={async updater => {
                                            if (typeof updater === 'function') {
                                                const newMenu = updater(activeMenu);
                                                await setActiveMenu(newMenu);
                                            } else {
                                                await setActiveMenu(updater);
                                            }
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    </CardBody>
                </Card>
            ) : (
                <Card className="shadow-sm">
                    <CardBody className="p-6">
                        <div className="flex flex-col items-center justify-center space-y-4">
                            <ClipboardDocumentListIcon className="h-12 w-12 text-gray-400" />
                            <Typography className="text-gray-500 text-center text-sm md:text-base">
                                No active menu available
                            </Typography>
                            {isAdmin && onChangeMenu && (
                                <Button
                                    variant="text"
                                    color="blue"
                                    className="mt-2 text-sm md:text-base"
                                    onClick={onChangeMenu}
                                >
                                    Select a menu
                                </Button>
                            )}
                        </div>
                    </CardBody>
                </Card>
            )}
        </div>
    );
};

export default LocationActiveMenu;
