// File: src/utils/paymentStatus.ts

import { ChipProps } from '@material-tailwind/react';
import { PaymentStatus } from '../types';

export const paymentStatusColor = (status: PaymentStatus): ChipProps['color'] => {
    switch (status) {
        case 'paid':
            return 'green';
        case 'unpaid':
            return 'red';
        default:
            return 'gray';
    }
};

export const paymentStatusOptions = [
    { label: 'Paid', value: 'paid', color: 'green' },
    { label: 'Unpaid', value: 'unpaid', color: 'red' },
] as const;
