// File: src/components/layout/Layout.tsx
import React from 'react'
import { Outlet } from 'react-router-dom'
import Navbar from '../Common/Navbar.tsx'
import Sidebar from '../Common/Sidebar.tsx'
import Footer from '../Common/Footer.tsx'

const Layout: React.FC = () => {
    return (
        <div className="flex h-screen bg-gray-100">
            <Sidebar />
            <div className="flex flex-col flex-1">
                <Navbar />
                <main className="bg-gray-100 flex-1 p-4 overflow-auto text-gray-700">
                    <Outlet />
                </main>
                <Footer />
            </div>
        </div>
    )
}

export default Layout
