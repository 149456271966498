import React, { useEffect, useState, useMemo } from 'react';
import {
    Typography,
    Button,
    Card,
    CardBody,
} from '@material-tailwind/react';
import {
    PlusIcon,
} from '@heroicons/react/24/outline';
import api from '../../services/api';
import { Link, useNavigate } from 'react-router-dom';
import { MenuItem } from '../../types/MenuItem';
import ViewModeToggle from '../../components/Common/ViewModeToggle';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { setViewMode, enforceCardView } from '../../store/slices/viewSlice';
import MenuItemsTable from "../../components/Tables/MenuItemsTable.tsx";
import MenuItemsCards from "../../components/Cards/MenuItemsCards.tsx";
import {useIsMobile} from "../../hooks/useIsMobile.tsx";
import {t} from "i18next";

interface Translation {
    language: string;
    name: string;
    description: string;
}

const MenuItemList: React.FC = () => {
    const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();
    const user = useSelector((state: RootState) => state.auth.user);
    const userRole = useSelector((state: RootState) => state.auth.user?.role?.name);
    const viewMode = useSelector((state: RootState) => state.view.mode);

    const isAdmin = userRole === 'Admin';
    const isMobile = useIsMobile()

    useEffect(() => {
        if (isMobile) {
            dispatch(enforceCardView());
        }
    }, [isMobile, dispatch]);

    useEffect(() => {
        const fetchMenuItems = async () => {
            const restaurantId = user?.restaurant?.id;
            let url = '/menu-items';
            if (userRole !== 'Admin' && restaurantId) {
                url = `/restaurants/${restaurantId}/menu-items`;
            }

            try {
                const response = await api.get(url);
                console.log(menuItems)
                setMenuItems(response.data);
            } catch (error) {
                console.error('Error fetching menu items:', error);
            }
        };

        fetchMenuItems();
    }, [user, userRole]);

    return (
        <div className="container mx-auto px-4 py-6">
            <div className="flex flex-col sm:flex-row justify-between items-center mb-6">
                <div className="flex flex-col sm:flex-row items-start sm:items-center gap-4 w-full">
                    <Typography variant="h4" className="text-neutralDark">
                        {t('sections.menuItems')}
                    </Typography>
                    <div className="flex items-center gap-4 ml-auto">
                        <ViewModeToggle />
                        <Button
                            className="flex items-center bg-primary hover:bg-opacity-90"
                            onClick={() => navigate('/menu-items/create')}
                            aria-label={t('buttons.addMenuItem')}
                        >
                            <PlusIcon className="h-5 w-5 mr-2" />
                            {t('buttons.addMenuItem')}
                        </Button>
                    </div>
                </div>
            </div>

            {viewMode === 'card' ? (
                <MenuItemsCards menuItems={menuItems} setMenuItems={setMenuItems} isAdmin={isAdmin} />
            ) : (
                <MenuItemsTable menuItems={menuItems} setMenuItems={setMenuItems} isAdmin={isAdmin} showBar={false} showRestaurant={true} />
            )}
        </div>
    );
};

export default MenuItemList;
