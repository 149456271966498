// File: src/pages/Users/UserForm.tsx

import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    Typography,
    Input,
    Button,
    Card,
    CardBody,
    CardHeader,
} from '@material-tailwind/react';
import Select from 'react-select';
import api from '../../services/api';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

interface User {
    id?: string;
    email: string;
    firstName: string;
    lastName: string;
    password?: string;
    roleId?: string;
    restaurantId?: string | null;
    locationId?: string | null;
}

const UserForm: React.FC<{ currentUserId: string }> = ({ currentUserId }) => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const [user, setUser] = useState<User>({
        email: '',
        firstName: '',
        lastName: '',
        password: '',
        roleId: '',
        restaurantId: null,
        locationId: null,
    });
    const [roles, setRoles] = useState<{ id: string; name: string }[]>([]);
    const [restaurants, setRestaurants] = useState<{ id: string; name: string }[]>([]);
    const [locations, setLocations] = useState<{ id: string; name: string }[]>([]);
    const [error, setError] = useState<string>('');
    const userRole = useSelector((state: RootState) => state.auth.user?.role?.name);
    const [validationErrors, setValidationErrors] = useState<{ restaurant: string; location: string }>({
        restaurant: '',
        location: '',
    });

    useEffect(() => {
        const fetchRoles = async () => {
            try {
                const response = await api.get('/roles');
                setRoles(response.data);
            } catch (error) {
                console.error('Error fetching roles:', error);
            }
        };

        const fetchRestaurants = async () => {
            try {
                const response = await api.get('/restaurants');
                setRestaurants(response.data);
            } catch (error) {
                console.error('Error fetching restaurants:', error);
            }
        };

        const fetchUser = async () => {
            try {
                const response = await api.get(`/users/${id}`);
                const { role, restaurant, location, ...userData } = response.data;

                setUser({
                    ...userData,
                    roleId: role?.id || '',
                    restaurantId: restaurant?.id || null,
                    locationId: location?.id || null,
                });

                if (restaurant?.id) {
                    await fetchLocationsByRestaurant(restaurant.id);
                }
            } catch (error) {
                console.error('Error fetching user:', error);
            }
        };

        fetchRoles();
        fetchRestaurants();

        if (id) {
            fetchUser();
        }
    }, [id]);

    const fetchLocationsByRestaurant = async (restaurantId: string) => {
        try {
            const response = await api.get(`/restaurants/${restaurantId}/locations`);
            setLocations(response.data);
        } catch (error) {
            console.error('Error fetching locations for restaurant:', error);
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    };

    const handleSelectChange = (field: keyof User) => async (selectedOption: { value: string | null }) => {
        const updatedUser = { ...user, [field]: selectedOption ? selectedOption.value : null };

        if (field === 'restaurantId' && selectedOption?.value) {
            await fetchLocationsByRestaurant(selectedOption.value);
            updatedUser.locationId = null; // Reset location when a new restaurant is selected
        }

        setUser(updatedUser);
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        const currentRole = roles.find((role) => role.id === user.roleId)?.name;
        let valid = true;
        const newValidationErrors = { restaurant: '', location: '' };

        // Check required fields based on role
        if (currentRole !== 'Admin' && !user.restaurantId) {
            newValidationErrors.restaurant = 'Restaurant must be selected for non-Admin roles.';
            valid = false;
        }
        if (currentRole === 'Staff' && !user.locationId) {
            newValidationErrors.location = 'Location must be selected for Staff role.';
            valid = false;
        }

        setValidationErrors(newValidationErrors);

        if (!valid) return;

        try {
            if (id) {
                await api.put(`/users/${id}`, user);
            } else {
                await api.post('/users', user);
            }
            navigate('/users');
        } catch (err: any) {
            setError(err.response?.data?.message || 'Operation failed');
        }
    };

    const isAdmin = userRole === 'Admin';

    return (
        <div className="container mx-auto px-4 py-6">
            <Card className="max-w-3xl mx-auto">
                <CardHeader className="bg-primary text-white p-4">
                    <Typography variant="h5" color="white">
                        {id ? 'Edit User' : 'Add User'}
                    </Typography>
                </CardHeader>
                <CardBody>
                    {error && <p className="text-red-500 mb-4">{error}</p>}
                    <form onSubmit={handleSubmit} className="space-y-6">
                        <Input
                            label="First Name"
                            name="firstName"
                            type="text"
                            value={user.firstName}
                            onChange={handleChange}
                            required
                            className="mb-4"
                        />
                        <Input
                            label="Last Name"
                            name="lastName"
                            type="text"
                            value={user.lastName}
                            onChange={handleChange}
                            required
                            className="mb-4"
                        />
                        <Input
                            label="Email"
                            name="email"
                            type="email"
                            value={user.email}
                            onChange={handleChange}
                            required
                            className="mb-4"
                        />
                        {(!id || (isAdmin && id !== currentUserId)) && (
                            <Input
                                label="Password"
                                name="password"
                                type="password"
                                value={user.password}
                                onChange={handleChange}
                                required={!id}
                                className="mb-4"
                            />
                        )}
                        <div>
                            <Typography variant="small"
                                        className="block text-sm font-medium text-gray-700 text-left mb-1">
                                Restaurant
                            </Typography>
                            <Select
                                options={restaurants.map((restaurant) => ({
                                    value: restaurant.id,
                                    label: restaurant.name
                                }))}
                                value={restaurants.find((restaurant) => restaurant.id === user.restaurantId) ? {
                                    value: user.restaurantId,
                                    label: restaurants.find((restaurant) => restaurant.id === user.restaurantId)?.name
                                } : null}
                                onChange={handleSelectChange('restaurantId')}
                                placeholder="Select a Restaurant"
                                className="mb-4"
                                isSearchable
                            />
                            {validationErrors.restaurant && (
                                <p className="text-red-500 text-sm mt-1">{validationErrors.restaurant}</p>
                            )}
                        </div>
                        <div>
                            <Typography variant="small"
                                        className="block text-sm font-medium text-gray-700 text-left mb-1">
                                Role
                            </Typography>
                            <Select
                                options={roles.map((role) => ({value: role.id, label: role.name}))}
                                value={roles.find((role) => role.id === user.roleId) ? {
                                    value: user.roleId,
                                    label: roles.find((role) => role.id === user.roleId)?.name
                                } : null}
                                onChange={handleSelectChange('roleId')}
                                placeholder="Select a Role"
                                className="mb-4"
                                isSearchable
                            />
                        </div>
                        <div>
                            <Typography variant="small"
                                        className="block text-sm font-medium text-gray-700 text-left mb-1">
                                Location
                            </Typography>
                            <Select
                                options={locations.map((location) => ({value: location.id, label: location.name}))}
                                value={locations.find((location) => location.id === user.locationId) ? {
                                    value: user.locationId,
                                    label: locations.find((location) => location.id === user.locationId)?.name
                                } : null}
                                onChange={handleSelectChange('locationId')}
                                placeholder="Select a Location"
                                className="mb-4"
                                isSearchable
                            />
                            {validationErrors.location && (
                                <p className="text-red-500 text-sm mt-1">{validationErrors.location}</p>
                            )}
                        </div>
                        <Button type="submit" fullWidth className="bg-primary hover:bg-opacity-90">
                            {id ? 'Update' : 'Create'}
                        </Button>
                    </form>
                </CardBody>
            </Card>
        </div>
    );
};

export default UserForm;
