import axios from 'axios';
import store, { RootState } from '../store';
import { logout, setCredentials } from '../store/slices/authSlice';

const api = axios.create({
    baseURL: import.meta.env.VITE_API_BASE_URL || 'http://localhost:3000',
});

api.interceptors.request.use(
    (config) => {
        const token = (store.getState() as RootState).auth.token;
        if (token && config.headers) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

api.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        if (
            error.response?.status === 401 &&
            !originalRequest._retry
        ) {
            originalRequest._retry = true;
            const state = store.getState() as RootState;
            const refreshToken = state.auth.refreshToken;

            if (refreshToken) {
                try {
                    const response = await axios.post(
                        `${import.meta.env.VITE_API_BASE_URL || 'http://localhost:3000'}/auth/refresh`,
                        { refreshToken }
                    );
                    const { access_token, refresh_token } = response.data;

                    // Update token in the Redux store
                    store.dispatch(
                        setCredentials({
                            token: access_token,
                            refreshToken: refresh_token,
                        })
                    );

                    // Retry the original request with the new token
                    originalRequest.headers.Authorization = `Bearer ${access_token}`;
                    return api(originalRequest);
                } catch (refreshError) {
                    // If refresh fails, log out the user
                    store.dispatch(logout());
                    window.location.href = '/login';
                }
            } else {
                // Log out if no refresh token is available
                store.dispatch(logout());
                window.location.href = '/login';
            }
        }

        return Promise.reject(error);
    }
);

export const updatePassword = (data: { currentPassword: string; newPassword: string }) => {
    return api.put('/users/me/password', data);
};

export default api;
