import React, { useMemo, useState } from 'react';
import { Card, CardBody, Typography, Button } from '@material-tailwind/react';
import { PlusIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import ActionButtons from './ActionButtons.tsx';
import {t} from "i18next";
import PriceTag from "../Common/Price.tsx";
import {Restaurant} from "../../types";

interface Translation {
    id: string;
    language: string;
    name: string;
    description: string;
}

interface MenuItemWithTranslations {
    id: string;
    price: number;
    translations: Translation[];
    restaurant: {
        name: string;
    };
}

interface Props {
    menuItems: MenuItemWithTranslations[];
    isAdmin: boolean;
    setMenuItems: React.Dispatch<React.SetStateAction<MenuItemWithTranslations[]>>;
    onAddMenuItem: () => void;
    showBar: boolean;
    showRestaurant: boolean;
    restaurant?: Restaurant
}

const MenuItemsTable: React.FC<Props> = ({
                                             menuItems,
                                             isAdmin,
                                             setMenuItems,
                                             onAddMenuItem,
                                             showRestaurant = false,
                                             showBar = true,
    restaurant
                                         }) => {
    const currentLanguage = useSelector((state: RootState) => state.language.current);

    const [sortConfig, setSortConfig] = useState<{
        key: keyof MenuItemWithTranslations | 'name' | 'description';
        direction: 'ascending' | 'descending';
    } | null>(null);

    const getTranslation = (item: MenuItemWithTranslations): Translation => {
        const translation = item.translations.find(t => t.language === currentLanguage);
        return (
            translation ||
            item.translations.find(t => t.language === 'en') || {
                id: '',
                language: 'en',
                name: 'Untranslated',
                description: 'No translation available',
            }
        );
    };

    const handleSort = (key: keyof MenuItemWithTranslations | 'name' | 'description') => {
        let direction: 'ascending' | 'descending' = 'ascending';
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const sortedMenuItems = useMemo(() => {
        let sortableMenuItems = [...menuItems];
        if (sortConfig !== null) {
            sortableMenuItems.sort((a, b) => {
                let aValue: any, bValue: any;

                if (sortConfig.key === 'name' || sortConfig.key === 'description') {
                    const aTranslation = getTranslation(a)[sortConfig.key];
                    const bTranslation = getTranslation(b)[sortConfig.key];
                    aValue = aTranslation || '';
                    bValue = bTranslation || '';
                } else if (sortConfig.key === 'price') {
                    aValue = parseFloat(a.price); // Ensuring numerical comparison
                    bValue = parseFloat(b.price);
                } else {
                    aValue = a[sortConfig.key];
                    bValue = b[sortConfig.key];
                }

                if (aValue < bValue) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (aValue > bValue) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableMenuItems;
    }, [menuItems, sortConfig]);

    return (
        <div className="mb-8">
            {showBar && (
                <div className="flex justify-between items-center mb-4">
                    <Typography variant="h5" className="text-gray-800 font-bold">
                        Menu Items
                    </Typography>
                    {isAdmin && (
                        <Button
                            className="flex items-center gap-2 bg-primary hover:bg-primary/90 shadow-none"
                            onClick={onAddMenuItem}
                            size="sm"
                        >
                            <PlusIcon className="h-4 w-4" />
                            Add Menu Item
                        </Button>
                    )}
                </div>
            )}

            <Card className="shadow-sm">
                <CardBody className="overflow-x-auto p-0">
                    <table className="w-full min-w-max table-auto text-left">
                        <thead className="border-b-2">
                        <tr>
                            {showRestaurant && isAdmin && (
                                <th className="px-6 py-3 text-left text-xs font-medium text-neutralDark uppercase tracking-wider">
                                    {t('common.restaurant')}
                                </th>
                            )}
                            <th
                                className="px-6 py-3 text-left text-xs font-medium text-neutralDark uppercase tracking-wider cursor-pointer"
                                onClick={() => handleSort('name')}
                            >
                                {t('common.name')}
                            </th>
                            <th
                                className="px-6 py-3 text-left text-xs font-medium text-neutralDark uppercase tracking-wider cursor-pointer"
                                onClick={() => handleSort('description')}
                            >
                                {t('common.description')}
                            </th>
                            <th
                                className="px-6 py-3 text-left text-xs font-medium text-neutralDark uppercase tracking-wider cursor-pointer"
                                onClick={() => handleSort('price')}
                            >
                                {t('common.price')}
                            </th>
                            {isAdmin && (
                                <th className="px-6 py-3 text-center text-xs font-medium text-neutralDark uppercase tracking-wider text-center">
                                    {t('common.actions')}
                                </th>
                            )}
                        </tr>
                        </thead>
                        <tbody className="divide-y divide-blue-gray-50">
                        {sortedMenuItems.map((item, idx) => {
                            const translation = getTranslation(item);
                            return (
                                <tr
                                    key={item.id}
                                    className={`${
                                        idx % 2 === 0 ? 'bg-neutralLight' : 'bg-white'
                                    } hover:bg-gray-50`}
                                >
                                    {showRestaurant && isAdmin && (
                                        <td className="p-4">
                                            <Link
                                                to={`/restaurants/${item.restaurant.id}`}
                                                className="text-primary hover:text-primary-dark font-medium"
                                            >
                                                {item.restaurant.name}
                                            </Link>
                                        </td>
                                    )}
                                    <td className="p-4">
                                        <Link
                                            to={`/menu-items/${item.id}`}
                                            className="text-primary hover:text-primary-dark font-medium"
                                        >
                                            {translation.name || 'Untitled Item'}
                                        </Link>
                                        {translation.language !== currentLanguage && (
                                            <span className="ml-2 text-xs text-gray-500">
                                                    (showing {translation.language} translation)
                                                </span>
                                        )}
                                    </td>
                                    <td className="p-4 text-gray-600 max-w-md truncate">
                                        {translation.description || 'No description available'}
                                    </td>
                                    <td className="p-4 text-gray-600">
                                        <PriceTag item={restaurant ? {
                                            ...item,
                                            restaurant
                                        } : item} />
                                    </td>
                                    {isAdmin && (
                                        <td className="p-4">
                                            <div className="flex justify-end pr-2">
                                                <ActionButtons
                                                    id={item.id}
                                                    name={translation.name || 'Untitled Item'}
                                                    type="menu-item"
                                                    setItems={setMenuItems}
                                                />
                                            </div>
                                        </td>
                                    )}
                                </tr>
                            );
                        })}
                        {menuItems.length === 0 && (
                            <tr>
                                <td
                                    colSpan={isAdmin ? 4 : 3}
                                    className="p-8 text-center text-gray-500"
                                >
                                    {t('common.noMenuItems')}
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </CardBody>
            </Card>
        </div>
    );
};

export default MenuItemsTable;
