// File: src/components/Cards/RestaurantCard.tsx

import React from 'react';
import { Card, CardBody, Typography, IconButton, Tooltip } from '@material-tailwind/react';
import {Restaurant, Translation} from '../../types/Restaurant';
import {Link, useNavigate} from 'react-router-dom';
import ActionButtons from "../Tables/ActionButtons.tsx";
import {RootState} from "../../store";
import {useSelector} from "react-redux";
import {getTranslation} from "../../utils/translation.utils.tsx";

interface RestaurantCardProps {
    restaurant: Restaurant;
    translation: Translation;
    setRestaurants: React.Dispatch<React.SetStateAction<Restaurant[]>>;
}

const RestaurantCard: React.FC<RestaurantCardProps> = ({ restaurant, setRestaurants, translation }) => {
    const currentLanguage = useSelector((state: RootState) => state.language.current);


    const name = getTranslation(
        restaurant.translations,
        'name',
        restaurant.name,
        currentLanguage
    );
    const description = getTranslation(
        restaurant.translations,
        'description',
        restaurant.description || '',
        currentLanguage
    );
    return (
        <Card key={restaurant.id} className="flex flex-col">
            {restaurant.logoUrl ? (
                <img
                    src={restaurant.logoUrl}
                    alt={restaurant.name}
                    className="h-48 w-full object-cover rounded-t-md"
                    onError={(e) => {
                        (e.target as HTMLImageElement).src = '/no-logo.png';
                    }}
                />
            ) : (
                <div className="h-48 w-full bg-gray-200 flex items-center justify-center rounded-t-md">
                    <span className="text-5xl text-gray-500 font-light">
                        No Logo
                    </span>
                </div>
            )}
            <CardBody className="flex-1 flex flex-col justify-between">
                <div>
                    <Link
                        to={`/restaurants/${restaurant.id}`}
                        className="text-primary font-semibold text-2xl"
                    >
                        {name}
                    </Link>
                    <Typography variant="body2" className="mb-2 mt-4">
                        {description}
                    </Typography>
                </div>
                <div className="flex justify-end space-x-2 mt-4">
                    <ActionButtons
                        id={restaurant.id}
                        name={translation.name}
                        type="restaurant"
                        setItems={setRestaurants}
                    />
                </div>
            </CardBody>
        </Card>
    );
};

export default RestaurantCard;
