// File: src/pages/Users/UserList.tsx

import React, {useEffect, useState, useMemo} from 'react';
import {Typography, Button, Card, CardBody} from '@material-tailwind/react';
import {PlusIcon} from '@heroicons/react/24/outline';
import api from '../../services/api';
import {Link, useNavigate} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {RootState, AppDispatch} from '../../store';
import {User} from '../../types/User';
import SortIndicator from '../../components/Tables/SortIndicator';
import ActionButtons from '../../components/Tables/ActionButtons';
import ViewModeToggle from '../../components/Common/ViewModeToggle';
import {enforceCardView, setViewMode} from '../../store/slices/viewSlice';
import {t} from "i18next";

const UserList: React.FC = () => {
    const [users, setUsers] = useState<User[]>([]);
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();
    const userRole = useSelector((state: RootState) => state.auth.user?.role?.name);
    const viewMode = useSelector((state: RootState) => state.view.mode);

    // Sorting state
    const [sortConfig, setSortConfig] = useState<{
        key: keyof User;
        direction: 'ascending' | 'descending';
    } | null>(null);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await api.get('/users');
                setUsers(response.data);
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };

        fetchUsers();
    }, []);

    useEffect(() => {
        const storedViewMode = localStorage.getItem('viewMode');
        if (storedViewMode === 'card' || storedViewMode === 'list') {
            dispatch(setViewMode(storedViewMode));
        } else {
            dispatch(setViewMode('list')); // Default to 'list' if not set
        }

        const handleResize = () => {
            if (window.innerWidth < 1024) {
                dispatch(enforceCardView());
            }
        };

        window.addEventListener('resize', handleResize);

        // Initial check
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [dispatch]);

    const handleSort = (key: keyof User) => {
        let direction: 'ascending' | 'descending' = 'ascending';
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({key, direction});
    };

    const sortedUsers = useMemo(() => {
        let sortableUsers = [...users];
        if (sortConfig !== null) {
            sortableUsers.sort((a, b) => {
                const aKey = a[sortConfig.key];
                const bKey = b[sortConfig.key];

                if (aKey === undefined || aKey === null) return 1;
                if (bKey === undefined || bKey === null) return -1;

                if (aKey < bKey) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (aKey > bKey) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableUsers;
    }, [users, sortConfig]);

    return (
        <div className="container mx-auto px-4 py-6">
            <div className="flex flex-col sm:flex-row justify-between items-center mb-6">
                <Typography variant="h4" className="text-neutralDark">
                    {t('sections.users')}
                </Typography>
                <div className="flex items-center space-x-2 mt-4 sm:mt-0">
                    <ViewModeToggle/>
                    <Button
                        className="flex items-center bg-primary hover:bg-opacity-90"
                        onClick={() => navigate('/users/create')}
                        aria-label="Add User"
                    >
                        <PlusIcon className="h-5 w-5 mr-2"/>
                        {t('buttons.addUser')}
                    </Button>
                </div>
            </div>

            {viewMode === 'card' ? (
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
                    {sortedUsers.map((user) => (
                        <Card key={user.id} className="flex flex-col">
                            <CardBody className="flex-1 flex flex-col justify-between">
                                <div>
                                    <Typography variant="h5" className="mb-2">
                                        {user.email}
                                    </Typography>
                                    <Typography variant="body2" className="mb-2">
                                        {user.role ? user.role.name : 'N/A'}
                                    </Typography>
                                    <Typography variant="body2" className="mb-2">
                                        {
                                            user.restaurant?.id ? (
                                                <Link to={`/restaurants/${user.restaurant?.id}`} className="text-primary ml-1">
                                                    {user.restaurant ? user.restaurant.name : 'N/A'}
                                                </Link>
                                            ) : 'N/A'
                                        }

                                    </Typography>
                                </div>
                                {userRole === 'Admin' && (
                                    <div className="flex justify-end space-x-2 mt-4">
                                        <ActionButtons
                                            id={user.id}
                                            name={user.email}
                                            type="user"
                                            setItems={setUsers}
                                        />
                                    </div>
                                )}
                            </CardBody>
                        </Card>
                    ))}
                    {sortedUsers.length === 0 && (
                        <Typography variant="body1" className="text-center w-full">
                            {t('common.noUsersAvailable')}
                        </Typography>
                    )}
                </div>
            ) : (
                <Card>
                    <CardBody className="overflow-x-auto p-0">
                        <table className="w-full min-w-max table-auto text-left">
                            <thead className={`border-b-2`}>
                                <tr>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-neutralDark uppercase tracking-wider cursor-pointer w-[200px]"
                                        onClick={() => handleSort('firstName')}>
                                        {t('common.firstName')} <SortIndicator
                                        direction={sortConfig?.key === 'firstName' ? sortConfig.direction : null}/>
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-neutralDark uppercase tracking-wider cursor-pointer w-[200px]"
                                        onClick={() => handleSort('email')}>
                                        {t('common.email')} <SortIndicator
                                        direction={sortConfig?.key === 'email' ? sortConfig.direction : null}/>
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-neutralDark uppercase tracking-wider cursor-pointer">
                                        {t('common.role')} <SortIndicator
                                        direction={sortConfig?.key === 'role' ? sortConfig.direction : null}/>
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-neutralDark uppercase tracking-wider cursor-pointer"
                                        onClick={() => handleSort('restaurant')}>
                                        {t('common.restaurant')} <SortIndicator
                                        direction={sortConfig?.key === 'restaurant' ? sortConfig.direction : null}/>
                                    </th>
                                    {(userRole === 'Admin' || userRole === 'Owner') && (
                                        <th className="px-6 py-3 text-center text-xs font-medium text-neutralDark uppercase tracking-wider w-1">
                                            {t('common.actions')}
                                        </th>
                                    )}
                                </tr>
                                </thead>
                                <tbody>
                                {sortedUsers.map((user, idx) => (
                                    <tr key={user.id}
                                        className={`${idx % 2 === 0 ? 'bg-neutralLight' : 'bg-white'} hover:bg-gray-50`}>
                                        <td className="px-6 py-4 text-left whitespace-nowrap text-sm text-neutralDark">
                                            {user.firstName} {user.lastName}
                                        </td>
                                        <td className="px-6 py-4 text-left whitespace-nowrap text-sm text-neutralDark">
                                            {user.email}
                                        </td>
                                        <td className="px-6 py-4 text-left whitespace-nowrap text-sm text-neutralDark">
                                            {user.role ? user.role.name : 'N/A'}
                                        </td>
                                        <td className="px-6 py-4 text-left whitespace-nowrap text-sm text-neutralDark">
                                            {user.restaurant ? user.restaurant.name : 'N/A'}
                                        </td>
                                        {(userRole === 'Admin' || userRole === 'Owner') && (
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-neutralDark">
                                                <ActionButtons
                                                    id={user.id}
                                                    name={user.email}
                                                    type="user"
                                                    setItems={setUsers}
                                                />
                                            </td>
                                        )}
                                    </tr>
                                ))}
                                {sortedUsers.length === 0 && (
                                    <tr>
                                        <td colSpan={userRole === 'Admin' ? 5 : 4}
                                            className="px-6 py-4 whitespace-nowrap text-sm text-neutralDark text-center">
                                            No users available.
                                        </td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                    </CardBody>
                </Card>
            )}
        </div>
    );
}

export default UserList;
