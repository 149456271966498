// LocationQRCode.tsx

import React, { useRef, useEffect } from 'react';
import { Card, Typography } from '@material-tailwind/react';
import QRCodeStyling from 'qr-code-styling';
import { Link } from 'react-router-dom';
import { ArrowDownTrayIcon, QrCodeIcon } from '@heroicons/react/24/outline';

interface Props {
    locationId: string;
    customerPortalUrl: string;
    locationName: string;
}

const LocationQRCode: React.FC<Props> = ({
                                             locationId,
                                             customerPortalUrl,
                                             locationName,
                                         }) => {
    // Construct the QR URL
    const qrUrl = `${customerPortalUrl}/${locationId}`;

    // Ref to the div where QR code will be appended
    const qrCodeRef = useRef<HTMLDivElement>(null);

    // Ref to store the QRCodeStyling instance
    const qrCodeInstance = useRef<QRCodeStyling | null>(null);

    useEffect(() => {
        // Initialize QRCodeStyling only once
        if (!qrCodeInstance.current) {
            qrCodeInstance.current = new QRCodeStyling({
                width: 250,
                height: 250,
                data: qrUrl,
                dotsOptions: {
                    color: '#000000',
                    type: 'rounded',
                },
                backgroundOptions: {
                    color: '#FFFFFF',
                },
                imageOptions: {
                    crossOrigin: 'anonymous',
                    margin: 20,
                },
            });

            // Append the QR code to the DOM
            if (qrCodeRef.current) {
                qrCodeInstance.current.append(qrCodeRef.current);
            }
        } else {
            // Update QR code data if qrUrl changes
            qrCodeInstance.current.update({ data: qrUrl });
        }

        // Cleanup function
        return () => {
            if (qrCodeInstance.current && qrCodeRef.current) {
                // Remove all child nodes from qrCodeRef
                qrCodeRef.current.innerHTML = '';
            }
        };
    }, [qrUrl]);

    // Function to handle QR code download
    const downloadQRCode = () => {
        if (qrCodeInstance.current) {
            qrCodeInstance.current.download({
                extension: 'png',
                name: `qr-${locationName.toLowerCase().replace(/\s+/g, '-')}`,
            });
        }
    };

    return (
        <Card className="p-6 flex flex-col items-center gap-6">
            {/* Header with QR Code Icon and Title */}
            <div className="flex items-center gap-2">
                <QrCodeIcon className="h-6 w-6 text-gray-800" />
                <Typography variant="h5" className="font-semibold text-gray-800">
                    Location QR Code
                </Typography>
            </div>

            {/* QR Code Display */}
            <div className="p-4 bg-gray-50 rounded-xl shadow-md">
                <Link to={qrUrl} target="_blank" className="block">
                    <div ref={qrCodeRef} />
                </Link>
            </div>

            {/* Instructions and Download Button */}
            <div className="space-y-2 text-center">
                <Typography className="text-sm text-gray-600">
                    Scan to view the menu
                </Typography>
                <button
                    onClick={downloadQRCode}
                    className="flex items-center justify-center text-blue-600 hover:text-blue-800 text-sm font-medium"
                >
                    <ArrowDownTrayIcon className="h-5 w-5 mr-1" />
                    Download QR Code
                </button>
            </div>
        </Card>
    );
};

export default LocationQRCode;
