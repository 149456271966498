export interface Translation {
    language: string;
    name: string;
    description: string;
}

export interface CategoryTranslation {
    language: string;
    name: string;
}

export interface Category {
    id: string;
    translations: CategoryTranslation[];
    order: number;
    menuItemIds: string[];
}

export interface MenuFormData {
    id?: string;
    restaurantId: string;
    isActive: boolean;
    translations: Translation[];
    categories: Category[];
}

export interface SelectOption {
    value: string;
    label: string;
}

export interface RestaurantOption extends SelectOption {
    supportedLanguages: string[];
}

export interface MenuItem extends SelectOption {
    id: string;
    translations: Translation[];
}

export const AVAILABLE_LANGUAGES = [
    { code: 'en', label: 'English' },
    { code: 'pl', label: 'Polish' },
    { code: 'it', label: 'Italian' },
    { code: 'jp', label: 'Japanese' },
] as const;
