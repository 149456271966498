// File: src/components/Tables/SortIndicator.tsx

import React from 'react';
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/24/outline';

interface SortIndicatorProps {
    direction: 'ascending' | 'descending' | null;
}

const SortIndicator: React.FC<SortIndicatorProps> = ({ direction }) => {
    if (direction === 'ascending') {
        return <ChevronUpIcon className="h-4 w-4 inline-block ml-1" />;
    }
    if (direction === 'descending') {
        return <ChevronDownIcon className="h-4 w-4 inline-block ml-1" />;
    }
    return null;
};

export default SortIndicator;
