import React, { useMemo, useState } from 'react';
import { Card, CardBody, Typography, Button } from '@material-tailwind/react';
import { Link } from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/24/outline';
import { Restaurant } from '../../types';
import ActionButtons from './ActionButtons.tsx';
import SortIndicator from './SortIndicator.tsx';
import {getTranslation} from "../../utils/translation.utils.tsx";
import {t} from "i18next";

interface Props {
    restaurants: Restaurant[];
    isAdmin: boolean;
    setRestaurants: React.Dispatch<React.SetStateAction<Restaurant[]>>;
    onAddRestaurant?: () => void;
    currentLanguage: string;
    showBar?: boolean;
}

const RestaurantsTable: React.FC<Props> = ({
                                               restaurants,
                                               isAdmin,
                                               setRestaurants,
                                               onAddRestaurant,
                                               currentLanguage,
                                               showBar = true,
                                           }) => {
    const [sortConfig, setSortConfig] = useState<{
        key: keyof Restaurant | 'name' | 'description';
        direction: 'ascending' | 'descending';
    } | null>(null);

    // Sorting configuration
    const handleSort = (key: keyof Restaurant | 'name' | 'description') => {
        let direction: 'ascending' | 'descending' = 'ascending';
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    // Sort restaurants using translations
    const sortedRestaurants = useMemo(() => {
        let sortableRestaurants = [...restaurants];
        if (sortConfig !== null) {
            sortableRestaurants.sort((a, b) => {
                let aValue, bValue;

                if (sortConfig.key === 'name' || sortConfig.key === 'description') {
                    aValue = getTranslation(a.translations, sortConfig.key, a[sortConfig.key], currentLanguage);
                    bValue = getTranslation(b.translations, sortConfig.key, b[sortConfig.key], currentLanguage);
                } else {
                    aValue = a[sortConfig.key];
                    bValue = b[sortConfig.key];
                }

                if (aValue < bValue) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (aValue > bValue) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableRestaurants;
    }, [restaurants, sortConfig, currentLanguage]);

    return (
        <div className="mb-8">
            {showBar && (
                <div className="flex justify-between items-center mb-4">
                    <Typography variant="h5" className="text-gray-800 font-bold">
                        {t('sections.restaurants')}
                    </Typography>
                    {isAdmin && (
                        <Button
                            className="flex items-center gap-2 bg-primary"
                            onClick={onAddRestaurant}
                            size="sm"
                        >
                            <PlusIcon className="h-4 w-4" />
                            {t('buttons.addRestaurant')}
                        </Button>
                    )}
                </div>
            )}

            <Card>
                <CardBody className="overflow-x-auto p-0">
                    <table className="w-full min-w-max table-auto text-left">
                        <thead className="border-b-2">
                        <tr>
                            <th
                                className="px-4 py-3 text-center text-xs font-medium text-neutralDark uppercase tracking-wider cursor-pointer w-[96px]"
                                onClick={() => handleSort('logoUrl')}
                            >
                                {t('common.logo')}{' '}
                                <SortIndicator
                                    direction={sortConfig?.key === 'logoUrl' ? sortConfig.direction : null}
                                />
                            </th>
                            <th
                                className="px-6 py-3 text-left text-xs font-medium text-neutralDark uppercase tracking-wider cursor-pointer"
                                onClick={() => handleSort('name')}
                            >
                                {t('common.name')}{' '}
                                <SortIndicator
                                    direction={sortConfig?.key === 'name' ? sortConfig.direction : null}
                                />
                            </th>
                            <th
                                className="px-6 py-3 text-left text-xs font-medium text-neutralDark uppercase tracking-wider cursor-pointer"
                                onClick={() => handleSort('description')}
                            >
                                {t('common.description')}{' '}
                                <SortIndicator
                                    direction={sortConfig?.key === 'description' ? sortConfig.direction : null}
                                />
                            </th>
                            {isAdmin && (
                                <th className="px-4 py-3 text-center text-xs font-medium text-neutralDark uppercase tracking-wider w-1">
                                    {t('common.actions')}
                                </th>
                            )}
                        </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-100">
                        {sortedRestaurants.map((restaurant, idx) => {
                            const name = getTranslation(
                                restaurant.translations,
                                'name',
                                restaurant.name
                            );
                            const description = getTranslation(
                                restaurant.translations,
                                'description',
                                restaurant.description
                            );

                            return (
                                <tr
                                    key={restaurant.id}
                                    className={`${
                                        idx % 2 === 0 ? 'bg-neutralLight' : 'bg-white'
                                    } hover:bg-gray-50`}
                                >
                                    <td className="px-4 py-2 whitespace-nowrap text-sm text-neutralDark flex justify-center">
                                        {restaurant.logoUrl ? (
                                            <img
                                                src={restaurant.logoUrl}
                                                alt={name}
                                                className="h-12 w-12 object-cover rounded-full"
                                            />
                                        ) : (
                                            <div className="h-12 w-12 bg-gray-200 flex items-center justify-center rounded-full">
                                                NL
                                            </div>
                                        )}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-neutralDark text-left">
                                        <Link
                                            to={`/restaurants/${restaurant.id}`}
                                            className="text-primary font-semibold text-lg"
                                        >
                                            {name}
                                        </Link>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-neutralDark text-left">
                                        {description}
                                    </td>
                                    {isAdmin && (
                                        <td className="px-4 py-2 whitespace-nowrap text-sm text-neutralDark w-1">
                                            <ActionButtons
                                                id={restaurant.id}
                                                name={name}
                                                type="restaurant"
                                                setItems={setRestaurants}
                                            />
                                        </td>
                                    )}
                                </tr>
                            );
                        })}
                        {restaurants.length === 0 && (
                            <tr>
                                <td
                                    colSpan={isAdmin ? 4 : 3}
                                    className="px-4 py-4 whitespace-nowrap text-sm text-neutralDark text-center"
                                >
                                    {t('common.noRestaurants')}
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </CardBody>
            </Card>
        </div>
    );
};

export default RestaurantsTable;
