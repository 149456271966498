// File: src/pages/Dashboard.tsx

import React from 'react';
import { Typography, Button, Card, CardBody } from '@material-tailwind/react';
import { t } from "i18next";
import { Link } from 'react-router-dom';

const Dashboard: React.FC = () => {
    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
            <Card className="w-full max-w-3xl bg-white shadow-lg rounded-lg">
                <CardBody className="flex flex-col items-center">
                    <Typography variant="h3" color="blue-gray" className="mb-4 text-center">
                        {t('welcome.message') || 'Welcome to QckOrder'}
                    </Typography>
                    <img
                        src="https://images.unsplash.com/photo-1556740749-887f6717d7e4?ixlib=rb-4.0.3&auto=format&fit=crop&w=800&q=60"
                        alt="Welcome"
                        className="w-full h-64 object-cover rounded-md mb-6"
                    />
                    <div className="flex space-x-4">
                        <Link to="/locations">
                            <Button color="blue" variant="filled">
                                {t('buttons.locations') || 'Locations'}
                            </Button>
                        </Link>
                        <Link to="/menus">
                            <Button color="green" variant="filled">
                                {t('buttons.menus') || 'Menus'}
                            </Button>
                        </Link>
                    </div>
                </CardBody>
            </Card>
        </div>
    );
};

export default Dashboard;
