import { useSelector } from 'react-redux';
import { RootState } from '../store';

const useAuth = () => {
    const userRole = useSelector((state: RootState) => state.auth.user?.role?.name);

    const hasRole = (allowedRoles: string[]) => {
        return allowedRoles.includes(userRole || '');
    };

    return { hasRole };
};

export default useAuth;
