import React, {useEffect, useState} from 'react';
import {
    Typography,
    Button,
    Card,
    CardBody,
    CardHeader,
    Chip
} from '@material-tailwind/react';
import {
    PlusIcon,
    ClipboardDocumentListIcon
} from '@heroicons/react/24/outline';
import {Link, useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {RootState} from '../../store';
import api from '../../services/api';
import ViewModeToggle from '../../components/Common/ViewModeToggle';
import ActionButtons from '../../components/Tables/ActionButtons';
import MenusTable from "../../components/Tables/MenusTable.tsx";
import {t} from "i18next";

interface Translation {
    language: string;
    name: string;
    description: string;
}

interface Menu {
    id: string;
    name: string;
    description: string;
    isActive: boolean;
    restaurant: {
        id: string;
        name: string;
        logoUrl: string;
    };
    translations: Translation[];
}

const MenuList: React.FC = () => {
    const navigate = useNavigate();
    const [menus, setMenus] = useState<Menu[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    const currentLanguage = useSelector((state: RootState) => state.language.current);
    const viewMode = useSelector((state: RootState) => state.view.mode);
    const userRole = useSelector((state: RootState) => state.auth.user?.role?.name);
    const user = useSelector((state: RootState) => state.auth.user);
    const isAdmin = userRole === 'Admin';

    useEffect(() => {
        const fetchMenus = async () => {
            setIsLoading(true);
            try {
                const url = isAdmin ? '/menus' : `/restaurants/${user?.restaurant?.id}/menus`;
                const response = await api.get(url);
                setMenus(response.data);
            } catch (err) {
                console.error('Error fetching menus:', err);
                setError('Failed to load menus');
            } finally {
                setIsLoading(false);
            }
        };

        fetchMenus();
    }, [isAdmin, user]);

    const getTranslation = (translations: Translation[], defaultText: string) => {
        const translation = translations?.find(t => t.language === currentLanguage);
        return translation?.name ||
            translations?.find(t => t.language === 'en')?.name ||
            defaultText;
    };

    const renderCardView = () => (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {menus.map((menu) => (
                <Card key={menu.id} className="overflow-hidden">
                    <CardHeader className="p-4 mt-4 bg-gray-50">
                        <div className="flex items-center gap-4">
                            {menu.restaurant.logoUrl ? (
                                <img
                                    src={menu.restaurant.logoUrl}
                                    alt={menu.restaurant.name}
                                    className="h-12 w-12 rounded-full object-cover"
                                />
                            ) : (
                                <div className="h-12 w-12 rounded-full bg-gray-200 flex items-center justify-center">
                                    <span className="text-gray-500 text-lg">
                                        {menu.restaurant.name.charAt(0)}
                                    </span>
                                </div>
                            )}
                            <Link
                                to={`/restaurants/${menu.restaurant.id}`}
                                className="font-medium text-primary hover:text-primary-dark"
                            >
                                {menu.restaurant.name}
                            </Link>
                        </div>
                    </CardHeader>
                    <CardBody className="p-6">
                        <div className="flex justify-between items-start mb-4">
                            <Link
                                to={`/menus/${menu.id}`}
                                className="text-xl font-semibold hover:text-primary"
                            >
                                {getTranslation(menu.translations, menu.name)}
                            </Link>
                            <div className={`
                                inline-flex items-center gap-1.5 px-3 py-1.5 rounded-full text-sm
                                ${menu.isActive
                                ? 'bg-green-50 text-green-700'
                                : 'bg-gray-100 text-gray-600'
                            }
                            `}>
                                <ClipboardDocumentListIcon className="h-4 w-4"/>
                                {menu.isActive ? 'Active' : 'Inactive'}
                            </div>
                        </div>
                        <Typography className="text-gray-600 mb-4 text-left">
                            {getTranslation(
                                menu.translations.map(t => ({...t, name: t.description})),
                                menu.description
                            )}
                        </Typography>
                        {isAdmin && (
                            <div className="flex justify-end">
                                <ActionButtons
                                    id={menu.id}
                                    name={menu.name}
                                    type="menu"
                                    setItems={setMenus}
                                />
                            </div>
                        )}
                    </CardBody>
                </Card>
            ))}
        </div>
    );

    const renderListView = () => (
        <Card>
            <CardBody className="overflow-x-auto p-0">
                <table className="w-full min-w-max table-auto">
                    <thead>
                    <tr className="bg-blue-gray-50">
                        {isAdmin && (
                            <th className="border-b border-blue-gray-100 p-4 text-left">
                                <Typography variant="small" className="font-medium text-blue-gray-700">
                                    Restaurant
                                </Typography>
                            </th>
                        )}
                        <th className="border-b border-blue-gray-100 p-4 text-left">
                            <Typography variant="small" className="font-medium text-blue-gray-700">
                                Name
                            </Typography>
                        </th>
                        <th className="border-b border-blue-gray-100 p-4 text-left">
                            <Typography variant="small" className="font-medium text-blue-gray-700">
                                Description
                            </Typography>
                        </th>
                        <th className="border-b border-blue-gray-100 p-4 text-left w-1/12">
                            <Typography variant="small" className="font-medium text-blue-gray-700">
                                Status
                            </Typography>
                        </th>
                        <th className="border-b border-blue-gray-100 p-4 text-right">
                            <Typography variant="small" className="font-medium text-blue-gray-700">
                                Actions
                            </Typography>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {menus.map((menu, index) => (
                        <tr
                            key={menu.id}
                            className={`
                                    border-b border-blue-gray-50
                                    ${index % 2 === 0 ? 'bg-white' : 'bg-blue-gray-50/50'}
                                `}
                        >
                            {isAdmin && (
                                <td className="p-4">
                                    <div className="flex items-center gap-3">
                                        {menu.restaurant.logoUrl ? (
                                            <img
                                                src={menu.restaurant.logoUrl}
                                                alt={menu.restaurant.name}
                                                className="h-10 w-10 rounded-full object-cover"
                                            />
                                        ) : (
                                            <div
                                                className="h-10 w-10 rounded-full bg-gray-200 flex items-center justify-center">
                                                    <span className="text-gray-500">
                                                        {menu.restaurant.name.charAt(0)}
                                                    </span>
                                            </div>
                                        )}
                                        <Link
                                            to={`/restaurants/${menu.restaurant.id}`}
                                            className="font-medium text-primary hover:text-primary-dark"
                                        >
                                            {menu.restaurant.name}
                                        </Link>
                                    </div>
                                </td>
                            )}
                            <td className="p-4 text-left">
                                <Link
                                    to={`/menus/${menu.id}`}
                                    className="inline-flex items-center gap-2 text-primary hover:text-primary-dark"
                                >
                                    <ClipboardDocumentListIcon className="h-4 w-4"/>
                                    {getTranslation(menu.translations, 'name', menu.name)}
                                </Link>
                            </td>
                            <td className="p-4 max-w-md truncate text-left">
                                {getTranslation(
                                    menu.translations.map(t => ({...t, name: t.description})),
                                    menu.description
                                )}
                            </td>
                            <td className="p-4">
                                <div className={`
                                        inline-flex items-center gap-1.5 px-3 py-1.5 rounded-full
                                        ${menu.isActive
                                    ? 'bg-green-50 text-green-700'
                                    : 'bg-gray-100 text-gray-600'
                                }
                                    `}>
                                    <ClipboardDocumentListIcon className="h-4 w-4"/>
                                    <span className="text-sm">{menu.isActive ? 'Active' : 'Inactive'}</span>
                                </div>
                            </td>
                            <td className="p-4">
                                <div className="flex justify-end">
                                    <ActionButtons
                                        id={menu.id}
                                        name={menu.name}
                                        type="menu"
                                        setItems={setMenus}
                                    />
                                </div>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </CardBody>
        </Card>
    );

    if (isLoading) {
        return (
            <div className="flex justify-center items-center min-h-[400px]">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary"></div>
            </div>
        );
    }

    return (
        <div className="container mx-auto px-4 py-8">
            <div className="flex justify-between items-center mb-6">
                <Typography variant="h4" className="text-gray-800">
                    { t('sections.menus') }
                </Typography>
                <div className="flex items-center gap-4">
                    <ViewModeToggle/>
                    {isAdmin && (
                        <Button
                            className="flex items-center gap-2 bg-primary"
                            onClick={() => navigate('/menus/create')}
                        >
                            <PlusIcon className="h-4 w-4"/>
                            { t('buttons.addMenu') }
                        </Button>
                    )}
                </div>
            </div>

            {error ? (
                <Card className="p-4 bg-red-50 text-red-700">
                    {error}
                </Card>
            ) : menus.length === 0 ? (
                <Card className="p-6">
                    <Typography className="text-center text-gray-500">
                        No menus available
                    </Typography>
                </Card>
            ) : (
                viewMode === 'card' ? renderCardView() :
                    <MenusTable menus={menus} setMenus={setMenus} isAdmin={isAdmin} showRestaurant={true}
                                showBar={false}/>
            )}
        </div>
    );
};

export default MenuList;
