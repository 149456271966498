import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import api from '../../../services/api';
import { MenuFormData, RestaurantOption, MenuItem } from '../types';

const formatInitialMenuData = (menuData: any, categoriesData: any[]): MenuFormData => {
    return {
        id: menuData.id,
        restaurantId: menuData.restaurantId,
        isActive: menuData.isActive ?? true,
        translations: menuData.translations || [{ language: 'en', name: '', description: '' }],
        categories: categoriesData.map(cat => ({
            id: cat.id,
            translations: cat.translations || [{ language: 'en', name: '' }],
            order: cat.order,
            menuItemIds: cat.menuItems?.map(item => item.id) || [],
            menuItems: cat.menuItems || [] // Keep the full menu items for reference
        }))
    };
};

export const useMenuForm = (menuId?: string) => {
    const userRole = useSelector((state: RootState) => state.auth.user?.role?.name);
    const user = useSelector((state: RootState) => state.auth.user);
    const currentLanguage = useSelector((state: RootState) => state.language.current);

    const [restaurants, setRestaurants] = useState<RestaurantOption[]>([]);
    const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
    const [supportedLanguages, setSupportedLanguages] = useState<string[]>(['en']);
    const [error, setError] = useState<string>('');
    const [isLoading, setIsLoading] = useState(false);

    const [formData, setFormData] = useState<MenuFormData>({
        restaurantId: '',
        isActive: true,
        translations: [{ language: 'en', name: '', description: '' }],
        categories: []
    });

    // Handle supported languages changes
    useEffect(() => {
        setFormData(prev => {
            const existingTranslations = new Map(
                prev.translations.map(t => [t.language, t])
            );

            const newTranslations = supportedLanguages.map(lang =>
                    existingTranslations.get(lang) || {
                        language: lang,
                        name: '',
                        description: ''
                    }
            );

            // Update categories translations as well
            const updatedCategories = prev.categories.map(category => {
                const existingCatTranslations = new Map(
                    category.translations.map(t => [t.language, t])
                );

                return {
                    ...category,
                    translations: supportedLanguages.map(lang =>
                            existingCatTranslations.get(lang) || {
                                language: lang,
                                name: ''
                            }
                    )
                };
            });

            return {
                ...prev,
                translations: newTranslations,
                categories: updatedCategories
            };
        });
    }, [supportedLanguages]);

    // Format menu items for select component
    const formatMenuItemsForSelect = (items: any[]): MenuItem[] => {
        return items.map(item => ({
            id: item.id,
            value: item.id,
            label: item.translations?.find(t => t.language === currentLanguage)?.name
                || item.translations?.[0]?.name
                || item.name
                || 'Unnamed Item',
            translations: item.translations || [],
            price: item.price,
            isAvailable: item.isAvailable
        }));
    };

    // Fetch menu items
    const fetchMenuItems = async (restaurantId: string) => {
        try {
            const response = await api.get(`/restaurants/${restaurantId}/menu-items`);
            const formattedItems = formatMenuItemsForSelect(response.data);
            setMenuItems(formattedItems);
            return formattedItems;
        } catch (error) {
            console.error('Error fetching menu items:', error);
            setError('Failed to load menu items');
            return [];
        }
    };

    // Fetch initial data
    useEffect(() => {
        const fetchInitialData = async () => {
            setIsLoading(true);
            setError('');

            try {
                // Fetch restaurant data
                const restaurantsUrl = userRole === 'Admin'
                    ? '/restaurants'
                    : `/restaurants/${user?.restaurant?.id}`;

                const restaurantsResponse = await api.get(restaurantsUrl);
                const restaurantsData = Array.isArray(restaurantsResponse.data)
                    ? restaurantsResponse.data
                    : [restaurantsResponse.data];

                const formattedRestaurants = restaurantsData.map(r => ({
                    value: r.id,
                    label: r.translations?.find(t => t.language === currentLanguage)?.name || r.name,
                    supportedLanguages: r.supportedLanguages
                }));
                setRestaurants(formattedRestaurants);

                // If editing existing menu
                if (menuId) {
                    const [menuResponse, categoriesResponse] = await Promise.all([
                        api.get(`/menus/${menuId}`),
                        api.get(`/menus/${menuId}/categories`)
                    ]);

                    const menuData = menuResponse.data;
                    const categoriesData = categoriesResponse.data;

                    // Set supported languages from restaurant
                    const restaurantData = formattedRestaurants.find(r => r.value === menuData.restaurantId);
                    if (restaurantData) {
                        setSupportedLanguages(restaurantData.supportedLanguages);

                        // Fetch menu items before setting form data
                        const fetchedItems = await fetchMenuItems(menuData.restaurantId);

                        // Format and set form data with menu items
                        const formattedMenuData = formatInitialMenuData(menuData, categoriesData);

                        // Ensure categories have access to full menu items data
                        formattedMenuData.categories = formattedMenuData.categories.map(category => ({
                            ...category,
                            menuItems: fetchedItems.filter(item => category.menuItemIds.includes(item.id))
                        }));

                        setFormData(formattedMenuData);
                    }
                }
            } catch (error: any) {
                console.error('Error fetching initial data:', error);
                setError(error.response?.data?.message || 'Failed to load menu data');
            } finally {
                setIsLoading(false);
            }
        };

        fetchInitialData();
    }, [menuId, userRole, user, currentLanguage]);

    return {
        formData,
        setFormData,
        restaurants,
        menuItems,
        supportedLanguages,
        setSupportedLanguages,
        error,
        setError,
        isLoading,
        setIsLoading,
        fetchMenuItems,
        userRole,
        currentLanguage
    };
};
