import React, { useEffect, useState, useMemo } from 'react';
import {
    Typography,
    Button,
    Card,
    CardBody,
    Chip,
} from '@material-tailwind/react';
import { PlusIcon } from '@heroicons/react/24/outline';
import { ClipboardDocumentListIcon } from '@heroicons/react/24/solid';
import api from '../../services/api';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from '../../store';
import { Location } from '../../types/Location';
import ViewModeToggle from '../../components/Common/ViewModeToggle';
import { setViewMode, enforceCardView } from '../../store/slices/viewSlice';
import LocationsTable from "../../components/Tables/LocationsTable.tsx";
import LocationsCards from "../../components/Cards/LocationsCards.tsx";
import {useIsMobile} from "../../hooks/useIsMobile.tsx";
import {t} from "i18next";

interface LocationWithMenu extends Location {
    activeMenu?: {
        id: string;
        translations: {
            language: string;
            name: string;
            description: string;
        }[];
        name: string;
    };
}

type SortKey = keyof Location | 'restaurantName' | 'menuName';

const LocationList: React.FC = () => {
    const [locations, setLocations] = useState<LocationWithMenu[]>([]);
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();
    const user = useSelector((state: RootState) => state.auth.user);
    const userRole = useSelector((state: RootState) => state.auth.user?.role?.name);
    const viewMode = useSelector((state: RootState) => state.view.mode);
    const currentLanguage = useSelector((state: RootState) => state.language.current);

    // Sorting state
    const [sortConfig, setSortConfig] = useState<{
        key: SortKey;
        direction: 'ascending' | 'descending';
    } | null>(null);

    const getTranslation = (translations: any[] | undefined, field: string, defaultValue: string) => {
        if (!translations) return defaultValue;
        const translation = translations.find(t => t.language === currentLanguage);
        if (!translation) {
            const enTranslation = translations.find(t => t.language === 'en');
            return enTranslation?.[field] || defaultValue;
        }
        return translation[field];
    };

    useEffect(() => {
        const fetchLocations = async () => {
            const url = userRole !== 'Admin' ? `/restaurants/${user!.restaurant.id}/locations` : '/locations';

            try {
                const response = await api.get<LocationWithMenu[]>(url);
                const locationsData = response.data;

                // Fetch active menu details for each location
                const locationsWithMenus = await Promise.all(
                    locationsData.map(async (location) => {
                        if (location.activeMenuId) {
                            try {
                                const menuResponse = await api.get(`/menus/${location.activeMenuId}`);
                                return {
                                    ...location,
                                    activeMenu: menuResponse.data
                                };
                            } catch (error) {
                                console.error(`Error fetching menu for location ${location.id}:`, error);
                                return location;
                            }
                        }
                        return location;
                    })
                );

                setLocations(locationsWithMenus);
            } catch (error) {
                console.error('Error fetching locations:', error);
            }
        };

        fetchLocations();
    }, [user, userRole]);

    const isMobile = useIsMobile()

    useEffect(() => {
        if (isMobile) {
            dispatch(enforceCardView());
        }
    }, [isMobile, dispatch]);

    const handleSort = (key: SortKey) => {
        let direction: 'ascending' | 'descending' = 'ascending';
        if (
            sortConfig &&
            sortConfig.key === key &&
            sortConfig.direction === 'ascending'
        ) {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const sortedLocations = useMemo(() => {
        let sortableLocations = [...locations];
        if (sortConfig !== null) {
            sortableLocations.sort((a, b) => {
                let aKey: any;
                let bKey: any;

                if (sortConfig.key === 'restaurantName') {
                    aKey = a.restaurant?.name || '';
                    bKey = b.restaurant?.name || '';
                } else if (sortConfig.key === 'menuName') {
                    aKey = a.activeMenu ? getTranslation(a.activeMenu.translations, 'name', a.activeMenu.name) : '';
                    bKey = b.activeMenu ? getTranslation(b.activeMenu.translations, 'name', b.activeMenu.name) : '';
                } else {
                    aKey = a[sortConfig.key] as unknown as string;
                    bKey = b[sortConfig.key] as unknown as string;
                }

                if (typeof aKey === 'string') aKey = aKey.toLowerCase();
                if (typeof bKey === 'string') bKey = bKey.toLowerCase();

                if (aKey < bKey) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (aKey > bKey) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableLocations;
    }, [locations, sortConfig, currentLanguage]);

    const renderActiveMenu = (location: LocationWithMenu) => {
        if (!location.activeMenu) {
            return <span className="text-gray-500 text-sm">No active menu</span>;
        }

        return (
            <Link
                to={`/menus/${location.activeMenuId}`}
                className="inline-flex items-center gap-2 text-primary hover:text-primary-dark"
            >
                <ClipboardDocumentListIcon className="h-4 w-4" />
                {getTranslation(location.activeMenu.translations, 'name', location.activeMenu.name)}
            </Link>
        );
    };

    const isAdmin = userRole === 'Admin';

    return (
        <div className="container mx-auto px-4 py-6">
            {/* Header Section */}
            <div className="flex flex-col sm:flex-row justify-between items-center mb-6">
                <Typography variant="h4" className="text-neutralDark">
                    {t('sections.locations')}
                </Typography>
                <div className="flex items-center space-x-2 mt-4 sm:mt-0">
                    <ViewModeToggle />
                    <Button
                        className="flex items-center bg-primary hover:bg-opacity-90"
                        onClick={() => navigate('/locations/create')}
                        aria-label={t('buttons.addLocation')}
                    >
                        <PlusIcon className="h-5 w-5 mr-2" />
                        {t('buttons.addLocation')}
                    </Button>
                </div>
            </div>

            {viewMode === 'card' ? (
                <LocationsCards locations={sortedLocations} isAdmin={isAdmin}  currentLanguage={currentLanguage} />
            ) : (
                /* Table View */
                <LocationsTable showBar={false} showRestaurant={true} locations={sortedLocations} currentLanguage={currentLanguage} isAdmin={isAdmin} onAddLocation={() => navigate('/locations/create')} />
            )}
        </div>
    );
};

export default LocationList;
