// src/pages/Locations/LocationDetail.tsx

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Alert } from '@material-tailwind/react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import api from '../../services/api';
import { Location, Order, Menu, Translation, TimeRange } from '../../types';

import LocationHeader from './components/LocationHeader';
import LocationQRCode from './components/LocationQRCode';
import LocationActiveMenu from './components/LocationActiveMenu';
import StatsTimeRange from "./components/StatsTimeRange";
import LocationStats from "./components/LocationStats";
import OrdersCards from "../../components/Cards/OrdersCards.tsx";
import OrdersTable from "../../components/Tables/OrdersTable.tsx";

interface DetailedMenu extends Menu {
    restaurant: {
        id: string;
        name: string;
        translations: Translation[];
    };
    translations: Translation[];
}

interface DateRange {
    startDate: Date;
    endDate: Date;
}

const LOCAL_STORAGE_KEY = 'selectedTimeRange';

const LocationDetail: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const userRole = useSelector((state: RootState) => state.auth.user?.role?.name);
    const currentLanguage = useSelector((state: RootState) => state.language.current);
    const isAdmin = userRole === 'Admin';
    const customerPortalUrl = import.meta.env.VITE_CUSTOMER_PORTAL_URL;

    // State
    const [timeRange, setTimeRange] = useState<TimeRange>(() => {
        const storedTimeRange = localStorage.getItem(LOCAL_STORAGE_KEY) as TimeRange | null;
        if (storedTimeRange && ['today', 'yesterday', 'last7days', 'last30days', 'all'].includes(storedTimeRange)) {
            return storedTimeRange;
        }
        return 'today'; // default
    });
    const [dateRange, setDateRange] = useState<DateRange>({
        startDate: new Date(),
        endDate: new Date(),
    });
    const [location, setLocation] = useState<Location | null>(null);
    const [activeMenu, setActiveMenu] = useState<DetailedMenu | null>(null);
    const [orders, setOrders] = useState<Order[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isMenuLoading, setIsMenuLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [menuError, setMenuError] = useState<string | null>(null);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [updateError, setUpdateError] = useState<string | null>(null); // Added for handling updates

    // Handle window resize for isSmall state
    const [isSmall, setIsSmall] = useState(window.innerWidth < 1024);

    useEffect(() => {
        const handleResize = () => {
            setIsSmall(window.innerWidth < 1024);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Update localStorage and dateRange whenever timeRange changes
    useEffect(() => {
        localStorage.setItem(LOCAL_STORAGE_KEY, timeRange);
        const { startDate, endDate } = calculateDateRange(timeRange);
        setDateRange({ startDate, endDate });
    }, [timeRange]);

    // Function to calculate date ranges based on timeRange
    const calculateDateRange = (range: TimeRange): DateRange => {
        const now = new Date();
        let startDate: Date;
        let endDate: Date = new Date(now); // Clone the current date

        switch (range) {
            case 'today':
                startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
                endDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999);
                break;
            case 'yesterday':
                startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);
                endDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1, 23, 59, 59, 999);
                break;
            case 'last7days':
                startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 6); // Including today
                endDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999);
                break;
            case 'last30days':
                startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 29); // Including today
                endDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999);
                break;
            case 'all':
                startDate = new Date(0); // Epoch time
                endDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999);
                break;
            default:
                startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
                endDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999);
        }

        return { startDate, endDate };
    };

    // Initialize dateRange on component mount
    useEffect(() => {
        const { startDate, endDate } = calculateDateRange(timeRange);
        setDateRange({ startDate, endDate });
    }, []); // Empty dependency array to run only once on mount

    // Fetch location and orders data
    useEffect(() => {
        const fetchLocationData = async () => {
            setIsLoading(true);
            setError(null);

            try {
                const [locationRes, ordersRes] = await Promise.all([
                    api.get(`/locations/${id}`),
                    api.get(`/locations/${id}/orders`)
                ]);

                setLocation(locationRes.data);
                setOrders(ordersRes.data);
            } catch (err) {
                console.error('Error fetching location data:', err);
                setError('Failed to load location data');
            } finally {
                setIsLoading(false);
            }
        };

        if (id) {
            fetchLocationData();
        }
    }, [id]);

    // Separate effect for fetching menu data
    useEffect(() => {
        const fetchMenuData = async () => {
            if (!location?.activeMenuId) {
                setIsMenuLoading(false);
                setActiveMenu(null);
                return;
            }

            setIsMenuLoading(true);
            setMenuError(null);

            try {
                const menuRes = await api.get(`/menus/${location.activeMenuId}`);
                const menuData = menuRes.data;

                // Fetch menu categories with items if needed
                const categoriesRes = await api.get(`/menus/${location.activeMenuId}/categories`);

                setActiveMenu({
                    ...menuData,
                    categories: categoriesRes.data
                });
            } catch (err) {
                console.error('Error fetching menu data:', err);
                setMenuError('Failed to load menu data');
                setActiveMenu(null);
            } finally {
                setIsMenuLoading(false);
            }
        };

        fetchMenuData();
    }, [location?.activeMenuId]);

    const handleFieldUpdate = async (id: string, field: string, value: string) => {
        let endpoint = '';
        let payload: Record<string, string> = {};

        switch (field) {
            case 'status':
                endpoint = `/orders/${id}/update/status`;
                payload = { status: value };
                break;
            case 'paymentStatus':
                endpoint = `/orders/${id}/update/payment-status`;
                payload = { paymentStatus: value };
                break;
            case 'paymentMethod':
                endpoint = `/orders/${id}/update/payment-method`;
                payload = { paymentMethod: value };
                break;
            default:
                return;
        }

        try {
            await api.put(endpoint, payload);
            setOrders((prevOrders) =>
                prevOrders.map((order) =>
                    order.id === id ? { ...order, [field]: value } : order
                )
            );
            setSuccessMessage(`Successfully updated ${field.replace(/([A-Z])/g, ' $1').toLowerCase()}.`);
            setTimeout(() => setSuccessMessage(null), 3000);
        } catch (error) {
            console.error('Error updating field:', error);
            setUpdateError('Failed to update. Please try again.');
            setTimeout(() => setUpdateError(null), 3000);
        }
    };

    const handleMenuUpdate = async (newMenu: DetailedMenu | null) => {
        if (!location) return;

        setIsMenuLoading(true);
        try {
            await api.put(`/locations/${location.id}`, {
                activeMenuId: newMenu?.id || null
            });

            setActiveMenu(newMenu);
            setLocation(prev => prev ? {
                ...prev,
                activeMenuId: newMenu?.id || null
            } : null);

            setSuccessMessage('Menu updated successfully');
            setTimeout(() => setSuccessMessage(null), 3000);
        } catch (err) {
            console.error('Error updating menu:', err);
            setError('Failed to update menu');
            setTimeout(() => setError(null), 3000);
        } finally {
            setIsMenuLoading(false);
        }
    };

    if (isLoading) {
        return (
            <div className="container mx-auto px-4 py-8">
                <div className="flex justify-center items-center min-h-[400px]">
                    <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary"></div>
                </div>
            </div>
        );
    }

    if (!location) {
        return (
            <div className="container mx-auto px-4 py-8">
                <Alert color="red">Location not found</Alert>
            </div>
        );
    }

    return (
        <div className="container mx-auto px-4 py-8">
            {/* Success/Error Messages */}
            {successMessage && (
                <Alert color="green" className="mb-4">{successMessage}</Alert>
            )}
            {error && (
                <Alert color="red" className="mb-4">{error}</Alert>
            )}
            {updateError && (
                <Alert color="red" className="mb-4">{updateError}</Alert>
            )}

            {/* Header */}
            <LocationHeader
                location={location}
                isAdmin={isAdmin}
            />

            {/* Time Range Selector */}
            <StatsTimeRange
                timeRange={timeRange}
                onChange={setTimeRange}
            />

            {/* Statistics Dashboard */}
            <LocationStats
                restaurant={location.restaurant}
                orders={orders}
                timeRange={timeRange}
                dateRange={dateRange}
            />

            {/* Main Content Grid */}
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 mb-8">
                {/* QR Code Section */}
                <div className="lg:col-span-1">
                    <LocationQRCode
                        locationId={location.id}
                        customerPortalUrl={customerPortalUrl}
                        locationName={location.name}
                    />
                </div>

                {/* Active Menu Section */}
                <div className="lg:col-span-2">
                    <LocationActiveMenu
                        activeMenu={activeMenu}
                        isAdmin={isAdmin}
                        setActiveMenu={handleMenuUpdate}
                        isLoading={isMenuLoading}
                        error={menuError}
                        currentLanguage={currentLanguage}
                    />
                </div>
            </div>

            {/* Orders Section */}
            {isSmall ?
                <OrdersCards orders={orders} isAdmin={isAdmin} onOrderUpdate={handleFieldUpdate}/>
                : <OrdersTable
                    orders={orders}
                    isAdmin={isAdmin}
                    setOrders={setOrders}
                    onFieldUpdate={handleFieldUpdate}/>
            }
        </div>
    );
};

export default LocationDetail;
