// File: src/components/Common/ConfirmDelete.tsx

import React from 'react';
import { Typography, Button } from '@material-tailwind/react';

interface ConfirmDeleteProps {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
    title: string;
    message: string;
}

const ConfirmDelete: React.FC<ConfirmDeleteProps> = ({ isOpen, onClose, onConfirm, title, message }) => {
    if (!isOpen) return null;

    // Convert "\n\n" to line breaks for HTML rendering
    const formattedMessage = message.replace(/\n\n/g, '<br />');

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white rounded-lg shadow-lg w-11/12 max-w-2xl">
                <div className="p-6">
                    <Typography variant="h6" className="mb-4 break-words">
                        {title}
                    </Typography>
                    <Typography
                        variant="body1"
                        className="mb-6 break-words whitespace-normal w-full"
                        dangerouslySetInnerHTML={{ __html: formattedMessage }}
                    />
                    <div className="flex justify-end space-x-2">
                        <Button variant="text" color="blue" onClick={onClose}>
                            Cancel
                        </Button>
                        <Button variant="gradient" color="red" onClick={onConfirm}>
                            Delete
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConfirmDelete;
