import React, {useEffect} from 'react';
import { IconButton, Tooltip, Select, Option } from '@material-tailwind/react';
import {
    UserCircleIcon,
    ArrowRightOnRectangleIcon,
    LanguageIcon,
} from '@heroicons/react/24/solid';
import {useDispatch, useSelector} from 'react-redux';
import { logout } from '../../store/slices/authSlice';
import { toggleSidebar } from '../../store/slices/sidebarSlice';
import {PREVIEW_LANGUAGES, setCurrentLanguage} from '../../store/slices/languageSlice';
import { Link, useNavigate } from 'react-router-dom';
import {RootState} from "../../store";
import {ChevronDoubleLeftIcon, ChevronDoubleRightIcon} from "@heroicons/react/16/solid";

const NavbarComponent: React.FC = () => {
    const isOpen = useSelector((state: RootState) => state.sidebar.isOpen);
    const currentLanguage = useSelector((state: RootState) => state.language.current);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // Initialize language from localStorage if needed
    useEffect(() => {
        const savedLanguage = localStorage.getItem('currentLanguage');
        if (savedLanguage && savedLanguage !== currentLanguage &&
            PREVIEW_LANGUAGES.some(lang => lang.code === savedLanguage)) {
            dispatch(setCurrentLanguage(savedLanguage));
        }
    }, []);

    const handleLogout = () => {
        dispatch(logout());
        navigate('/login');
    };

    const handleSidebarToggle = () => {
        dispatch(toggleSidebar());
    };

    const handleLanguageChange = (value: string) => {
        dispatch(setCurrentLanguage(value));
    };

    const currentLanguageLabel = PREVIEW_LANGUAGES.find(lang => lang.code === currentLanguage)?.label || 'English';

    // //  when sidebar is open on width below 1024 and I click outside of the sidebar, it should close
    useEffect(() => {
        const handleOutsideClick = (e: MouseEvent) => {
            //  or click on the link
            if (isOpen && e.clientX > 256 || (e.target as HTMLElement).closest('a')) {
                dispatch(toggleSidebar());
            }
        };

        if (isOpen) {
            window.addEventListener('click', handleOutsideClick);
        }

        return () => {
            window.removeEventListener('click', handleOutsideClick);
        };
    }, [isOpen]);

    return (
        <nav className="px-4 py-3 border-b border-gray-200 bg-white shadow-sm flex items-center justify-between lg:justify-end gap-4">
            <div className="flex items-center gap-4">
                <IconButton
                    variant="text"
                    color="blue-gray"
                    onClick={handleSidebarToggle}
                    className={`flex lg:hidden z-100`}
                >
                    {isOpen ? (
                        <ChevronDoubleLeftIcon className="w-6 h-6" />
                    ) : (
                        <ChevronDoubleRightIcon className="w-6 h-6" />
                    )}
                </IconButton>

                <div className="hidden sm:flex items-center gap-2">
                    <LanguageIcon className="h-5 w-5 text-blue-gray-500" />
                    <Select
                        value={currentLanguage}
                        onChange={handleLanguageChange}
                        className="w-32 border-2 border-gray-300 focus:border-none text-neutralDark w-full"
                        containerProps={{
                            className: "min-w-[160px]"
                        }}
                        labelProps={{
                            className: "hidden"
                        }}
                    >
                        {PREVIEW_LANGUAGES.map((lang) => (
                            <Option key={lang.code} value={lang.code} className={`flex items-center text-neutralDark`}>
                                {lang.label} - {lang.originalLabel}
                            </Option>
                        ))}
                    </Select>
                </div>
            </div>

            <div className="flex items-center space-x-4">
                <Tooltip content="Profile">
                    <Link to="/profile" className="flex items-center space-x-2 text-white">
                        <IconButton variant="text" color="blue-gray">
                            <UserCircleIcon className="w-6 h-6" />
                        </IconButton>
                    </Link>
                </Tooltip>
                <Tooltip content="Logout">
                    <IconButton variant="text" color="red" onClick={handleLogout}>
                        <ArrowRightOnRectangleIcon className="w-6 h-6" />
                    </IconButton>
                </Tooltip>
            </div>
        </nav>
    );
};

export default NavbarComponent;
