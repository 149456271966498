import React from 'react';
import { Card, CardBody, CardHeader, Typography } from '@material-tailwind/react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import ActionButtons from '../Tables/ActionButtons.tsx';
import RestaurantCard from "./RestaurantCard.tsx";
import {Restaurant} from "../../types";

interface Translation {
    id: string;
    language: string;
    name: string;
    description: string;
}

interface Menu {
    id: string;
    restaurantId: string;
    isActive: boolean;
    createdAt: string;
    updatedAt: string;
    translations: Translation[];
    restaurantName: string;
    restaurantLogoUrl?: string;
}

interface Props {
    restaurants: Restaurant[];
    isAdmin: boolean;
    setRestaurants: React.Dispatch<React.SetStateAction<Restaurant[]>>;
}

const RestaurantsCards: React.FC<Props> = ({ restaurants, isAdmin, setRestaurants }) => {
    const currentLanguage = useSelector((state: RootState) => state.language.current);

    return (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
            {restaurants.map((restaurant) => {
                const translation = restaurant.translations.find(t => t.language === currentLanguage) || {
                    name: 'No Name',
                    description: 'No Description',
                };
                return (
                    <RestaurantCard restaurant={restaurant} translation={translation} setRestaurants={setRestaurants} key={restaurant.id} />
                );
            })}
            {restaurants.length === 0 && (
                <Typography variant="body1" className="text-center w-full">
                    No restaurants available.
                </Typography>
            )}
        </div>
    );
};

export default RestaurantsCards;
