import React from 'react';
import { Card, CardBody, CardHeader, Typography } from '@material-tailwind/react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import ActionButtons from '../Tables/ActionButtons.tsx';

interface Translation {
    id: string;
    language: string;
    name: string;
    description: string;
}

interface Menu {
    id: string;
    restaurantId: string;
    isActive: boolean;
    createdAt: string;
    updatedAt: string;
    translations: Translation[];
    restaurantName: string;
    restaurantLogoUrl?: string;
}

interface Props {
    menus: Menu[];
    isAdmin: boolean;
    setMenus: React.Dispatch<React.SetStateAction<Menu[]>>;
}

const MenusCards: React.FC<Props> = ({ menus, isAdmin, setMenus }) => {
    const currentLanguage = useSelector((state: RootState) => state.language.current);

    const getTranslation = (menu: Menu, field: 'name' | 'description', fallback: string): string => {
        const translation = menu.translations.find(t => t.language === currentLanguage);

        if (!translation) {
            const defaultTranslation = menu.translations.find(t => t.language === 'en');
            return defaultTranslation ? defaultTranslation[field] || fallback : fallback;
        }

        return translation[field] || fallback;
    };

    return (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 mb-8">
            {menus.map((menu) => (
                <Card key={menu.id} className="overflow-hidden">
                    <CardBody className="p-6">
                        <div className="flex justify-between items-start mb-4">
                            <Link
                                to={`/menus/${menu.id}`}
                                className="text-xl font-semibold hover:text-primary"
                            >
                                {getTranslation(menu, 'name', 'Untitled Menu')}
                            </Link>
                            <div className={`
                                inline-flex items-center gap-1.5 px-3 py-1.5 rounded-full text-sm
                                ${menu.isActive ? 'bg-green-50 text-green-700' : 'bg-gray-100 text-gray-600'}
                            `}>
                                <span className="h-4 w-4 rounded-full bg-current"></span>
                                {menu.isActive ? 'Active' : 'Inactive'}
                            </div>
                        </div>
                        <Typography className="text-gray-600 mb-4">
                            {getTranslation(menu, 'description', 'No description available')}
                        </Typography>
                        {isAdmin && (
                            <div className="flex justify-end">
                                <ActionButtons
                                    id={menu.id}
                                    name={getTranslation(menu, 'name', 'Untitled Menu')}
                                    type="menu"
                                    setItems={setMenus}
                                />
                            </div>
                        )}
                    </CardBody>
                </Card>
            ))}
            {menus.length === 0 && (
                <Typography variant="body1" className="text-center w-full">
                    No menus available.
                </Typography>
            )}
        </div>
    );
};

export default MenusCards;
