import React, { useMemo, useState } from 'react';
import { Card, CardBody, Typography, Button } from '@material-tailwind/react';
import { PlusIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import { Location } from '../../types';
import ActionButtons from './ActionButtons.tsx';
import SortIndicator from './SortIndicator.tsx';
import {t} from "i18next";

interface Props {
    locations: Location[];
    isAdmin: boolean;
    restaurantId: string;
    setLocations: React.Dispatch<React.SetStateAction<Location[]>>;
    onAddLocation: () => void;
    currentLanguage: string;
    showRestaurant: boolean;
    showBar: boolean;
}

const LocationsTable: React.FC<Props> = ({
                                             locations,
                                             isAdmin,
                                             restaurantId,
                                             setLocations,
                                             onAddLocation,
                                             currentLanguage,
                                             showRestaurant = false,
                                             showBar = true,
                                         }) => {
    const [sortConfig, setSortConfig] = useState<{
        key: keyof Location | 'activeMenuName';
        direction: 'ascending' | 'descending';
    } | null>(null);

    // Function to handle sorting
    const handleSort = (key: keyof Location | 'activeMenuName') => {
        let direction: 'ascending' | 'descending' = 'ascending';
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    // Sort locations based on the active sorting configuration
    const sortedLocations = useMemo(() => {
        let sortableLocations = [...locations];
        if (sortConfig !== null) {
            sortableLocations.sort((a, b) => {
                let aValue, bValue;

                if (sortConfig.key === 'activeMenuName') {
                    const aMenu = a.activeMenu?.translations.find(
                        (t) => t.language === currentLanguage
                    )?.name || t('common.noActiveMenu');
                    const bMenu = b.activeMenu?.translations.find(
                        (t) => t.language === currentLanguage
                    )?.name || t('common.noActiveMenu');
                    aValue = aMenu;
                    bValue = bMenu;
                } else {
                    aValue = a[sortConfig.key];
                    bValue = b[sortConfig.key];
                }

                if (aValue < bValue) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (aValue > bValue) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableLocations;
    }, [locations, sortConfig, currentLanguage]);

    return (
        <div className="mb-8">
            {showBar && (
                <div className="flex justify-between items-center mb-4">
                    <Typography variant="h5" className="text-gray-800 font-bold">
                        {t('sections.locations')}
                    </Typography>
                    {isAdmin && (
                        <Button
                            className="flex items-center gap-2 bg-primary"
                            onClick={onAddLocation}
                            size="sm"
                        >
                            <PlusIcon className="h-4 w-4" />
                            {t('buttons.addLocation')}
                        </Button>
                    )}
                </div>
            )}

            <Card>
                <CardBody className="overflow-x-auto p-0">
                    <table className="w-full min-w-max table-auto text-left">
                        <thead className="border-b-2">
                        <tr>
                            {showRestaurant && isAdmin && (
                                <th
                                    className="px-4 py-3 text-left text-xs font-medium text-neutralDark uppercase tracking-wider cursor-pointer w-[240px]"
                                    onClick={() => handleSort('restaurant')}
                                >
                                    {t('common.restaurant')}{' '}
                                </th>
                            )}
                            <th
                                className="px-6 py-3 text-left text-xs font-medium text-neutralDark uppercase tracking-wider cursor-pointer"
                                onClick={() => handleSort('name')}
                            >
                                {t('common.location')}{' '}
                                <SortIndicator
                                    direction={sortConfig?.key === 'name' ? sortConfig.direction : null}
                                />
                            </th>
                            <th
                                className="px-6 py-3 text-left text-xs font-medium text-neutralDark uppercase tracking-wider cursor-pointer"
                                onClick={() => handleSort('address')}
                            >
                                {t('common.address')}{' '}
                                <SortIndicator
                                    direction={sortConfig?.key === 'address' ? sortConfig.direction : null}
                                />
                            </th>
                            <th
                                className="px-6 py-3 text-left text-xs font-medium text-neutralDark uppercase tracking-wider cursor-pointer"
                                onClick={() => handleSort('phoneNumber')}
                            >
                                {t('common.phoneNumber')}{' '}
                                <SortIndicator
                                    direction={sortConfig?.key === 'phoneNumber' ? sortConfig.direction : null}
                                />
                            </th>
                            <th
                                className="px-6 py-3 text-left text-xs font-medium text-neutralDark uppercase tracking-wider cursor-pointer"
                                onClick={() => handleSort('activeMenuName')}
                            >
                                {t('common.activeMenu')}{' '}
                                <SortIndicator
                                    direction={sortConfig?.key === 'activeMenuName' ? sortConfig.direction : null}
                                />
                            </th>
                            {isAdmin && (
                                <th className="px-4 py-3 text-center text-xs font-medium text-neutralDark uppercase tracking-wider w-1">
                                    {t('common.actions')}{' '}
                                </th>
                            )}
                        </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-100">
                        {sortedLocations.map((location, idx) => (
                            <tr
                                key={location.id}
                                className={`${
                                    idx % 2 === 0 ? 'bg-neutralLight' : 'bg-white'
                                } hover:bg-gray-50`}
                            >
                                {showRestaurant && isAdmin && (
                                    <td className="p-4">
                                        <Link
                                            to={`/restaurants/${location.restaurant.id}`}
                                            className="text-primary hover:text-primary-dark font-medium"
                                        >
                                            {location.restaurant?.name || 'Unknown'}
                                        </Link>
                                    </td>
                                )}
                                <td className="p-4">
                                    <Link
                                        to={`/locations/${location.id}`}
                                        className="text-primary hover:text-primary-dark font-medium"
                                    >
                                        {location.name}
                                    </Link>
                                </td>
                                <td className="p-4 text-gray-600">{location.address}</td>
                                <td className="p-4 text-gray-600">{location.phoneNumber}</td>
                                <td className="p-4 text-gray-600">
                                    {location.activeMenu ? (
                                        <Link
                                            to={`/menus/${location.activeMenu.id}`}
                                            className="text-primary hover:text-primary-dark"
                                        >
                                            {location.activeMenu.translations.find(
                                                (t) => t.language === currentLanguage
                                            )?.name || 'Untitled Menu'}
                                        </Link>
                                    ) : (
                                        <span className="text-gray-400">{t('common.noActiveMenu')}</span>
                                    )}
                                </td>
                                {isAdmin && (
                                    <td className="p-4">
                                        <div className="flex justify-center">
                                            <ActionButtons
                                                id={location.id}
                                                name={location.name}
                                                type="location"
                                                setItems={setLocations}
                                            />
                                        </div>
                                    </td>
                                )}
                            </tr>
                        ))}
                        {locations.length === 0 && (
                            <tr>
                                <td
                                    colSpan={isAdmin ? 6 : 5}
                                    className="p-4 text-center text-gray-500"
                                >
                                    {t('common.noLocations')}
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </CardBody>
            </Card>
        </div>
    );
};

export default LocationsTable;
