import {RootState} from "../store";
import {useSelector} from "react-redux";

export const getTranslation = (
    translations: any[] | undefined,
    field: 'name' | 'description',
    defaultValue: string,
    currentLanguage: string
): string => {
    if (!translations) return defaultValue;

    // Look for the desired language translation
    const translation = translations.find((t) => t.language === currentLanguage);
    if (translation && translation[field]) {
        return translation[field];
    }

    // Fallback to English translation
    const enTranslation = translations.find((t) => t.language === 'en');
    if (enTranslation && enTranslation[field]) {
        return enTranslation[field];
    }

    const plTranslation = translations.find((t) => t.language === 'pl');
    if (plTranslation && plTranslation[field]) {
        return plTranslation[field];
    }

    const anyTranslation = translations.find((t) => t[field]);
    if (anyTranslation && anyTranslation[field]) {
        return anyTranslation[field];
    }

    // Fallback to default value
    return defaultValue;
};

export const LANGUAGE_FLAGS: Record<string, string> = {
    en: 'us',
    pl: 'pl',
    es: 'es',
    fr: 'fr',
    de: 'de',
    it: 'it',
    jp: 'jp'
};
