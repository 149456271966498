import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    Typography,
    Input,
    Button,
    Card,
    CardBody,
    CardHeader,
    Textarea,
} from '@material-tailwind/react';
import Select from 'react-select';
import api from '../../services/api';
import { Restaurant } from '../../types/Restaurant';
import { Location } from '../../types/Location';
import { Menu } from '../../types/Menu';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import {getTranslation} from "../../utils/translation.utils.tsx";
import {t} from "i18next";

interface Translation {
    language: string;
    name: string;
    description: string;
}

const LocationForm: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const currentLanguage = useSelector((state: RootState) => state.language.current);

    const [location, setLocation] = useState<Location>({
        id: '',
        restaurantId: '',
        name: '',
        address: '',
        phoneNumber: '',
        activeMenuId: '',
    });
    const [restaurants, setRestaurants] = useState<Restaurant[]>([]);
    const [menus, setMenus] = useState<Menu[]>([]);
    const [error, setError] = useState<string>('');
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                // Fetch restaurants
                const restaurantsResponse = await api.get('/restaurants');
                setRestaurants(restaurantsResponse.data);

                // Fetch all menus
                const menusResponse = await api.get('/menus');
                setMenus(menusResponse.data);

                // If editing, fetch location details
                if (id) {
                    const locationResponse = await api.get(`/locations/${id}`);
                    setLocation(locationResponse.data);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                setError('Failed to load data');
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [id]);

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setLocation({ ...location, [e.target.name]: e.target.value });
    };

    // Filter menus based on selected restaurant
    const filteredMenus = menus.filter(menu => menu.restaurantId === location.restaurantId);

    // Prepare options for react-select
    const restaurantOptions = restaurants.map((restaurant) => ({
        value: restaurant.id,
        label: restaurant.name,
    }));

    const menuOptions = [
        { value: '', label: t('common.selectAMenu') },
        ...filteredMenus.map((menu) => ({
            value: menu.id,
            label: getTranslation(menu.translations, 'name', menu.name, currentLanguage),
        })),
    ];

    const getSelectedOption = (
        options: { value: string; label: string }[],
        value: string
    ) => options.find((option) => option.value === value) || null;

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            if (id) {
                await api.put(`/locations/${id}`, location);
            } else {
                await api.post('/locations', location);
            }
            navigate('/locations');
        } catch (err: any) {
            setError(err.response?.data?.message || 'Operation failed');
        }
    };

    if (isLoading) {
        return (
            <div className="flex justify-center items-center min-h-[400px]">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary"></div>
            </div>
        );
    }

    return (
        <div className="container mx-auto px-4 py-6">
            <Card className="max-w-3xl mx-auto">
                <CardHeader className="bg-primary text-white p-4">
                    <Typography variant="h5" color="white">
                        {id ? t('sections.locationsAdd') : t('sections.locationsCreate')}
                    </Typography>
                </CardHeader>
                <CardBody>
                    {error && (
                        <div className="bg-red-50 text-red-700 p-3 rounded-lg mb-4">
                            {error}
                        </div>
                    )}
                    <form onSubmit={handleSubmit} className="space-y-6">
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-2 text-left">
                                {t('common.restaurant')}
                            </label>
                            <Select
                                options={restaurantOptions}
                                value={getSelectedOption(
                                    restaurantOptions,
                                    location.restaurantId
                                )}
                                onChange={(selectedOption) => {
                                    setLocation({
                                        ...location,
                                        restaurantId: selectedOption ? selectedOption.value : '',
                                        activeMenuId: '', // Reset menu selection when restaurant changes
                                    });
                                }}
                                placeholder={t('common.selectARestaurant')}
                                isSearchable
                                className="basic-select"
                                classNamePrefix="select"
                            />
                        </div>
                        <Input
                            size="lg"
                            label={t('common.name')}
                            name="name"
                            type="text"
                            value={location.name}
                            onChange={handleChange}
                            required
                        />
                        <Textarea
                            label={t('common.address')}
                            name="address"
                            value={location.address}
                            onChange={handleChange}
                            required
                        />
                        <Input
                            size="lg"
                            label={t('common.phoneNumber')}
                            name="phoneNumber"
                            type="text"
                            value={location.phoneNumber}
                            onChange={handleChange}
                        />
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-2 text-left">
                                {t('common.activeMenu')}
                            </label>
                            <Select
                                options={menuOptions}
                                value={getSelectedOption(
                                    menuOptions,
                                    location.activeMenuId
                                )}
                                onChange={(selectedOption) => {
                                    setLocation({
                                        ...location,
                                        activeMenuId: selectedOption ? selectedOption.value : '',
                                    });
                                }}
                                placeholder={t('common.selectAMenu')}
                                isSearchable
                                isDisabled={!location.restaurantId}
                                className="basic-select"
                                classNamePrefix="select"
                            />
                            {!location.restaurantId && (
                                <p className="text-sm text-gray-500 mt-1">
                                    {t('common.selectRestaurantFirst')}
                                </p>
                            )}
                        </div>
                        <div className="flex justify-end">
                            <Button
                                type="submit"
                                className="bg-primary hover:bg-opacity-90"
                            >
                                {id ? t('buttons.update') : t('buttons.create')}
                            </Button>
                        </div>
                    </form>
                </CardBody>
            </Card>
        </div>
    );
};

export default LocationForm;
