import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setCredentials } from '../store/slices/authSlice';
import { useNavigate } from 'react-router-dom';
import {
    Typography,
    Input,
    Button,
    Card,
    CardBody,
    CardHeader,
    Alert,
} from '@material-tailwind/react';
import api from '../services/api';
import { User } from '../types/User';

interface LoginResponse {
    user: User;
    access_token: string;
    refresh_token: string;
}

const Login: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const response = await api.post<LoginResponse>('/auth/login', { email, password });
            dispatch(
                setCredentials({
                    user: response.data.user,
                    token: response.data.access_token,
                    refreshToken: response.data.refresh_token,
                })
            );
            navigate('/', { replace: true });
        } catch (err: any) {
            setError(err.response?.data?.message || 'An error occurred');
        }
    };

    return (
        <div className="flex items-center justify-center min-h-screen p-4">
            <div className="w-full max-w-sm">
                <Card>
                    <CardHeader className="mb-4 grid h-28 place-items-center bg-secondary">
                        <Typography variant="h3" color="white">
                            QckOrder
                        </Typography>
                    </CardHeader>
                    <CardBody className="flex flex-col gap-4">
                        {error && (
                            <Alert color="red" className="mb-4">
                                {error}
                            </Alert>
                        )}
                        <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                            <Input
                                size="lg"
                                label="Email"
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                className="focus:border-primary"
                            />
                            <Input
                                size="lg"
                                label="Password"
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                                className="focus:border-primary"
                            />
                            <Button type="submit" className="bg-secondary hover:bg-opacity-60" fullWidth>
                                Sign In
                            </Button>
                        </form>
                    </CardBody>
                </Card>
            </div>
        </div>
    );
};

export default Login;
