import React, { useMemo, useState } from 'react';
import { Card, CardBody, Typography, Button } from '@material-tailwind/react';
import { PlusIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import ActionButtons from "./ActionButtons.tsx";
import {t} from "i18next";
import {Restaurant} from "../../types";

interface Translation {
    id: string;
    language: string;
    name: string;
    description: string;
}

interface Menu {
    id: string;
    restaurantId: string;
    isActive: boolean;
    createdAt: string;
    updatedAt: string;
    translations: Translation[];
    restaurantName?: string;
    restaurant?: Restaurant;
}

interface Props {
    menus: Menu[];
    isAdmin: boolean;
    restaurantId: string;
    setMenus: React.Dispatch<React.SetStateAction<Menu[]>>;
    onAddMenu: () => void;
    showBar?: boolean;
    showRestaurant?: boolean;
}

const MenusTable: React.FC<Props> = ({
                                         menus,
                                         isAdmin,
                                         restaurantId,
                                         setMenus,
                                         onAddMenu,
                                         showBar = true,
                                         showRestaurant = false
                                     }) => {
    const currentLanguage = useSelector((state: RootState) => state.language.current);

    const [sortConfig, setSortConfig] = useState<{
        key: keyof Menu | 'name' | 'description';
        direction: 'ascending' | 'descending';
    } | null>(null);

    const getTranslation = (menu: Menu): Translation => {
        const translation = menu.translations.find(t => t.language === currentLanguage);
        if (!translation) {
            return menu.translations.find(t => t.language === 'en') || {
                id: '',
                language: 'en',
                name: 'Untranslated',
                description: 'No translation available'
            };
        }
        return translation;
    };

    const handleSort = (key: keyof Menu | 'name' | 'description') => {
        let direction: 'ascending' | 'descending' = 'ascending';
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const sortedMenus = useMemo(() => {
        let sortableMenus = [...menus];
        if (sortConfig !== null) {
            sortableMenus.sort((a, b) => {
                let aValue, bValue;

                if (sortConfig.key === 'name' || sortConfig.key === 'description') {
                    const aTranslation = getTranslation(a)[sortConfig.key];
                    const bTranslation = getTranslation(b)[sortConfig.key];
                    aValue = aTranslation || '';
                    bValue = bTranslation || '';
                } else if (sortConfig.key === 'isActive') {
                    aValue = a.isActive ? 1 : 0;
                    bValue = b.isActive ? 1 : 0;
                } else {
                    aValue = a[sortConfig.key];
                    bValue = b[sortConfig.key];
                }

                if (aValue < bValue) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (aValue > bValue) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableMenus;
    }, [menus, sortConfig]);

    return (
        <div className="mb-8">
            {showBar && (
                <div className="flex justify-between items-center mb-4">
                    <Typography variant="h5" className="text-gray-800 font-bold">
                        Menus
                    </Typography>
                    {isAdmin && (
                        <Button
                            className="flex items-center gap-2 bg-primary hover:bg-primary/90 shadow-none"
                            onClick={onAddMenu}
                            size="sm"
                        >
                            <PlusIcon className="h-4 w-4" />
                            Add Menu
                        </Button>
                    )}
                </div>
            )}

            <Card>
                <CardBody className="overflow-x-auto p-0">
                    <table className="w-full min-w-max table-auto text-left">
                        <thead className="border-b-2">
                        <tr>
                            {showRestaurant && isAdmin && (
                                <th className="px-4 py-3 text-left text-xs font-medium text-neutralDark uppercase tracking-wider">
                                    {t('common.restaurant')}
                                </th>
                            )}
                            <th
                                className="px-4 py-3 text-left text-xs font-medium text-neutralDark uppercase tracking-wider cursor-pointer"
                                onClick={() => handleSort('name')}
                            >
                                {t('common.name')}
                            </th>
                            <th
                                className="px-4 py-3 text-left text-xs font-medium text-neutralDark uppercase tracking-wider cursor-pointer"
                                onClick={() => handleSort('description')}
                            >
                                {t('common.description')}
                            </th>
                            <th
                                className="px-4 py-3 text-left text-xs font-medium text-neutralDark uppercase tracking-wider cursor-pointer"
                                onClick={() => handleSort('isActive')}
                            >
                                {t('common.status')}
                            </th>
                            {isAdmin && (
                                <th className="px-4 py-3 text-center text-xs font-medium text-neutralDark uppercase tracking-wider">
                                    {t('common.actions')}
                                </th>
                            )}
                        </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-100">
                        {sortedMenus.map((menu, idx) => {
                            const translation = getTranslation(menu);
                            return (
                                <tr
                                    key={menu.id}
                                    className={`${
                                        idx % 2 === 0 ? 'bg-neutralLight' : 'bg-white'
                                    } hover:bg-gray-50`}
                                >
                                    {showRestaurant && isAdmin && (
                                        <td className="p-4">
                                            <Link
                                                to={`/restaurants/${menu.restaurantId}`}
                                                className="text-primary hover:text-primary-dark font-medium"
                                            >
                                                {menu.restaurantName ? menu.restaurantName : menu.restaurant?.name || 'No restaurant'}
                                            </Link>
                                        </td>
                                    )}
                                    <td className="p-4">
                                        <Link
                                            to={`/menus/${menu.id}`}
                                            className="text-primary hover:text-primary-dark font-medium"
                                        >
                                            {translation.name || 'Untitled Menu'}
                                        </Link>
                                        {translation.language !== currentLanguage && (
                                            <span className="ml-2 text-xs text-gray-500">
                                                    (showing {translation.language} translation)
                                                </span>
                                        )}
                                    </td>
                                    <td className="p-4 text-gray-600 max-w-md truncate">
                                        {translation.description || 'No description available'}
                                    </td>
                                    <td className="p-4">
                                        <div
                                            className={`inline-flex items-center gap-1.5 px-2.5 py-1 rounded-full ${
                                                menu.isActive
                                                    ? 'bg-green-50 text-green-700'
                                                    : 'bg-gray-100 text-gray-600'
                                            }`}
                                        >
                                            <div
                                                className={`h-1.5 w-1.5 rounded-full ${
                                                    menu.isActive
                                                        ? 'bg-green-600'
                                                        : 'bg-gray-400'
                                                }`}
                                            />
                                            <span className="text-xs font-medium">
                                                    {menu.isActive ? 'Active' : 'Inactive'}
                                                </span>
                                        </div>
                                    </td>
                                    {isAdmin && (
                                        <td className="p-4">
                                            <div className="flex justify-end pr-2">
                                                <ActionButtons
                                                    id={menu.id}
                                                    name={translation.name || 'Untitled Menu'}
                                                    type="menu"
                                                    setItems={setMenus}
                                                />
                                            </div>
                                        </td>
                                    )}
                                </tr>
                            );
                        })}
                        {menus.length === 0 && (
                            <tr>
                                <td
                                    colSpan={isAdmin ? 4 : 3}
                                    className="p-8 text-center text-gray-500"
                                >
                                    No menus available
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </CardBody>
            </Card>
        </div>
    );
};

export default MenusTable;
