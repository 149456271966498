import React, { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    Typography,
    Input,
    Button,
    Card,
    CardBody,
    CardHeader,
    Textarea,
} from '@material-tailwind/react';
import ReactSelect from 'react-select';
import { useDropzone } from 'react-dropzone';
import { useMenuItemForm } from './hooks/useMenuItemForm';
import Toggle from '../../components/Toggle';
import { AVAILABLE_LANGUAGES } from './types';
import {t} from "i18next";

const MenuItemForm: React.FC = () => {
    const { itemId } = useParams<{ itemId?: string }>();
    const navigate = useNavigate();

    const {
        formData,
        setFormData,
        restaurants,
        supportedLanguages,
        error,
        setError,
        isLoading,
        setIsLoading,
        handleTranslationChange,
        handleRestaurantSelect,
        handleToggle,
        handleSubmit,
        imageFile,
        setImageFile,
    } = useMenuItemForm(itemId);

    const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        // Only allow numbers and one decimal point
        if (value === '' || /^\d*\.?\d{0,2}$/.test(value)) {
            setFormData(prev => ({
                ...prev,
                price: value
            }));
        }
    };

    const onDrop = useCallback((acceptedFiles: File[]) => {
        if (acceptedFiles && acceptedFiles.length > 0) {
            setImageFile(acceptedFiles[0]);
            // Preview the uploaded image
            setFormData((prev) => ({
                ...prev,
                imageUrl: URL.createObjectURL(acceptedFiles[0]),
            }));
        }
    }, [setFormData, setImageFile]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            'image/*': ['.jpeg', '.jpg', '.png', '.gif'],
        },
        multiple: false,
    });

    if (isLoading) {
        return (
            <div className="flex justify-center items-center min-h-[400px]">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary"></div>
            </div>
        );
    }

    return (
        <div className="container mx-auto px-4 py-6">
            <Card className="max-w-4xl mx-auto">
                <CardHeader className="bg-primary text-white p-4">
                    <Typography variant="h5" color="white">
                        {itemId ? t('sections.menuItemsEdit') : t('sections.menuItemsCreate')}
                    </Typography>
                </CardHeader>
                <CardBody>
                    {error && (
                        <div className="bg-red-50 text-red-700 p-3 rounded-lg mb-4 flex items-center">
                            <span>{error}</span>
                        </div>
                    )}
                    <form onSubmit={handleSubmit} className="space-y-6">
                        <div className="w-full">
                            <label className="block text-sm font-medium text-gray-700 mb-2 text-left">
                                {t('common.restaurant')}
                            </label>
                            <ReactSelect
                                options={restaurants}
                                value={restaurants.find(r => r.value === formData.restaurantId)}
                                onChange={handleRestaurantSelect}
                                isSearchable
                                placeholder={t('common.selectARestaurant')}
                                className="w-full"
                                isDisabled={isLoading}
                            />
                        </div>

                        <div className="w-full">
                            <label className="block text-sm font-medium text-gray-700 mb-2 text-left">
                                {t('common.price')}
                            </label>
                            <Input
                                type="text"
                                size="lg"
                                value={formData.price}
                                onChange={handlePriceChange}
                                label={t('common.price')}
                                required
                                disabled={isLoading}
                            />
                        </div>

                        {/* Image Upload Section */}
                        <div>
                            <Typography variant="small" className="mb-2 font-medium text-left">
                                {t('common.image')}
                            </Typography>
                            <div
                                {...getRootProps()}
                                className={`border-2 border-dashed rounded-md p-4 text-center cursor-pointer ${
                                    isDragActive ? 'border-blue-400' : 'border-gray-300'
                                }`}
                            >
                                <input {...getInputProps()} />
                                {formData.imageUrl ? (
                                    <div className="flex flex-col items-center">
                                        <img
                                            src={formData.imageUrl}
                                            alt="Image Preview"
                                            className="h-32 w-32 object-cover mb-2"
                                        />
                                        <p className="text-gray-500">
                                            Drag and drop a new image, or click to select
                                        </p>
                                    </div>
                                ) : (
                                    <p className="text-gray-500">
                                        Drag and drop an image here, or click to select
                                    </p>
                                )}
                            </div>
                        </div>

                        {supportedLanguages.map(lang => {
                            const language = AVAILABLE_LANGUAGES.find(l => l.code === lang);
                            const translation = formData.translations.find(t => t.language === lang) || {
                                language: lang,
                                name: '',
                                description: '',
                            };

                            return (
                                <div key={lang} className="border p-4 rounded-md shadow-sm">
                                    <Typography variant="h6" className="mb-4">
                                        {language?.label || lang}
                                    </Typography>
                                    <div className="space-y-4">
                                        <Input
                                            size="lg"
                                            label={`${t('common.name')} (${language?.label || lang})`}
                                            value={translation.name}
                                            onChange={(e) => handleTranslationChange(lang, 'name', e.target.value)}
                                            required
                                            disabled={isLoading}
                                        />
                                        <Textarea
                                            label={`${t('common.description')} (${language?.label || lang})`}
                                            value={translation.description}
                                            onChange={(e) => handleTranslationChange(lang, 'description', e.target.value)}
                                            disabled={isLoading}
                                        />
                                    </div>
                                </div>
                            );
                        })}

                        <div className="flex items-center py-2">
                            <Toggle
                                label={t('common.isAvailable')}
                                enabled={formData.isAvailable}
                                onToggle={handleToggle}
                                disabled={isLoading}
                            />
                        </div>

                        <div className="flex justify-end">
                            <Button
                                type="submit"
                                className="bg-primary hover:bg-primary/90"
                                disabled={isLoading}
                            >
                                {isLoading ? t('common.saving') : itemId ? t('common.update') : t('common.add')}
                            </Button>
                        </div>
                    </form>
                </CardBody>
            </Card>
        </div>
    );
};

export default MenuItemForm;
