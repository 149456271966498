import React from 'react';
import { Typography } from '@material-tailwind/react';
import { Restaurant } from '../../../types';
import { GlobeAltIcon } from '@heroicons/react/24/outline';
import { useDispatch } from 'react-redux';
import { setCurrentLanguage } from '../../../store/slices/languageSlice';

const AVAILABLE_LANGUAGES = [
    { code: 'en', label: 'English' },
    { code: 'pl', label: 'Polish' },
    { code: 'it', label: 'Italian' },
    { code: 'jp', label: 'Japanese' },
];

interface Props {
    restaurant: Restaurant;
    currentLanguage: string;
}

const RestaurantHeader: React.FC<Props> = ({ restaurant, currentLanguage }) => {
    const dispatch = useDispatch();
    const currentTranslation = restaurant.translations.find(
        t => t.language === currentLanguage
    );

    const handleLanguageChange = (langCode: string) => {
        dispatch(setCurrentLanguage(langCode));
    };

    return (
        <div className="mb-8">
            <div className="bg-white rounded-xl shadow-sm p-6">
                {/* Languages at the top right */}
                <div className="flex justify-end mb-4">
                    <div className="flex items-center gap-2">
                        <GlobeAltIcon className="h-5 w-5 text-blue-800" />
                        <div className="flex flex-wrap gap-1.5">
                            {restaurant.supportedLanguages.map((lang) => {
                                const language = AVAILABLE_LANGUAGES.find(l => l.code === lang);
                                const isActive = lang === currentLanguage;

                                return (
                                    <button
                                        key={lang}
                                        onClick={() => handleLanguageChange(lang)}
                                        className={`
                                            inline-flex items-center px-2.5 py-1.5 rounded-md
                                            text-sm font-medium transition-colors
                                            hover:ring-2 hover:ring-offset-1
                                            ${isActive
                                            ? 'bg-blue-100 text-blue-800 hover:ring-blue-300'
                                            : 'bg-gray-100 text-gray-600 hover:bg-gray-200 hover:ring-gray-300'
                                        }
                                            ${isActive ? 'cursor-default' : 'cursor-pointer'}
                                        `}
                                        disabled={isActive}
                                        aria-pressed={isActive}
                                        title={`Switch to ${language?.label || lang}`}
                                    >
                                        {language?.label || lang}
                                        {isActive && (
                                            <span className="sr-only">(current language)</span>
                                        )}
                                    </button>
                                );
                            })}
                        </div>
                    </div>
                </div>

                {/* Main content with logo and text */}
                <div className="flex flex-col md:flex-row gap-6">
                    {/* Logo section */}
                    {restaurant.logoUrl ? (
                        <img
                            src={`${restaurant.logoUrl}`}
                            className="h-48 w-full object-cover lg:w-72 rounded-lg"
                        />
                    ) : (
                        <div className="h-48 w-full bg-gray-200 flex items-center justify-center rounded-t-md lg:w-72 rounded-lg">
                            <span className="text-3xl text-gray-500 font-light">
                                No Logo
                            </span>
                        </div>
                    )}

                    {/* Text content section */}
                    <div className="flex-grow">
                        <div className="space-y-3 text-left">
                            <Typography variant="h3" className="text-3xl font-bold text-gray-800">
                                {currentTranslation?.name || restaurant.name}
                            </Typography>

                            {currentTranslation?.description && (
                                <div className="max-w-3xl">
                                    <Typography className="text-gray-600 leading-relaxed">
                                        {currentTranslation.description || restaurant.description}
                                    </Typography>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RestaurantHeader;
