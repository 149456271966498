// File: src/components/common/Footer.tsx
import React from 'react'
import { Typography } from '@material-tailwind/react'

const FooterComponent: React.FC = () => {
    return (
        <footer className="p-4 bg-white border-t border-gray-200">
            <Typography variant="small" color="gray">
                © {new Date().getFullYear()} QckOrder. All rights reserved.
            </Typography>
        </footer>
    )
}

export default FooterComponent
