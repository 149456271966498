import api from '../../services/api';
import {MenuFormData} from './types';

export const validateForm = (formData: MenuFormData): string | null => {
    if (!formData.restaurantId) {
        return 'Please select a restaurant';
    }

    if (!formData.translations.some(t => t.name.trim())) {
        return 'Menu name is required for at least one language';
    }

    const invalidCategory = formData.categories.find(
        cat => !cat.translations.some(t => t.name.trim())
    );

    if (invalidCategory) {
        return 'All categories must have a name in at least one language';
    }

    return null;
};

export const createMenu = async (formData: MenuFormData) => {
    // First create the menu
    const menuPayload = {
        restaurantId: formData.restaurantId,
        isActive: formData.isActive,
        translations: formData.translations,
    };

    const menuResponse = await api.post('/menus', menuPayload);
    const menuId = menuResponse.data.id;

    // Then create categories
    for (const category of formData.categories) {
        const categoryPayload = {
            restaurantId: formData.restaurantId,
            translations: category.translations,
            order: category.order
        };

        try {
            const categoryResponse = await api.post('/categories', categoryPayload);
            const categoryId = categoryResponse.data.id;

            // Associate category with menu and items
            await api.post(`/menus/${menuId}/categories`, {
                categoryId,
                menuItemIds: category.menuItemIds
            });
        } catch (error) {
            console.error('Error creating category:', error);
            throw error;
        }
    }

    return menuResponse.data;
};

export const updateMenu = async (menuId: string, formData: MenuFormData) => {
    // Update basic menu info
    const menuPayload = {
        restaurantId: formData.restaurantId,
        isActive: formData.isActive,
        translations: formData.translations,
    };

    await api.put(`/menus/${menuId}`, menuPayload);

    // Get existing categories
    const currentCategoriesResponse = await api.get(`/menus/${menuId}/categories`);
    const currentCategories = currentCategoriesResponse.data;

    // Update or create categories
    for (const category of formData.categories) {
        const categoryPayload = {
            restaurantId: formData.restaurantId,
            translations: category.translations,
            order: category.order,
            menuItemIds: category.menuItemIds
        };

        if (category.id && currentCategories.find(c => c.id === category.id)) {
            // Update existing category
            await api.put(`/categories/${category.id}`, categoryPayload);
            // Update menu items association
            await api.put(`/menus/${menuId}/categories/${category.id}`, {
                menuItemIds: category.menuItemIds
            });
        } else {
            // Create new category
            const newCategoryResponse = await api.post('/categories', {
                restaurantId: formData.restaurantId,
                translations: category.translations,
                order: category.order
            });

            // Associate with menu and items
            await api.post(`/menus/${menuId}/categories`, {
                categoryId: newCategoryResponse.data.id,
                menuItemIds: category.menuItemIds
            });
        }
    }

    // Remove deleted categories
    const categoriesToRemove = currentCategories
        .filter(cc => !formData.categories.some(fc => fc.id === cc.id))
        .map(c => c.id);

    for (const categoryId of categoriesToRemove) {
        await api.delete(`/menus/${menuId}/categories/${categoryId}`);
    }
};
