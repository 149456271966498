// File: src/pages/Orders/OrderForm.tsx
import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Typography, Input, Button, Card, CardBody } from '@material-tailwind/react'
import api from '../../services/api.ts'

interface Order {
    id?: string
    orderNumber: string
    status: string
    // Add other fields as necessary
}

const OrderForm: React.FC = () => {
    const { id } = useParams<{ id: string }>()
    const navigate = useNavigate()
    const [order, setOrder] = useState<Order>({
        orderNumber: '',
        status: '',
    })
    const [error, setError] = useState<string>('')

    useEffect(() => {
        const fetchOrder = async () => {
            try {
                const response = await api.get(`/orders/${id}`)
                setOrder(response.data)
            } catch (error) {
                console.error('Error fetching order:', error)
            }
        }

        if (id) {
            fetchOrder()
        }
    }, [id])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setOrder({ ...order, [e.target.name]: e.target.value })
    }

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
        try {
            if (id) {
                await api.put(`/orders/${id}`, order)
            } else {
                await api.post('/orders', order)
            }
            navigate('/orders')
        } catch (err: any) {
            setError(err.response?.data?.message || 'Operation failed')
        }
    }

    return (
        <div className="flex items-center justify-center h-full">
            <Card className="w-96">
                <CardBody>
                    <Typography variant="h5" color="blue-gray" className="mb-4">
                        {id ? 'Edit Order' : 'Add Order'}
                    </Typography>
                    {error && <p className="text-red-500 mb-2">{error}</p>}
                    <form onSubmit={handleSubmit}>
                        <Input
                            label="Order Number"
                            name="orderNumber"
                            type="text"
                            value={order.orderNumber}
                            onChange={handleChange}
                            required
                            className="mb-4"
                        />
                        <Input
                            label="Status"
                            name="status"
                            type="text"
                            value={order.status}
                            onChange={handleChange}
                            required
                            className="mb-4"
                        />
                        <Button type="submit" fullWidth>
                            {id ? 'Update' : 'Create'}
                        </Button>
                    </form>
                </CardBody>
            </Card>
        </div>
    )
}

export default OrderForm
