// File: src/pages/Restaurants/RestaurantList.tsx

import React, {useEffect, useState, useMemo} from 'react';
import {
    Typography,
    Button,
    Card,
    CardBody,
} from '@material-tailwind/react';
import {PlusIcon} from '@heroicons/react/24/outline';
import api from '../../services/api';
import {Link, useNavigate} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {Restaurant} from '../../types/Restaurant';
import SortIndicator from '../../components/Tables/SortIndicator';
import ActionButtons from '../../components/Tables/ActionButtons';
import ViewModeToggle from '../../components/Common/ViewModeToggle';
import {setViewMode, enforceCardView} from '../../store/slices/viewSlice';
import {AppDispatch, RootState} from "../../store";
import RestaurantCard from "../../components/Cards/RestaurantCard.tsx";
import RestaurantsCards from "../../components/Cards/RestaurantsCards.tsx";
import RestaurantsTable from "../../components/Tables/RestaurantsTable.tsx";
import {t} from "i18next";

const RestaurantList: React.FC = () => {
    const [restaurants, setRestaurants] = useState<Restaurant[]>([]);
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();
    const userRole = useSelector((state: RootState) => state.auth.user?.role?.name);
    const viewMode = useSelector((state: RootState) => state.view.mode);
    const currentLanguage = useSelector((state: RootState) => state.language.current); // Assuming you have a language slice


    useEffect(() => {
        const fetchRestaurants = async () => {
            try {
                const response = await api.get('/restaurants');
                setRestaurants(response.data);
            } catch (error) {
                console.error('Error fetching restaurants:', error);
            }
        };

        if (userRole === 'Admin') {
            fetchRestaurants();
        }
    }, [userRole]);

    useEffect(() => {
        const storedViewMode = localStorage.getItem('viewMode');
        if (storedViewMode === 'card' || storedViewMode === 'list') {
            dispatch(setViewMode(storedViewMode as 'card' | 'list'));
        } else {
            dispatch(setViewMode('list')); // Default to 'list' if not set
        }

        const handleResize = () => {
            if (window.innerWidth < 1024) {
                dispatch(enforceCardView());
            }
        };

        window.addEventListener('resize', handleResize);

        // Initial check
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [dispatch]);


    const isAdmin = userRole === 'Admin';

    return (
        <div className="container mx-auto px-4 py-6">
            <div className="flex flex-col sm:flex-row justify-between items-center mb-6">
                <Typography variant="h4" className="text-neutralDark">
                    {t('sections.restaurants')}
                </Typography>
                <div className="flex items-center space-x-2 mt-4 sm:mt-0">
                    <ViewModeToggle/>
                    <Button
                        className="flex items-center bg-primary hover:bg-opacity-90"
                        onClick={() => navigate('/restaurants/create')}
                        aria-label={t('buttons.addRestaurant')}
                    >
                        <PlusIcon className="h-5 w-5 mr-2"/>
                        {t('buttons.addRestaurant')}
                    </Button>
                </div>
            </div>

            {viewMode === 'card' ? (
                <RestaurantsCards restaurants={restaurants} isAdmin={isAdmin}
                                  setRestaurants={setRestaurants}/>
            ) : (
                <RestaurantsTable restaurants={restaurants} isAdmin={isAdmin}
                                  setRestaurants={setRestaurants} currentLanguage={currentLanguage}
                                  showBar={false}
                />
            )}
        </div>
    );
}

export default RestaurantList;
