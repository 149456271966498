// File: src/store/index.ts

import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice';
import viewReducer from './slices/viewSlice';
import sidebarReducer from './slices/sidebarSlice';
import languageReducer from './slices/languageSlice';

const store = configureStore({
    reducer: {
        auth: authReducer,
        view: viewReducer,
        sidebar: sidebarReducer,
        language: languageReducer
        // Include other reducers if necessary
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
