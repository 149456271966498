// src/components/RestaurantForm.tsx

import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    Typography,
    Input,
    Button,
    Card,
    CardBody,
    CardHeader,
    Textarea,
    Select as MTSelect,
    Option,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
} from '@material-tailwind/react';
import { useDropzone } from 'react-dropzone';
import api from '../../services/api';
import { Restaurant, Translation } from '../../types/Restaurant';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import ReactSelect, { SingleValue } from 'react-select';
import { Currency, CURRENCIES } from '../../constants/currencies';
import {PREVIEW_LANGUAGES} from "../../constants/languages.ts";
import {t} from "i18next";

const RestaurantForm: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const [restaurant, setRestaurant] = useState<Restaurant>({
        id: '',
        supportedLanguages: ['pl'],
        translations: [{ language: 'pl', description: '', name: '' }],
        logoUrl: '',
        currency: { name: 'PLN', symbol: 'zł', symbolPosition: 'after' },
    });
    const [logoFile, setLogoFile] = useState<File | null>(null);
    const [error, setError] = useState<string>('');
    const [isLoading, setIsLoading] = useState(false);
    const [supportedLanguages, setSupportedLanguages] = useState<string[]>(['pl']);
    const [translations, setTranslations] = useState<Translation[]>([
        { language: 'pl', description: '', name: '' },
    ]);
    const [isConfirmOpen, setIsConfirmOpen] = useState<{
        open: boolean;
        language: string | null;
    }>({ open: false, language: null });
    const [selectedCurrency, setSelectedCurrency] = useState<Currency>({
        name: 'PLN',
        symbol: 'zł',
        symbolPosition: 'after',
    });

    const userRole = useSelector(
        (state: RootState) => state.auth.user?.role?.name
    );
    const isAdmin = userRole === 'Admin';

    useEffect(() => {
        const fetchRestaurant = async () => {
            setIsLoading(true);
            try {
                const response = await api.get(`/restaurants/${id}`);
                const restaurantData = response.data;
                setRestaurant(restaurantData);

                // Set supported languages
                const langs = restaurantData.supportedLanguages || ['pl'];
                setSupportedLanguages(langs);

                // Initialize translations for all supported languages
                const initialTranslations = langs.map((lang) => {
                    const existingTranslation = restaurantData.translations.find(
                        (t) => t.language === lang
                    );
                    return (
                        existingTranslation || {
                            language: lang,
                            name: '',
                            description: '',
                        }
                    );
                });

                setTranslations(initialTranslations);

                // Set selected currency
                if (restaurantData.currency && restaurantData.currencySymbol) {
                    const currency = CURRENCIES.find(
                        (c) =>
                            c.name === restaurantData.currency &&
                            c.symbol === restaurantData.currencySymbol
                    );
                    if (currency) {
                        setSelectedCurrency(currency);
                    } else {
                        setSelectedCurrency({ name: 'PLN', symbol: 'zł', symbolPosition: 'after' });
                    }
                } else {
                    setSelectedCurrency({ name: 'PLN', symbol: 'zł', symbolPosition: 'after' });
                }
            } catch (error) {
                console.error('Error fetching restaurant:', error);
                setError('Failed to fetch restaurant details.');
            } finally {
                setIsLoading(false);
            }
        };

        if (id) {
            fetchRestaurant();
        }
    }, [id]);

    const onDrop = useCallback((acceptedFiles: File[]) => {
        if (acceptedFiles?.[0]) {
            const file = acceptedFiles[0];
            setLogoFile(file);
            setRestaurant((prev) => ({
                ...prev,
                logoUrl: URL.createObjectURL(file),
            }));
        }
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            'image/*': ['.jpeg', '.jpg', '.png', '.gif'],
        },
        multiple: false,
    });

    const handleLanguageChange = (language: string) => {
        if (!language || supportedLanguages.includes(language)) return;

        setSupportedLanguages((prev) => [...prev, language]);
        setTranslations((prev) => [
            ...prev,
            { language, name: '', description: '' },
        ]);
    };

    const handleLanguageRemove = (language: string) => {
        if (language === 'en') {
            setError('Cannot remove the default language (English)');
            return;
        }
        setIsConfirmOpen({ open: true, language });
    };

    const handleCloseModal = useCallback(() => {
        setIsConfirmOpen({ open: false, language: null });
    }, []);

    const confirmLanguageRemove = () => {
        const language = isConfirmOpen.language;
        if (language) {
            setSupportedLanguages((prev) => prev.filter((lang) => lang !== language));
            setTranslations((prev) => prev.filter((t) => t.language !== language));
        }
        handleCloseModal();
    };

    const handleTranslationChange = (
        language: string,
        field: 'name' | 'description',
        value: string
    ) => {
        setTranslations((prev) =>
            prev.map((t) =>
                t.language === language ? { ...t, [field]: value } : t
            )
        );
    };

    const validateForm = (): boolean => {
        // Ensure each translation has a name
        const missingNames = translations.filter((t) => !t.name.trim());
        if (missingNames.length > 0) {
            setError('Restaurant name is required for all languages');
            return false;
        }
        return true;
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!validateForm()) return;

        setIsLoading(true);
        setError('');

        try {
            const payload: Partial<Restaurant> = {
                supportedLanguages,
                translations,
                currency: selectedCurrency,
            };
            payload.name = translations[0].name;
            payload.description = translations[0].description;

            payload.translations = payload.translations!.map((t) => ({
                ...t,
                name: t.name || payload.name,
            }));

            let savedRestaurant;
            if (id) {
                const response = await api.put(`/restaurants/${id}`, payload);
                savedRestaurant = response.data;
            } else {
                const response = await api.post('/restaurants', payload);
                savedRestaurant = response.data;
            }

            if (logoFile) {
                const formData = new FormData();
                formData.append('file', logoFile);
                await api.post(
                    `/restaurants/${savedRestaurant.id}/upload-logo`,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                );
            }

            navigate('/restaurants');
        } catch (err: any) {
            setError(err.response?.data?.message || 'Operation failed');
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading && id) {
        return (
            <div className="flex justify-center items-center min-h-screen">
                <Typography>Loading restaurant details...</Typography>
            </div>
        );
    }

    return (
        <div className="container mx-auto px-4 py-6">
            <Card className="max-w-4xl mx-auto">
                <CardHeader className="bg-primary text-white p-4">
                    <Typography variant="h5" color="white">
                        {id ? t('sections.restaurantsEdit') : t('sections.restaurantsCreate')}
                    </Typography>
                </CardHeader>
                <CardBody>
                    {error && (
                        <div className="bg-red-50 text-red-700 p-3 rounded-lg mb-4">
                            {error}
                        </div>
                    )}
                    <form onSubmit={handleSubmit} className="space-y-6">
                        {/* Supported Languages */}
                        <div>
                            <Typography variant="small" className="mb-2 font-medium">
                                { t('common.supportedLanguages') }
                            </Typography>
                            <div className="flex flex-wrap flex-col gap-2 items-start">
                                <div className="languages flex flex-row flex-wrap gap-2">
                                    {supportedLanguages.map((lang) => {
                                        const language = PREVIEW_LANGUAGES.find(
                                            (l) => l.code === lang
                                        );
                                        const isDefaultLanguage = lang === 'pl';

                                        return (
                                            <div
                                                key={lang}
                                                className={`
                          inline-flex items-center gap-2 px-3 py-1.5 rounded-lg
                          bg-blue-100 text-blue-800 
                          ${
                                                    !isDefaultLanguage
                                                        ? 'pr-2 hover:bg-blue-200 cursor-pointer'
                                                        : ''
                                                }
                        `}
                                            >
                        <span className="font-medium">
                          {language ? language.label : lang}
                        </span>
                                                {!isDefaultLanguage && (
                                                    <button
                                                        type="button"
                                                        onClick={() => handleLanguageRemove(lang)}
                                                        className="p-0.5 rounded-full hover:bg-blue-200 bg-neutralLight transition-colors"
                                                        aria-label={`Remove ${language?.label || lang} language`}
                                                    >
                                                        <XMarkIcon className="h-4 w-4 text-blue-800" />
                                                    </button>
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>

                                <div className="border-2 rounded-md w-full mt-4">
                                    <MTSelect
                                        label={ t('common.addLanguage') }
                                        onChange={(value: string) => handleLanguageChange(value)}
                                        className="w-full"
                                    >
                                        {PREVIEW_LANGUAGES.filter(
                                            (l) => !supportedLanguages.includes(l.code)
                                        ).map((lang) => (
                                            <Option key={lang.code} value={lang.code}>
                                                {lang.label}
                                            </Option>
                                        ))}
                                    </MTSelect>
                                </div>
                            </div>
                        </div>

                        {/* Translations */}
                        {supportedLanguages.map((lang) => {
                            const language = PREVIEW_LANGUAGES.find(
                                (l) => l.code === lang
                            );
                            const translation =
                                translations.find((t) => t.language === lang) || {
                                    language: lang,
                                    name: '',
                                    description: '',
                                };
                            return (
                                <div key={lang} className="border p-4 rounded-md">
                                    <Typography variant="h6" className="mb-2">
                                        {language
                                            ? `${language.label} Translation`
                                            : `Translation (${lang})`}
                                    </Typography>
                                    <div className="space-y-4">
                                        <Input
                                            size="lg"
                                            label={ t('common.name') }
                                            name={`name-${lang}`}
                                            value={translation.name}
                                            onChange={(e) =>
                                                handleTranslationChange(
                                                    lang,
                                                    'name',
                                                    e.target.value
                                                )
                                            }
                                            required
                                        />
                                        <Textarea
                                            label={ t('common.description') }
                                            name={`description-${lang}`}
                                            value={translation.description}
                                            onChange={(e) =>
                                                handleTranslationChange(
                                                    lang,
                                                    'description',
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                            );
                        })}

                        {/* Currency Selector */}
                        <div>
                            <Typography variant="small" className="mb-2 font-medium text-left">
                                { t('common.currency') }
                            </Typography>
                            <ReactSelect
                                options={CURRENCIES.map((currency) => ({
                                    value: currency.name,
                                    label: `${currency.name} (${currency.symbol})`,
                                    currency,
                                }))}
                                value={{
                                    value: selectedCurrency.name,
                                    label: `${selectedCurrency.name} (${selectedCurrency.symbol})`,
                                    currency: selectedCurrency,
                                }}
                                onChange={(option: SingleValue<any>) => {
                                    if (option && option.currency) {
                                        setSelectedCurrency(option.currency);
                                    }
                                }}
                                isSearchable
                                placeholder={t('common.selectCurrency')}
                            />
                        </div>

                        {/* Logo Upload */}
                        <div>
                            <Typography variant="small" className="mb-2 font-medium">
                                Logo
                            </Typography>
                            <div
                                {...getRootProps()}
                                className={`border-2 border-dashed rounded-md p-4 text-center cursor-pointer ${
                                    isDragActive
                                        ? 'border-blue-400 bg-blue-50'
                                        : 'border-gray-300'
                                }`}
                            >
                                <input {...getInputProps()} />
                                {restaurant.logoUrl ? (
                                    <div className="flex flex-col items-center">
                                        <img
                                            src={restaurant.logoUrl}
                                            alt="Logo Preview"
                                            className="h-32 w-32 object-cover mb-2"
                                        />
                                        <p className="text-gray-500">
                                            Drag and drop a new logo, or click to select
                                        </p>
                                    </div>
                                ) : (
                                    <p className="text-gray-500">
                                        Drag and drop a logo here, or click to select
                                    </p>
                                )}
                            </div>
                        </div>

                        {/* Submit Button */}
                        <div className="flex justify-end">
                            <Button
                                type="submit"
                                className="bg-primary hover:bg-opacity-90"
                                disabled={isLoading}
                            >
                                {isLoading ? t('common.saving') : id ? t('common.update') : t('common.add')}
                            </Button>
                        </div>
                    </form>
                </CardBody>
            </Card>

            {/* Confirmation Dialog for Removing Language */}
            <Dialog open={isConfirmOpen.open} handler={handleCloseModal}>
                <DialogHeader className={`text-neutralDark`}>
                    Confirm Removal
                </DialogHeader>
                <DialogBody divider>
                    <div className="flex items-center space-x-2">
                        <ExclamationTriangleIcon className="h-6 w-6 text-red-500" />
                        <Typography className={`text-neutralDark`}>
                            Are you sure you want to remove the{' '}
                            {
                                PREVIEW_LANGUAGES.find(
                                    (l) => l.code === isConfirmOpen.language
                                )?.label || isConfirmOpen.language
                            }{' '}
                            translation? This action cannot be undone.
                        </Typography>
                    </div>
                </DialogBody>
                <DialogFooter>
                    <Button
                        variant="text"
                        color="red"
                        onClick={handleCloseModal}
                        className="mr-2"
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="gradient"
                        color="red"
                        onClick={confirmLanguageRemove}
                        disabled={!isConfirmOpen.language}
                    >
                        {t('common.remove')}
                    </Button>
                </DialogFooter>
            </Dialog>
        </div>
    );
};

export default RestaurantForm;
