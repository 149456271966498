import React from 'react';
import { Card, CardBody, Typography } from '@material-tailwind/react';
import { Link } from 'react-router-dom';
import ActionButtons from '../Tables/ActionButtons';
import { Location } from '../../types';
import {t} from "i18next";

interface Props {
    locations: Location[];
    isAdmin: boolean;
    setLocations: React.Dispatch<React.SetStateAction<Location[]>>;
    currentLanguage: string;
}

const LocationsCards: React.FC<Props> = ({ locations, isAdmin, setLocations, currentLanguage }) => {
    return (
        <div>

            {
                locations.length === 0 ? (
                    <div className="card mx-auto shadow-lg rounded-lg">
                        <div className="flex flex-col items-center p-6 card-content w-full">
                            <Typography variant="body1" className="text-center w-full text-gray-700">
                                {t('common.noLocations')}
                            </Typography>
                        </div>
                    </div>
                ) : (
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 mb-8">
                        {locations.map((location) => (
                            <Card
                                key={location.id}
                                className="flex flex-col shadow-lg hover:shadow-xl transition-shadow duration-300"
                            >
                                {/* Restaurant Logo and Name (Admin Only) */}
                                {isAdmin && location.restaurant && (
                                    <div className="flex flex-col items-center">
                                        {location.restaurant.logoUrl ? (
                                            <img
                                                src={location.restaurant.logoUrl}
                                                alt={location.restaurant.name}
                                                className="h-48 w-full object-cover rounded-t-md"
                                                onError={(e) => {
                                                    (e.target as HTMLImageElement).src = '/no-logo.png';
                                                }}
                                            />
                                        ) : (
                                            <div className="h-48 w-full bg-gray-200 flex items-center justify-center rounded-t-md">
                                                <span className="text-5xl text-gray-500 font-light">
                                                    {t('common.noImage')}
                                                </span>
                                            </div>
                                        )}
                                        <Link
                                            to={`/restaurants/${location.restaurant.id}`}
                                            className="text-primary font-semibold text-2xl mt-6"
                                        >
                                        {location.restaurant.name}
                                        </Link>
                                    </div>
                                )}

                                {/* Location Details */}
                                <CardBody className="flex-1 flex flex-col justify-between">
                                    <div>
                                        <Link to={`/locations/${location.id}`} className="text-primary hover:text-primary-dark mb-4 flex justify-center font-semibold text-xl">
                                            {location.name}
                                        </Link>
                                        <Typography variant="body2" className="mb-1 text-sm text-gray-700">
                                            <strong>{t('common.address')}:</strong> {location.address}
                                        </Typography>
                                        <Typography variant="body2" className="mb-1 text-sm text-gray-700">
                                            <strong>{t('common.phone')}:</strong> {location.phoneNumber}
                                        </Typography>
                                        <Typography variant="body2" className="mb-1 text-sm text-gray-700">
                                            <strong>{t('common.activeMenu')}</strong>{' '}
                                            {location.activeMenu ? (
                                                <Link
                                                    to={`/menus/${location.activeMenu.id}`}
                                                    className="text-primary hover:text-primary-dark"
                                                >
                                                    {location.activeMenu.translations.find(
                                                        (t) => t.language === currentLanguage
                                                    )?.name || 'Untitled Menu'}
                                                </Link>
                                            ) : (
                                                <span className="text-gray-400">No active menu</span>
                                            )}
                                        </Typography>
                                    </div>
                                    {/* Action Buttons */}
                                    {isAdmin && (
                                        <div className="flex justify-end space-x-2 mt-4">
                                            <ActionButtons
                                                id={location.id}
                                                name={location.name}
                                                type="location"
                                                setItems={setLocations}
                                            />
                                        </div>
                                    )}
                                </CardBody>
                            </Card>
                        ))}
                    </div>
                )
            }
        </div>
    );
};

export default LocationsCards;
