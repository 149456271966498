// File: src/main.tsx
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import AppRoutes from './routes';
import { Provider } from 'react-redux';
import store from './store';
import {I18nextProvider} from "react-i18next";
import i18n from "./i18n.ts";

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <Provider store={store}>
            <I18nextProvider i18n={i18n}>
                <AppRoutes />
            </I18nextProvider>
        </Provider>
    </React.StrictMode>
);
