import React from 'react';
import { Card, CardBody, Typography, Button } from '@material-tailwind/react';
import { Link } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import {MenuItem, Restaurant} from '../../types';
import ActionButtons from "../Tables/ActionButtons.tsx";
import {t} from "i18next";
import Price from "../Common/Price.tsx";

interface Translation {
    id: string;
    language: string;
    name: string;
    description: string;
}

interface MenuItemWithTranslations extends MenuItem {
    translations: Translation[];
}

interface Props {
    menuItems: MenuItemWithTranslations[];
    isAdmin: boolean;
    setMenuItems: React.Dispatch<React.SetStateAction<MenuItemWithTranslations[]>>;
    restaurant?: Restaurant
}

const MenuItemsCards: React.FC<Props> = ({ menuItems, isAdmin, setMenuItems, restaurant }) => {
    const currentLanguage = useSelector((state: RootState) => state.language.current);
    console.log(restaurant)

    const getTranslation = (
        item: MenuItemWithTranslations,
        field: 'name' | 'description',
        fallback: string
    ): string => {
        const translation = item.translations.find(t => t.language === currentLanguage);

        if (!translation) {
            const defaultTranslation = item.translations.find(t => t.language === 'en');
            return defaultTranslation ? defaultTranslation[field] || fallback : fallback;
        }

        return translation[field] || fallback;
    };

    return (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
            {menuItems.map((item) => (
                <Card key={item.id} className="flex flex-col">
                    {item.imageUrl ? (
                        <img
                            src={item.imageUrl}
                            alt={getTranslation(item, 'name', 'Untitled Item')}
                            className="h-48 w-full object-cover rounded-t-md"
                        />
                    ) : (
                        <div className="h-48 w-full bg-gray-200 flex items-center justify-center rounded-t-md">
                            <span className="text-5xl text-gray-500 font-light">
                                {t('common.noImage')}
                            </span>
                        </div>
                    )}
                    <CardBody className="flex-1 flex flex-col justify-between">
                        <div>
                            <Typography variant="h5" className="font-bold mb-2">
                                {getTranslation(item, 'name', t('common.untitledItem'))}
                            </Typography>
                            <Typography variant="body2" className="text-gray-600 mb-4">
                                {getTranslation(item, 'description', t('common.noDescription'))}
                            </Typography>
                            <Typography variant="body2" className="font-semibold flex gap-2 justify-center w-full">
                                {t('common.price')}: <Price item={restaurant ? {
                                    ...item,
                                    restaurant
                            } : item} />
                            </Typography>
                        </div>
                        {isAdmin && (
                            <div className="flex justify-end space-x-2 mt-4">
                                <ActionButtons
                                    id={item.id}
                                    name={getTranslation(item, 'name', 'Untitled Item')}
                                    type="menu-item"
                                    setItems={setMenuItems}
                                />
                            </div>
                        )}
                    </CardBody>
                </Card>
            ))}
            {menuItems.length === 0 && (
                <Typography variant="body1" className="text-center w-full">
                    No menu items available.
                </Typography>
            )}
        </div>
    );
};

export default MenuItemsCards;
