// src/components/RoleProtectedRoute.tsx
import React from 'react';
import { Navigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

interface RoleProtectedRouteProps {
    allowedRoles: string[];
    Component: React.FC;
}

const RoleProtectedRoute: React.FC<RoleProtectedRouteProps> = ({ allowedRoles, Component }) => {
    const { hasRole } = useAuth();

    return hasRole(allowedRoles) ? <Component /> : <Navigate to="/unauthorized" replace />;
};

export default RoleProtectedRoute;
