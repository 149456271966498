// File: src/pages/Register.tsx
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { setCredentials } from '../store/slices/authSlice.ts'
import { useNavigate } from 'react-router-dom'
import {
    Typography,
    Input,
    Button,
    Card,
    CardBody,
} from '@material-tailwind/react'
import api from '../services/api.ts'

const Register: React.FC = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [firstName, setFirstName] = useState<string>('')
    const [lastName, setLastName] = useState<string>('')
    const [error, setError] = useState<string>('')

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
        try {
            const response = await api.post('/auth/register', {
                email,
                password,
                firstName,
                lastName,
            })
            dispatch(
                setCredentials({
                    user: response.data.user,
                    token: response.data.access_token,
                })
            )
            navigate('/')
        } catch (err: any) {
            setError(err.response?.data?.message || 'Registration failed')
        }
    }

    return (
        <div className="flex items-center justify-center h-screen bg-gray-100">
            <Card className="w-96">
                <CardBody>
                    <Typography variant="h5" color="blue-gray" className="mb-4">
                        Register
                    </Typography>
                    {error && <p className="text-red-500 mb-2">{error}</p>}
                    <form onSubmit={handleSubmit}>
                        <Input
                            label="First Name"
                            type="text"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            required
                            className="mb-4"
                        />
                        <Input
                            label="Last Name"
                            type="text"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            required
                            className="mb-4"
                        />
                        <Input
                            label="Email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            className="mb-4"
                        />
                        <Input
                            label="Password"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            className="mb-4"
                        />
                        <Button type="submit" fullWidth>
                            Register
                        </Button>
                    </form>
                </CardBody>
            </Card>
        </div>
    )
}

export default Register
