// src/constants/currencies.ts

export interface Currency {
    name: string;
    symbol: string;
    symbolPosition?: 'before' | 'after';
}

// src/constants/currencies.ts

export const CURRENCIES: Currency[] = [
    { name: 'PLN', symbol: 'zł', symbolPosition: 'after' },
    { name: 'USD', symbol: '$', symbolPosition: 'before' },
    { name: 'EUR', symbol: '€', symbolPosition: 'before' },
    { name: 'GBP', symbol: '£', symbolPosition: 'before' },
    { name: 'JPY', symbol: '¥', symbolPosition: 'before' },
    { name: 'CNY', symbol: '¥', symbolPosition: 'before' },
    { name: 'AUD', symbol: '$', symbolPosition: 'before' }, // Australian Dollar
    { name: 'CAD', symbol: '$', symbolPosition: 'before' }, // Canadian Dollar
    { name: 'CHF', symbol: 'CHF', symbolPosition: 'before' }, // Swiss Franc
    { name: 'SEK', symbol: 'kr', symbolPosition: 'after' }, // Swedish Krona
    { name: 'NZD', symbol: '$', symbolPosition: 'before' }, // New Zealand Dollar
    { name: 'MXN', symbol: '$', symbolPosition: 'before' }, // Mexican Peso
    { name: 'SGD', symbol: '$', symbolPosition: 'before' }, // Singapore Dollar
    { name: 'HKD', symbol: '$', symbolPosition: 'before' }, // Hong Kong Dollar
    { name: 'NOK', symbol: 'kr', symbolPosition: 'after' }, // Norwegian Krone
    { name: 'KRW', symbol: '₩', symbolPosition: 'before' }, // South Korean Won
    { name: 'TRY', symbol: '₺', symbolPosition: 'before' }, // Turkish Lira
    { name: 'RUB', symbol: '₽', symbolPosition: 'before' }, // Russian Ruble
    { name: 'INR', symbol: '₹', symbolPosition: 'before' }, // Indian Rupee
    { name: 'BRL', symbol: 'R$', symbolPosition: 'before' }, // Brazilian Real
    { name: 'ZAR', symbol: 'R', symbolPosition: 'before' }, // South African Rand
    { name: 'MYR', symbol: 'RM', symbolPosition: 'before' }, // Malaysian Ringgit
    { name: 'THB', symbol: '฿', symbolPosition: 'before' }, // Thai Baht
    { name: 'PHP', symbol: '₱', symbolPosition: 'before' }, // Philippine Peso
    { name: 'IDR', symbol: 'Rp', symbolPosition: 'before' }, // Indonesian Rupiah
    { name: 'ILS', symbol: '₪', symbolPosition: 'before' }, // Israeli New Shekel
    { name: 'DKK', symbol: 'kr', symbolPosition: 'after' }, // Danish Krone
    { name: 'CZK', symbol: 'Kč', symbolPosition: 'after' }, // Czech Koruna
    { name: 'HUF', symbol: 'Ft', symbolPosition: 'after' }, // Hungarian Forint
    { name: 'AED', symbol: 'د.إ', symbolPosition: 'before' }, // United Arab Emirates Dirham
    { name: 'SAR', symbol: '﷼', symbolPosition: 'before' }, // Saudi Riyal
    { name: 'NGN', symbol: '₦', symbolPosition: 'before' }, // Nigerian Naira
    { name: 'TWD', symbol: 'NT$', symbolPosition: 'before' }, // New Taiwan Dollar
    { name: 'VND', symbol: '₫', symbolPosition: 'before' }, // Vietnamese Dong
    { name: 'EGP', symbol: '£', symbolPosition: 'before' }, // Egyptian Pound
    { name: 'PKR', symbol: '₨', symbolPosition: 'before' }, // Pakistani Rupee
    { name: 'BDT', symbol: '৳', symbolPosition: 'before' }, // Bangladeshi Taka
    { name: 'CLP', symbol: '$', symbolPosition: 'before' }, // Chilean Peso
    { name: 'COP', symbol: '$', symbolPosition: 'before' }, // Colombian Peso
    { name: 'ARS', symbol: '$', symbolPosition: 'before' }, // Argentine Peso
    { name: 'DZD', symbol: 'د.ج', symbolPosition: 'before' }, // Algerian Dinar
    { name: 'KWD', symbol: 'د.ك', symbolPosition: 'before' }, // Kuwaiti Dinar
    { name: 'QAR', symbol: 'ر.ق', symbolPosition: 'before' }, // Qatari Rial
    { name: 'OMR', symbol: 'ر.ع.', symbolPosition: 'before' }, // Omani Rial
    { name: 'BHD', symbol: '.د.ب', symbolPosition: 'before' }, // Bahraini Dinar
    { name: 'KZT', symbol: '₸', symbolPosition: 'before' }, // Kazakhstani Tenge
    { name: 'UZS', symbol: 'лв', symbolPosition: 'before' }, // Uzbekistani Som
    { name: 'UAH', symbol: '₴', symbolPosition: 'before' }, // Ukrainian Hryvnia
    { name: 'KES', symbol: 'KSh', symbolPosition: 'before' }, // Kenyan Shilling
    { name: 'GHS', symbol: '₵', symbolPosition: 'before' }, // Ghanaian Cedi
    { name: 'DOP', symbol: 'RD$', symbolPosition: 'before' }, // Dominican Peso
    { name: 'PEN', symbol: 'S/.', symbolPosition: 'before' }, // Peruvian Sol
    { name: 'BOB', symbol: 'Bs.', symbolPosition: 'before' }, // Bolivian Boliviano
    { name: 'MAD', symbol: 'د.م.', symbolPosition: 'before' }, // Moroccan Dirham
    { name: 'RON', symbol: 'lei', symbolPosition: 'after' }, // Romanian Leu
    { name: 'HRK', symbol: 'kn', symbolPosition: 'after' }, // Croatian Kuna
    { name: 'ISK', symbol: 'kr', symbolPosition: 'after' }, // Icelandic Króna
    { name: 'BGN', symbol: 'лв', symbolPosition: 'after' }, // Bulgarian Lev
    { name: 'UYU', symbol: '$U', symbolPosition: 'before' }, // Uruguayan Peso
    { name: 'NPR', symbol: '₨', symbolPosition: 'before' }, // Nepalese Rupee
    { name: 'LKR', symbol: '₨', symbolPosition: 'before' }, // Sri Lankan Rupee
    { name: 'TTD', symbol: 'TT$', symbolPosition: 'before' }, // Trinidad and Tobago Dollar
    { name: 'JMD', symbol: 'J$', symbolPosition: 'before' }, // Jamaican Dollar
    { name: 'SYP', symbol: '£S', symbolPosition: 'before' }, // Syrian Pound
    { name: 'GEL', symbol: 'ლ', symbolPosition: 'before' }, // Georgian Lari
    { name: 'MUR', symbol: '₨', symbolPosition: 'before' }, // Mauritian Rupee
    { name: 'BWP', symbol: 'P', symbolPosition: 'before' }, // Botswana Pula
    { name: 'XOF', symbol: 'CFA', symbolPosition: 'before' }, // West African CFA Franc
    { name: 'XAF', symbol: 'FCFA', symbolPosition: 'before' }, // Central African CFA Franc
    { name: 'XCD', symbol: '$', symbolPosition: 'before' }, // East Caribbean Dollar
    { name: 'MZN', symbol: 'MT', symbolPosition: 'before' }, // Mozambican Metical
    { name: 'LAK', symbol: '₭', symbolPosition: 'before' }, // Lao Kip
    { name: 'MMK', symbol: 'K', symbolPosition: 'before' }, // Myanmar Kyat
    { name: 'TZS', symbol: 'TSh', symbolPosition: 'before' }, // Tanzanian Shilling
    { name: 'GIP', symbol: '£', symbolPosition: 'before' }, // Gibraltar Pound
    { name: 'PKR', symbol: '₨', symbolPosition: 'before' }, // Pakistani Rupee (Duplicate Entry)
    { name: 'BND', symbol: '$', symbolPosition: 'before' }, // Brunei Dollar
    { name: 'YER', symbol: '﷼', symbolPosition: 'before' }, // Yemeni Rial
    { name: 'MWK', symbol: 'MK', symbolPosition: 'before' }, // Malawian Kwacha
    { name: 'ALL', symbol: 'L', symbolPosition: 'before' }, // Albanian Lek
    { name: 'BHD', symbol: '.د.ب', symbolPosition: 'before' }, // Bahraini Dinar (Duplicate Entry)
    { name: 'KWD', symbol: 'د.ك', symbolPosition: 'before' }, // Kuwaiti Dinar (Duplicate Entry)
    { name: 'LYD', symbol: 'ل.د', symbolPosition: 'before' }, // Libyan Dinar
    { name: 'AED', symbol: 'د.إ', symbolPosition: 'before' }, // UAE Dirham (Duplicate Entry)
    { name: 'GNF', symbol: 'FG', symbolPosition: 'before' }, // Guinean Franc
    { name: 'TND', symbol: 'د.ت', symbolPosition: 'before' }, // Tunisian Dinar
    { name: 'SCR', symbol: '₨', symbolPosition: 'before' }, // Seychellois Rupee
    { name: 'BDT', symbol: '৳', symbolPosition: 'before' }, // Bangladeshi Taka (Duplicate Entry)
    { name: 'MGA', symbol: 'Ar', symbolPosition: 'before' }, // Malagasy Ariary
    { name: 'XPF', symbol: '₣', symbolPosition: 'before' }, // CFP Franc
    { name: 'CDF', symbol: 'FC', symbolPosition: 'before' }, // Congolese Franc
    { name: 'DJF', symbol: 'Fdj', symbolPosition: 'before' }, // Djiboutian Franc
    { name: 'GNF', symbol: 'FG', symbolPosition: 'before' }, // Guinean Franc (Duplicate Entry)
    { name: 'CDF', symbol: 'FC', symbolPosition: 'before' }, // Congolese Franc (Duplicate Entry)
    // Add more currencies as needed
];
