import React from 'react';

interface Translation {
    id: string;
    language: string;
    name: string;
    description: string;
}

interface Restaurant {
    id: string;
    name: string;
    description: string;
    logoUrl: string | null;
    supportedLanguages: string[];
    createdAt: string;
    updatedAt: string;
    currency: string;
    currencySymbol: string;
    currencySymbolPosition: 'before' | 'after';
    locationCount: number;
    userCount: number;
}

interface MenuItem {
    id: string;
    restaurantId: string;
    name: string | null;
    description: string | null;
    price: string;
    imageUrl: string | null;
    isAvailable: boolean;
    createdAt: string;
    updatedAt: string;
    translations: Translation[];
    categories: any[];
    restaurant: Restaurant;
}

interface PriceTagProps {
    item: MenuItem;
}

const PriceTag: React.FC<PriceTagProps> = ({ item, style = `font-bold text-lg` }) => {
    const { price, restaurant } = item;
    const { currencySymbol, currencySymbolPosition } = restaurant;

    const formattedPrice = currencySymbolPosition === 'before'
        ? `${currencySymbol}${price}`
        : `${price} ${currencySymbol}`;

    return (
        <div className={style}>
            {formattedPrice}
        </div>
    );
};

export default PriceTag;
