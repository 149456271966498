// src/pages/Menus/MenuForm.tsx

import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    Typography,
    Input,
    Button,
    Card,
    CardBody,
    CardHeader,
    Textarea,
} from '@material-tailwind/react';
import ReactSelect from 'react-select';
import { DragDropContext, Droppable, Draggable, DropResult } from '@hello-pangea/dnd';
import { ArrowRightEndOnRectangleIcon, ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import Toggle from '../../components/Toggle';
import { useMenuForm } from './hooks/useMenuForm';
import { selectStyles } from './styles';
import { AVAILABLE_LANGUAGES } from './types';
import { createMenu, updateMenu, validateForm } from "./methods.ts";
import {t} from "i18next";

const MenuForm: React.FC = () => {
    const { menuId } = useParams<{ menuId?: string }>();
    const navigate = useNavigate();

    const {
        formData,
        setFormData,
        restaurants,
        menuItems,
        supportedLanguages,
        setSupportedLanguages,
        error,
        setError,
        isLoading,
        setIsLoading,
        fetchMenuItems,
        userRole,
        currentLanguage
    } = useMenuForm(menuId);

    const [isCategoriesLoading, setIsCategoriesLoading] = useState(true);

    // Debugging: Log formData.categories whenever it changes
    useEffect(() => {
        console.log('Categories fetched:', formData.categories);
        if (formData.categories.length > 0) {
            setIsCategoriesLoading(false);
        }
    }, [formData.categories]);

    // Verify all categories have unique, non-null string IDs
    useEffect(() => {
        const ids = formData.categories.map(cat => cat.id);
        const uniqueIds = new Set(ids);
        if (ids.length !== uniqueIds.size) {
            console.error("Duplicate category IDs found!");
        }
        formData.categories.forEach(cat => {
            if (!cat.id || typeof cat.id !== 'string') {
                console.error(`Category with invalid ID:`, cat);
            }
        });
    }, [formData.categories]);

    const handleRestaurantChange = async (selected: any) => {
        if (!selected) return;

        const restaurant = restaurants.find(r => r.value === selected.value);
        if (restaurant) {
            setSupportedLanguages(restaurant.supportedLanguages);
            setFormData(prev => ({
                ...prev,
                restaurantId: selected.value,
                translations: restaurant.supportedLanguages.map(lang => ({
                    language: lang,
                    name: '',
                    description: ''
                })),
                categories: [], // Reset categories if restaurant changes
            }));
            await fetchMenuItems(selected.value);
        }
    };

    const handleTranslationChange = (language: string, field: 'name' | 'description', value: string) => {
        setFormData(prev => {
            const existingTranslationIndex = prev.translations.findIndex(t => t.language === language);

            let newTranslations = [...prev.translations];

            if (existingTranslationIndex >= 0) {
                newTranslations[existingTranslationIndex] = {
                    ...newTranslations[existingTranslationIndex],
                    [field]: value
                };
            } else {
                newTranslations.push({
                    language,
                    name: field === 'name' ? value : '',
                    description: field === 'description' ? value : ''
                });
            }

            return {
                ...prev,
                translations: newTranslations
            };
        });
    };


    const handleCategoryTranslationChange = (
        categoryId: string,
        language: string,
        value: string
    ) => {
        setFormData(prev => {
            const updatedCategories = prev.categories.map(cat => {
                if (cat.id !== categoryId) return cat;

                const existingTranslationIndex = cat.translations.findIndex(t => t.language === language);
                let newTranslations = [...cat.translations];

                if (existingTranslationIndex >= 0) {
                    newTranslations[existingTranslationIndex] = {
                        ...newTranslations[existingTranslationIndex],
                        name: value
                    };
                } else {
                    newTranslations.push({
                        language,
                        name: value
                    });
                }

                return {
                    ...cat,
                    translations: newTranslations
                };
            });

            return {
                ...prev,
                categories: updatedCategories
            };
        });
    };

    const addCategory = () => {
        const newId = crypto.randomUUID();
        console.log("Adding new category with ID:", newId); // Debugging log

        setFormData(prev => ({
            ...prev,
            categories: [
                ...prev.categories,
                {
                    id: newId,
                    translations: supportedLanguages.map(lang => ({
                        language: lang,
                        name: ''
                    })),
                    order: prev.categories.length,
                    menuItemIds: []
                }
            ]
        }));
    };

    const removeCategory = (categoryId: string) => {
        console.log("Removing category with ID:", categoryId); // Debugging log

        setFormData(prev => ({
            ...prev,
            categories: prev.categories.filter(cat => cat.id !== categoryId)
        }));
    };

    const handleDragEnd = (result: DropResult) => {
        const { destination, source, draggableId } = result;
        console.log('Drag End Result:', result); // Debugging log

        if (!destination) return;
        if (destination.index === source.index) return;

        const reorderedCategories = Array.from(formData.categories);
        const [movedCategory] = reorderedCategories.splice(source.index, 1);
        reorderedCategories.splice(destination.index, 0, movedCategory);

        // Update the 'order' property based on new index
        const updatedCategories = reorderedCategories.map((cat, index) => ({
            ...cat,
            order: index
        }));

        console.log('Updated Categories Order:', updatedCategories.map(cat => ({ id: cat.id, order: cat.order }))); // Debugging log

        setFormData(prev => ({
            ...prev,
            categories: updatedCategories
        }));
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        const validationError = validateForm(formData);
        if (validationError) {
            setError(validationError);
            return;
        }

        setIsLoading(true);
        setError('');

        try {
            if (menuId) {
                await updateMenu(menuId, formData);
            } else {
                await createMenu(formData);
            }
            navigate('/menus');
        } catch (err: any) {
            console.error('Error saving menu:', err);
            setError(err.response?.data?.message || 'Failed to save menu. Please check all fields and try again.');
        } finally {
            setIsLoading(false);
        }
    };

    // Function to format menu items for ReactSelect
    const formatMenuItemsForSelect = (items: any[]) => {
        if (!items || !Array.isArray(items)) return [];

        return items.map(item => ({
            value: item.id,
            label: item.translations?.find(t => t.language === currentLanguage)?.name ||
                item.translations?.[0]?.name ||
                t('common.unnamedItem')
        }));
    };

    // Function to render menu items selection for a category
    const renderCategoryMenuItems = (category: any) => {
        const selectedItems = menuItems
            .filter(item => category.menuItemIds?.includes(item.id))
            .map(item => ({
                value: item.id,
                label: item.translations?.find(t => t.language === currentLanguage)?.name ||
                    item.translations?.[0]?.name ||
                    t('common.unnamedItem')
            }));

        return (
            <ReactSelect
                isMulti
                options={formatMenuItemsForSelect(menuItems)}
                value={selectedItems}
                onChange={(selected) => handleCategoryMenuItemsChange(category.id, selected)}
                styles={selectStyles}
                placeholder={t('common.selectMenuItems')}
                isDisabled={isLoading}
            />
        );
    };

    const handleCategoryMenuItemsChange = (
        categoryId: string,
        selected: any
    ) => {
        setFormData(prev => ({
            ...prev,
            categories: prev.categories.map(cat =>
                cat.id === categoryId
                    ? { ...cat, menuItemIds: selected ? selected.map((opt: any) => opt.value) : [] }
                    : cat
            )
        }));
    };

    // Create a sorted copy of categories using useMemo
    const sortedCategories = useMemo(() => {
        return [...formData.categories].sort((a, b) => a.order - b.order);
    }, [formData.categories]);

    return (
        <div className={`container mx-auto px-4 py-6 ${isLoading ? 'opacity-50 pointer-events-none' : ''}`}>
            <Card className="max-w-4xl mx-auto">
                <CardHeader className="bg-primary text-white p-4  text-left">
                    <Typography variant="h5" color="white">
                        {menuId ? t('sections.menusEdit') : t('sections.menusCreate')}
                    </Typography>
                </CardHeader>
                <CardBody>
                    {error && (
                        <div className="bg-red-50 text-red-700 p-3 rounded-lg mb-4 flex items-center">
                            <ExclamationTriangleIcon className="h-6 w-6 mr-2" />
                            <span>{error}</span>
                        </div>
                    )}

                    <form onSubmit={handleSubmit} className="space-y-6">
                        {userRole === 'Admin' && (
                            <div className="w-full">
                                <label className="block text-sm font-medium text-gray-700 mb-2 text-left">
                                    {t('common.restaurant')}
                                </label>
                                <ReactSelect
                                    options={restaurants}
                                    value={restaurants.find(r => r.value === formData.restaurantId)}
                                    onChange={handleRestaurantChange}
                                    isSearchable
                                    styles={selectStyles}
                                    className="w-full"
                                    placeholder={t('common.selectARestaurant')}
                                    isDisabled={isLoading}
                                />
                            </div>
                        )}

                        {supportedLanguages.map(lang => {
                            const language = AVAILABLE_LANGUAGES.find(l => l.code === lang);
                            const translation = formData.translations.find(t => t.language === lang) || {
                                language: lang,
                                name: '',
                                description: ''
                            };

                            return (
                                <div key={lang} className="border p-4 rounded-md shadow-sm">
                                    <Typography variant="h6" className="mb-4">
                                        {language?.label || lang}
                                    </Typography>
                                    <div className="space-y-4">
                                        <Input
                                            size="lg"
                                            label={`${t(`common.menuName`)} (${language?.label || lang})`}
                                            value={translation.name}
                                            onChange={(e) => handleTranslationChange(lang, 'name', e.target.value)}
                                            required
                                            disabled={isLoading}
                                        />
                                        <Textarea
                                            label={`${t(`common.menuDescription`)} (${language?.label || lang})`}
                                            value={translation.description}
                                            onChange={(e) => handleTranslationChange(lang, 'description', e.target.value)}
                                            disabled={isLoading}
                                        />
                                    </div>
                                </div>
                            );
                        })}

                        <div className="flex items-center py-2">
                            <Toggle
                                label={t('common.menuActive')}
                                enabled={formData.isActive}
                                onToggle={() => setFormData(prev => ({ ...prev, isActive: !prev.isActive }))}
                                disabled={isLoading}
                            />
                        </div>

                        <div className="mt-6">
                            <div className="flex justify-between items-center mb-4">
                                <Typography variant="h6">{t(`common.categories`)}</Typography>
                                <Button
                                    type="button"
                                    variant="gradient"
                                    color="green"
                                    size="sm"
                                    onClick={addCategory}
                                    disabled={isLoading}
                                >
                                    {t(`buttons.addCategory`)}
                                </Button>
                            </div>

                            {!isCategoriesLoading ? (
                                <DragDropContext onDragEnd={handleDragEnd}>
                                    <Droppable droppableId="categories">
                                        {(provided) => (
                                            <div
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                                className="space-y-4"
                                            >
                                                {sortedCategories.map((category, index) => {
                                                    console.log(`Rendering Draggable for Category ID: ${category.id}`); // Debugging log
                                                    return (
                                                        <Draggable
                                                            key={category.id}
                                                            draggableId={category.id}
                                                            index={index}
                                                        >
                                                            {(provided, snapshot) => (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    className={`${snapshot.isDragging ? 'bg-blue-50' : ''}`}
                                                                >
                                                                    <Card className="p-4 bg-gray-50">
                                                                        <div className="flex justify-between items-center mb-4">
                                                                            <div className="flex items-center gap-2">
                                                                                <div
                                                                                    {...provided.dragHandleProps}
                                                                                    className="cursor-move"
                                                                                >
                                                                                    <ArrowRightEndOnRectangleIcon className="h-5 w-5 text-gray-500" />
                                                                                </div>
                                                                                <Typography variant="h6">
                                                                                    {`${t('common.category')} ${index + 1}`}
                                                                                </Typography>
                                                                            </div>
                                                                            <Button
                                                                                color="red"
                                                                                variant="text"
                                                                                size="sm"
                                                                                onClick={() => removeCategory(category.id)}
                                                                                disabled={isLoading}
                                                                            >
                                                                                {t(`buttons.remove`)}
                                                                            </Button>
                                                                        </div>

                                                                        {supportedLanguages.map((lang) => {
                                                                            const language = AVAILABLE_LANGUAGES.find(l => l.code === lang);
                                                                            const translation = category.translations.find(t => t.language === lang) || {
                                                                                language: lang,
                                                                                name: ''
                                                                            };

                                                                            return (
                                                                                <div key={lang} className="mb-4">
                                                                                    <Input
                                                                                        size="lg"
                                                                                        label={`${t('common.categoryName')} (${language?.label || lang})`}
                                                                                        value={translation.name}
                                                                                        onChange={(e) => handleCategoryTranslationChange(
                                                                                            category.id,
                                                                                            lang,
                                                                                            e.target.value
                                                                                        )}
                                                                                        required
                                                                                        disabled={isLoading}
                                                                                    />
                                                                                </div>
                                                                            );
                                                                        })}

                                                                        <div className="mt-4">
                                                                            <label
                                                                                className="block text-sm font-medium text-gray-700 mb-2 text-left">
                                                                                {t('common.menuItems')}
                                                                            </label>
                                                                            {renderCategoryMenuItems(category)}
                                                                        </div>
                                                                    </Card>
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    );
                                                })}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            ) : (
                                <div>{t('common.loadingCategories')}...</div>
                            )}
                        </div>

                        <div className="flex justify-end">
                            <Button
                                type="submit"
                                className="bg-primary hover:bg-primary/90"
                                disabled={isLoading}
                            >
                                {isLoading ? t(`common.saving`) : (menuId ? t('buttons.save') : t('buttons.add'))}
                            </Button>
                        </div>
                    </form>
                </CardBody>
            </Card>
        </div>
    );

};

export default MenuForm;
