import React, {useEffect} from 'react';
import {Typography, List, ListItem, ListItemPrefix, Tooltip, Select, Option} from '@material-tailwind/react';
import {
    HomeIcon,
    MapPinIcon,
    DocumentTextIcon,
    ClipboardDocumentListIcon,
    ShoppingCartIcon,
    UserGroupIcon,
    BuildingStorefrontIcon,
} from '@heroicons/react/24/solid';
import { NavLink } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import { RootState } from '../../store';
import {PREVIEW_LANGUAGES, setCurrentLanguage} from "../../store/slices/languageSlice.ts";
import {toggleSidebar} from "../../store/slices/sidebarSlice.ts";
import {useTranslation} from "react-i18next";

const SidebarComponent: React.FC = () => {
    const { t, i18n } = useTranslation();
    const isOpen = useSelector((state: RootState) => state.sidebar.isOpen);
    const userRole = useSelector((state: RootState) => state.auth.user?.role?.name);

    const navItems = [
        // { title: t('sidebar.dashboard'), icon: HomeIcon, path: '/', roles: ['Admin', 'Owner', 'Manager', 'Staff'] },
        { title: t('sidebar.restaurants'), icon: BuildingStorefrontIcon, path: '/restaurants', roles: ['Admin'] },
        { title: t('sidebar.locations'), icon: MapPinIcon, path: '/locations', roles: ['Admin', 'Owner', 'Manager'] },
        { title: t('sidebar.menus'), icon: DocumentTextIcon, path: '/menus', roles: ['Admin', 'Owner', 'Manager'] },
        { title: t('sidebar.menuItems'), icon: ClipboardDocumentListIcon, path: '/menu-items', roles: ['Admin', 'Owner', 'Manager'] },
        { title: t('sidebar.orders'), icon: ShoppingCartIcon, path: '/orders', roles: ['Admin', 'Owner', 'Manager', 'Staff'] },
        { title: t('sidebar.users'), icon: UserGroupIcon, path: '/users', roles: ['Admin', 'Owner'] },
    ];

    const currentLanguage = useSelector((state: RootState) => state.language.current);
    const dispatch = useDispatch();
    const handleLanguageChange = (value: string) => {
        dispatch(setCurrentLanguage(value));
    };

    // New handler for i18n language selection
    const handleI18nLanguageChange = (value: string) => {
        i18n.changeLanguage(value);
    };

    return (
        <div
            className={`fixed z-40 h-screen bg-white shadow-md flex flex-col transition-transform duration-300 ${
                isOpen ? 'translate-x-0 w-64' : '-translate-x-full w-64'
            } lg:translate-x-0 lg:w-64 lg:static`}
        >
            <div className="p-4 flex items-center justify-center mb-8 lg:mb-0">
                <Typography variant="h5" color="black" className={`hidden lg:flex`}>
                    QckOrder
                </Typography>
            </div>
            <List>
                {navItems
                    .filter((item) => item.roles.includes(userRole || ''))
                    .map((item) => (
                        <NavLink to={item.path} key={item.title}>
                            {({ isActive }) => (
                                <ListItem
                                    className={`mb-2 rounded-lg ${
                                        isActive
                                            ? 'bg-blue-500 text-white'
                                            : 'text-gray-700 hover:bg-gray-200'
                                    } flex items-center px-4`}
                                >
                                    <ListItemPrefix>
                                        <item.icon className="w-5 h-5" />
                                    </ListItemPrefix>
                                    <span className="ml-2 whitespace-nowrap">{item.title}</span>
                                </ListItem>
                            )}
                        </NavLink>
                    ))}
            </List>

            {/*Mobile language selector (for original languageSlice)*/}
            <div className="lg:hidden p-4">
                <Tooltip content="Change Language">
                    <Select
                        value={currentLanguage}
                        onChange={handleLanguageChange}
                        containerProps={{
                            className: "min-w-[160px] border-2 border-gray-300 focus:border-none text-neutralDark"
                        }}
                        labelProps={{
                            className: "hidden"
                        }}
                    >
                        {PREVIEW_LANGUAGES.map((lang) => (
                            <Option key={lang.code} value={lang.code} className="flex items-center text-neutralDark w-full">
                                {lang.label} - {lang.originalLabel}
                            </Option>
                        ))}
                    </Select>
                </Tooltip>
            </div>

            {/* New language selector for i18n at the bottom of the sidebar */}
            <div className="mt-auto p-4">
                <Select
                    value={i18n.language}
                    onChange={handleI18nLanguageChange}
                    containerProps={{
                        className: "min-w-[160px] border-2 border-gray-300 focus:border-none text-neutralDark"
                    }}
                    labelProps={{
                        className: "hidden"
                    }}
                >
                    <Option value="en" className="text-neutralDark w-full">English</Option>
                    <Option value="pl" className="text-neutralDark w-full">Polski</Option>
                </Select>
            </div>
        </div>
    );
};

export default SidebarComponent;
