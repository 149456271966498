// File: src/pages/Orders/OrderDetail.tsx

import React, {useEffect, useState, useMemo} from 'react';
import {
    Typography,
    Button,
    Card,
    CardBody,
    Chip,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
    Alert
} from '@material-tailwind/react';
import {PencilIcon} from '@heroicons/react/24/outline';
import {Link, useNavigate, useParams} from 'react-router-dom';
import api from '../../services/api';
import {Order} from '../../types';
import ActionButtons from '../../components/Tables/ActionButtons';
import {useSelector, useDispatch} from 'react-redux';
import {RootState, AppDispatch} from '../../store';
import {setViewMode} from '../../store/slices/viewSlice';
import dayjs from "dayjs";
import relativeTime from 'dayjs/plugin/relativeTime';

import {
    statusBadgeColor,
    statusOptions,
    paymentStatusColor,
    paymentStatusOptions,
    paymentMethodBadgeColor,
    paymentMethodIcon,
    paymentMethodOptions
} from '../../utils';
import {t} from "i18next";
import PriceTag from "../../components/Common/Price.tsx";

dayjs.extend(relativeTime);

type FieldToUpdate = 'status' | 'paymentStatus' | 'paymentMethod';

const OrderDetail: React.FC = () => {
    const {id} = useParams<{ id: string }>();
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();
    const userRole = useSelector((state: RootState) => state.auth.user?.role?.name);

    const [order, setOrder] = useState<Order | null>(null);
    const [updateError, setUpdateError] = useState<string | null>(null);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [fieldToUpdate, setFieldToUpdate] = useState<FieldToUpdate | null>(null);
    const [selectedOrderId, setSelectedOrderId] = useState<string | null>(null);
    const [updating, setUpdating] = useState<boolean>(false);

    useEffect(() => {
        const fetchOrder = async () => {
            try {
                const response = await api.get(`/orders/${id}`);
                setOrder(response.data);
            } catch (error) {
                console.error('Error fetching order:', error);
            }
        };
        if (id) {
            fetchOrder();
        }
    }, [id]);

    useEffect(() => {
        dispatch(setViewMode('list'));
        const handleResize = () => {
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [dispatch]);

    const getOptions = () => {
        switch (fieldToUpdate) {
            case 'status':
                return statusOptions;
            case 'paymentStatus':
                return paymentStatusOptions;
            case 'paymentMethod':
                return paymentMethodOptions;
            default:
                return [];
        }
    };

    const getFieldLabel = () => {
        switch (fieldToUpdate) {
            case 'status':
                return 'Status';
            case 'paymentStatus':
                return 'Payment Status';
            case 'paymentMethod':
                return 'Payment Method';
            default:
                return '';
        }
    };

    const openModal = (field: FieldToUpdate, orderId: string) => {
        setFieldToUpdate(field);
        setSelectedOrderId(orderId);
        setIsModalOpen(true);
        setUpdateError(null);
        setSuccessMessage(null);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setFieldToUpdate(null);
        setSelectedOrderId(null);
        setUpdateError(null);
        setTimeout(() => {
            setSuccessMessage(null);
        }, 3000);
    };

    const handleUpdate = async (newValue: string) => {
        if (!selectedOrderId || !fieldToUpdate) return;

        setUpdating(true);
        setUpdateError(null);
        setSuccessMessage(null);

        let endpoint = '';
        let payload: any = {};

        switch (fieldToUpdate) {
            case 'status':
                endpoint = `/orders/${selectedOrderId}/update/status`;
                payload = {status: newValue};
                break;
            case 'paymentStatus':
                endpoint = `/orders/${selectedOrderId}/update/payment-status`;
                payload = {paymentStatus: newValue};
                break;
            case 'paymentMethod':
                endpoint = `/orders/${selectedOrderId}/update/payment-method`;
                payload = {paymentMethod: newValue};
                break;
            default:
                break;
        }

        try {
            await api.put(endpoint, payload);
            setOrder((prevOrder) =>
                prevOrder ? {...prevOrder, [fieldToUpdate]: newValue} : prevOrder
            );
            setSuccessMessage(`Successfully updated ${getFieldLabel()}.`);
            closeModal();
        } catch (err) {
            setUpdateError('Failed to update. Please try again.');
            console.error(err);
        } finally {
            setUpdating(false);
        }
    };

    const isAdmin = userRole === 'Admin';

    return (
        <div className="container mx-auto px-4 py-6">
            {/* Success and Error Alerts */}
            <div className="mb-4">
                {successMessage && (
                    <Alert color="green" className="mb-2">
                        {successMessage}
                    </Alert>
                )}
                {updateError && (
                    <Alert color="red" className="mb-2">
                        {updateError}
                    </Alert>
                )}
            </div>

            {/* Go to Restaurant Button */}
            {order && (
                <div className="mb-6 flex flex-col lg:flex-row gap-4">
                    {
                        isAdmin && (
                            <Button
                                onClick={() => navigate(`/restaurants/${order.restaurantId}`)}
                                className="flex items-center bg-secondary hover:bg-opacity-90"
                                aria-label="Go to Restaurant"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5 mr-2"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M15 19l-7-7 7-7"
                                    />
                                </svg>
                                {t('buttons.goToRestaurant')}
                            </Button>
                        )
                    }
                    <Button
                        onClick={() => navigate(`/orders`)}
                        className="flex items-center bg-secondary hover:bg-opacity-90"
                        aria-label="Go to orders"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5 mr-2"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M15 19l-7-7 7-7"
                            />
                        </svg>
                        {t('buttons.goToOrders')}
                    </Button>
                </div>
            )}

            {/* Order Information */}
            {order && (
                <Card className="mb-12">
                    <CardBody>
                        <div className="flex flex-col md:flex-row justify-end">
                            <div className="flex items-center space-x-2">
                                {userRole === 'Admin' && (
                                    <>
                                        <Button
                                            onClick={() => navigate(`/orders/edit/${order.id}`)}
                                            className="flex items-center mx-auto lg:mx-0 bg-primary hover:bg-opacity-90"
                                            aria-label={t('buttons.editOrder')}
                                        >
                                            <PencilIcon className="h-5 w-5 mr-2"/>
                                            {t('buttons.editOrder')}
                                        </Button>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="flex flex-col md:flex-row justify-evenly mt-6">
                            <div
                                className="mb-4 md:mb-0 flex flex-col w-full items-center lg:flex-row lg:justify-between">
                                <div className={`lg:w-1/3`}></div>
                                <div className="lg:w-1/3 text-lg text-center font-semibold">
                                    {t('common.order')} #{order.orderNumber}
                                </div>
                                <div className="lg:w-1/3 flex flex-col lg:space-x-4 text-right">
                                    <Typography variant="body2" className="text-gray-600">
                                        {t('common.created')} {dayjs(order.createdAt).fromNow()}
                                    </Typography>
                                    <Typography variant="body2" className="text-gray-600">
                                        {t('common.updated')} {dayjs(order.updatedAt).fromNow()}
                                    </Typography>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col lg:flex-row justify-between w-full mt-6 gap-6">
                            <div className="lg:w-1/2">
                                <Typography variant="h5" className="mb-2 lg:mb-6 lg:text-left hidden lg:flex">
                                    Customer Details
                                </Typography>
                                <div className="text-base space-y-2">
                                    <div className="flex items-center flex-col lg:flex-row mb-6">
                                        <span className="font-semibold min-w-[160px] lg:text-left">Customer ID:</span>
                                        <span className="text-left max-w-[240px] truncate flex-1">{order.customerId}</span>
                                    </div>
                                    <div className="flex items-center flex-col lg:flex-row">
                                        <span className="font-semibold min-w-[160px] lg:text-left">Customer Notes:</span>
                                        <span className="text-left flex-1">{order.customerNotes}</span>
                                    </div>
                                </div>
                            </div>
                            <div>
                            <Typography variant="h5" className="mb-2 lg:mb-6 lg:text-right hidden lg:block">
                                    {t('common.locationDetails')}
                                </Typography>
                                <div className="text-base space-y-2 text-center lg:text-right">
                                    <div>
                                        <span className="font-semibold">{t('common.restaurant')}:</span>{' '}
                                        <Link to={`/restaurants/${order.restaurantId}`}
                                              className="text-primary underline">
                                            {order.restaurantName}
                                        </Link>
                                    </div>
                                    <div>
                                        <span className="font-semibold">{t('common.location')}:</span>{' '}
                                        <Link to={`/locations/${order.locationId}`} className="text-primary underline">
                                            {order.locationName}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            className="flex flex-col lg:flex-row justify-between gap-12 mt-16 border-t-2 border-t-gray-200">
                            <div className="details w-full lg:w-1/3 border-r-gray-200 lg:border-r-2 lg:p-6">
                                <div className="mt-6">
                                    <Typography variant="h5" className="mb-2">
                                        Payment Details
                                    </Typography>
                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                        <Typography variant="body1">
                                            <span className="font-semibold">Payment Method:</span>{' '}
                                            <Chip
                                                value={order.paymentMethod.replace(/\b\w/g, l => l.toUpperCase())}
                                                color={paymentMethodBadgeColor(order.paymentMethod)}
                                                icon={paymentMethodIcon(order.paymentMethod)}
                                                className="text-sm font-semibold"
                                                onClick={() => openModal('paymentMethod', order.id)}
                                            />
                                        </Typography>
                                        <Typography variant="body1">
                                            <span className="font-semibold">Payment Status:</span>{' '}
                                            <Chip
                                                value={order.paymentStatus.replace(/\b\w/g, l => l.toUpperCase())}
                                                color={paymentStatusColor(order.paymentStatus)}
                                                className="text-sm font-semibold"
                                                onClick={() => openModal('paymentStatus', order.id)}
                                            />
                                        </Typography>
                                    </div>
                                </div>
                                <div className="mt-6">
                                    <Typography variant="h5" className="mb-2">
                                        Order Status
                                    </Typography>
                                    <Chip
                                        value={order.status.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
                                        color={statusBadgeColor(order.status)}
                                        className="text-sm font-semibold cursor-pointer"
                                        onClick={() => openModal('status', order.id)}
                                    />
                                </div>
                            </div>
                            <div className="order w-full lg:w-2/3 lg:p-6">
                                <div className="mt-6">
                                    <Typography variant="h5" className="mb-2">
                                        Ordered Items
                                    </Typography>
                                    <div className="overflow-x-auto">
                                        <table className="min-w-full divide-y divide-gray-200 overflow-auto">
                                            <thead className="bg-neutralLight">
                                            <tr>
                                                <th className="px-4 py-2 text-left text-xs font-medium text-neutralDark uppercase tracking-wider">
                                                    Item Name
                                                </th>
                                                <th className="px-4 py-2 text-left text-xs font-medium text-neutralDark uppercase tracking-wider">
                                                    Quantity
                                                </th>
                                                <th className="px-4 py-2 text-left text-xs font-medium text-neutralDark uppercase tracking-wider">
                                                    Price
                                                </th>
                                                <th className="hidden lg:block px-4 py-2 text-left text-xs font-medium text-neutralDark uppercase tracking-wider">
                                                    Total
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {order.orderItems.map((item) => (
                                                <tr key={item.id} className="bg-white">
                                                    <td className="px-4 py-2 text-sm text-neutralDark text-left">
                                                        {item.name}
                                                    </td>
                                                    <td className="px-4 py-2 whitespace-nowrap text-sm text-neutralDark">
                                                        {item.quantity}
                                                    </td>
                                                    <td className="px-4 py-2 whitespace-nowrap text-sm text-neutralDark">
                                                        <PriceTag item={
                                                            {
                                                                price: item.price,
                                                                restaurant: {
                                                                    currencySymbol: order.currencySymbol,
                                                                    currencySymbolPosition: order.currencySymbolPosition
                                                                }
                                                            } as any
                                                        } />
                                                    </td>
                                                    <td className="hidden lg:block px-4 py-2 whitespace-nowrap text-sm text-neutralDark">
                                                        <PriceTag item={
                                                            {
                                                                price: item.price * item.quantity,
                                                                restaurant: {
                                                                    currencySymbol: order.currencySymbol,
                                                                    currencySymbolPosition: order.currencySymbolPosition
                                                                }
                                                            } as any
                                                        } />
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                                <div className="mt-6 flex justify-end">
                                <span
                                    className="font-semibold mr-2">Total Amount:</span> <PriceTag item={
                                    {
                                        price: order.totalAmount,
                                        restaurant: {
                                            currencySymbol: order.currencySymbol,
                                            currencySymbolPosition: order.currencySymbolPosition
                                        }
                                    } as any} />
                                </div>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            )}

            {/* Update Modal */}
            <Dialog open={isModalOpen} handler={closeModal}>
                <DialogHeader>
                    Update {getFieldLabel()}
                </DialogHeader>
                <DialogBody divider>
                    <div className="flex flex-col space-y-4">
                        {getOptions().map((option) => (
                            <Button
                                key={option.value}
                                color={option.color}
                                onClick={() => handleUpdate(option.value)}
                                disabled={updating}
                            >
                                {option.label}
                            </Button>
                        ))}
                    </div>
                </DialogBody>
                <DialogFooter>
                    <Button variant="text" color="red" onClick={closeModal} className="mr-2">
                        <span>Cancel</span>
                    </Button>
                </DialogFooter>
            </Dialog>
        </div>
    );
}

export default OrderDetail;
