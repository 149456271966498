import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import {
    Card,
    CardBody,
    Typography,
    Button,
    Alert,
    Chip,
    Tabs,
    TabsHeader,
    Tab,
    IconButton,
    Tooltip
} from '@material-tailwind/react';
import {
    ChevronLeftIcon,
    PencilIcon,
    ClipboardDocumentListIcon,
    BuildingStorefrontIcon,
    ShoppingBagIcon,
    NoSymbolIcon,
    ClockIcon,
} from '@heroicons/react/24/outline';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import api from '../../services/api';
import { Menu, MenuItem, Translation } from '../../types';
import {PREVIEW_LANGUAGES} from "../../store/slices/languageSlice.ts";
import {current} from "@reduxjs/toolkit";
import {getTranslation} from "../../utils/translation.utils.tsx";

interface Category {
    id: string;
    name: string;
    order: number;
    menuItems: MenuItem[];
    translations: Translation[];
}

interface MenuWithDetails extends Menu {
    categories: Category[];
    restaurant: {
        id: string;
        name: string;
        translations: Translation[];
        logoUrl?: string;
    };
    createdAt: string;
    updatedAt: string;
}

const MenuDetail: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const currentLanguage = useSelector((state: RootState) => state.language.current);
    const userRole = useSelector((state: RootState) => state.auth.user?.role?.name);
    const isAdmin = userRole === 'Admin';

    const [menu, setMenu] = useState<MenuWithDetails | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedLanguage, setSelectedLanguage] = useState<string>(currentLanguage);

    useEffect(() => {
        setSelectedLanguage(currentLanguage);
    }, [currentLanguage]);

    useEffect(() => {
        const fetchMenu = async () => {
            setIsLoading(true);
            try {
                const [menuRes, categoriesRes] = await Promise.all([
                    api.get(`/menus/${id}`),
                    api.get(`/menus/${id}/categories`)
                ]);

                const menuData = menuRes.data;
                const categoriesData = categoriesRes.data;

                setMenu({
                    ...menuData,
                    categories: categoriesData.sort((a: Category, b: Category) => a.order - b.order)
                });
            } catch (err) {
                console.error('Error fetching menu:', err);
                setError('Failed to load menu details');
            } finally {
                setIsLoading(false);
            }
        };

        if (id) {
            fetchMenu();
        }
    }, [id]);

    // Get available languages from menu translations
    const availableLanguages = menu?.translations
        ? Array.from(new Set(menu.translations.map(t => t.language)))
        : [];

    if (isLoading) {
        return (
            <div className="flex justify-center items-center min-h-[400px]">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary"></div>
            </div>
        );
    }

    if (error || !menu) {
        return (
            <div className="container mx-auto px-4 py-8">
                <Alert color="red">{error || 'Menu not found'}</Alert>
            </div>
        );
    }

    return (
        <div className="container mx-auto px-4 py-8">
            {/* Header Section */}
            <div className="mb-8">
                <div className="flex flex-col lg:flex-row justify-between items-start gap-4 mb-6">
                    <div className="flex items-center gap-4">
                        <Button
                            variant="text"
                            className="flex items-center gap-2 text-neutralLight hover:text-neutralDark"
                            onClick={() => navigate('/menus')}
                        >
                            <ChevronLeftIcon className="h-4 w-4" />
                            Back to Menus
                        </Button>
                    </div>

                    {isAdmin && (
                        <Button
                            className="flex items-center gap-2 bg-primary"
                            onClick={() => navigate(`/menus/edit/${id}`)}
                        >
                            <PencilIcon className="h-4 w-4" />
                            Edit Menu
                        </Button>
                    )}
                </div>

                <Card className="overflow-hidden">
                    <CardBody className="p-6">
                        <div className="flex flex-col">
                            {/* Restaurant Info */}
                            <div className="flex flex-row gap-4 w-full justify-between">
                                <div className="restaurant flex justify-start items-start">
                                    {menu.restaurant && (
                                        <Link
                                            to={`/restaurants/${menu.restaurant.id}`}
                                            className="flex items-center gap-4 p-4 rounded-lg hover:bg-gray-50 transition-colors"
                                        >
                                            {menu.restaurant.logoUrl ? (
                                                <img
                                                    src={menu.restaurant.logoUrl}
                                                    alt={getTranslation(menu.restaurant.translations, 'name', menu.restaurant.name, currentLanguage)}
                                                    className="h-12 w-12 rounded-full object-cover"
                                                />
                                            ) : (
                                                <div className="h-12 w-12 rounded-full bg-gray-100 flex items-center justify-center">
                                                    <BuildingStorefrontIcon className="h-6 w-6 text-gray-400" />
                                                </div>
                                            )}
                                            <div>
                                                <Typography className="font-semibold text-primary">
                                                    {getTranslation(menu.restaurant.translations, 'name', menu.restaurant.name, currentLanguage)}
                                                </Typography>
                                            </div>
                                        </Link>
                                    )}
                                </div>

                                {/* Available Translations */}
                                <div className="details flex gap-4 flex-col">
                                    <div className="mt-4">
                                        <div className="flex flex-wrap gap-2">
                                            {availableLanguages.map((lang) => {
                                                const langInfo = PREVIEW_LANGUAGES.find(l => l.code === lang);
                                                return (
                                                    <Chip
                                                        key={lang}
                                                        value={langInfo?.label || lang.toUpperCase()}
                                                        variant="outlined"
                                                        color={selectedLanguage === lang ? "blue" : "blue-gray"}
                                                    />
                                                );
                                            })}
                                        </div>
                                    </div>

                                    {/* Menu Status */}
                                    <div className="flex flex-col gap-2">
                                        <Chip
                                            value={menu.isActive ? 'Active' : 'Inactive'}
                                            color={menu.isActive ? 'green' : 'blue-gray'}
                                            icon={menu.isActive ? <ClipboardDocumentListIcon className="h-4 w-4"/> :
                                                <NoSymbolIcon className="h-4 w-4"/>}
                                        />
                                    </div>

                                    {/* Timestamps */}
                                    <div className="text-sm text-gray-600 space-y-1">
                                        <div className="flex items-center gap-2">
                                            <ClockIcon className="h-4 w-4"/>
                                            <span>Created: {new Date(menu.createdAt).toLocaleDateString()}</span>
                                        </div>
                                        <div className="flex items-center gap-2">
                                            <ClockIcon className="h-4 w-4"/>
                                            <span>Updated: {new Date(menu.updatedAt).toLocaleDateString()}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Menu Details */}
                            <div className="lg:col-span-2">
                                <Typography variant="h3" className="text-2xl font-bold mb-3 text-left">
                                    {getTranslation(menu.translations, 'name', menu.name, currentLanguage)}
                                </Typography>
                                <Typography className="text-gray-600 mb-4 text-left">
                                    {getTranslation(menu.translations, 'description', menu.description || 'No description available', currentLanguage)}
                                </Typography>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </div>

            {/* Categories Section */}
            <div className="space-y-6">
                {menu.categories.map((category) => (
                    <Card key={category.id} className="overflow-hidden">
                        <CardBody>
                            <Typography variant="h5" className="mb-6 flex items-center gap-2">
                                {getTranslation(category.translations, 'name', category.name, currentLanguage)}
                            </Typography>
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                                {category.menuItems.map((item) => (
                                    <Card key={item.id} className="overflow-hidden">
                                        {item.imageUrl && (
                                            <div className="relative h-48">
                                                <img
                                                    src={item.imageUrl}
                                                    alt={getTranslation(item.translations, 'name', item.name, currentLanguage)}
                                                    className="w-full h-full object-cover"
                                                />
                                                {!item.isAvailable && (
                                                    <div
                                                        className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                                                        <Chip
                                                            value="Unavailable"
                                                            color="red"
                                                            className="bg-opacity-75"
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                        <CardBody>
                                            <div className="space-y-3">
                                                <div className="flex justify-between items-start">
                                                    <Typography variant="h6">
                                                        {getTranslation(item.translations, 'name', item.name, currentLanguage)}
                                                    </Typography>
                                                    <Typography className="font-bold text-lg">
                                                        ${Number(item.price).toFixed(2)}
                                                    </Typography>
                                                </div>
                                                <Typography className="text-gray-600 text-sm text-left">
                                                    {getTranslation(item.translations, 'description', item.description || 'No description available', currentLanguage)}
                                                </Typography>
                                                <div className="flex justify-between items-center pt-2">
                                                    <Chip
                                                        size="sm"
                                                        variant="ghost"
                                                        value={item.isAvailable ? 'Available' : 'Unavailable'}
                                                        color={item.isAvailable ? 'green' : 'red'}
                                                    />
                                                    {isAdmin && (
                                                        <Tooltip content="Edit Item">
                                                            <IconButton
                                                                variant="text"
                                                                className={`text-neutralLight hover:text-neutralDark`}
                                                                onClick={() => navigate(`/menu-items/${item.id}`)}
                                                            >
                                                                <PencilIcon className="h-4 w-4" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    )}
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                ))}
                            </div>
                            {category.menuItems.length === 0 && (
                                <div className="text-center py-8">
                                    <ShoppingBagIcon className="h-12 w-12 mx-auto text-gray-400 mb-2" />
                                    <Typography className="text-gray-500">
                                        No items in this category
                                    </Typography>
                                </div>
                            )}
                        </CardBody>
                    </Card>
                ))}
                {menu.categories.length === 0 && (
                    <Card>
                        <CardBody className="text-center py-12">
                            <ClipboardDocumentListIcon className="h-12 w-12 mx-auto text-gray-400 mb-2" />
                            <Typography className="text-gray-500">
                                No categories available
                            </Typography>
                        </CardBody>
                    </Card>
                )}
            </div>
        </div>
    );
};

export default MenuDetail;
