// File: src/components/Common/ViewModeToggle.tsx

import React from 'react';
import { IconButton, Tooltip } from '@material-tailwind/react';
import { TableCellsIcon, Squares2X2Icon } from '@heroicons/react/24/outline';
import { useDispatch, useSelector } from 'react-redux';
import {AppDispatch, RootState} from "../../store";
import {setViewMode} from "../../store/slices/viewSlice.ts";
import {t} from "i18next";
// import { RootState, AppDispatch } from '../../store/store.ts';
// import { setViewMode } from '../../store/viewSlice.ts';

const ViewModeToggle: React.FC = () => {
    const dispatch: AppDispatch = useDispatch();
    const viewMode = useSelector((state: RootState) => state.view.mode);

    const handleViewModeChange = (mode: 'card' | 'list') => {
        dispatch(setViewMode(mode));
    };

    return (
        <>
            <Tooltip placement="bottom" content={t('common.toggleViewMode')}>
                <IconButton
                    color="lightBlue"
                    size="regular"
                    className={`hidden lg:block`}
                    onClick={() => handleViewModeChange(viewMode === 'list' ? 'card' : 'list')}
                >
                    {viewMode === 'list' ? (
                        <Squares2X2Icon className="h-6 w-6"/>
                    ) : (
                        <TableCellsIcon className="h-6 w-6"/>
                    )}
                </IconButton>
            </Tooltip>
        </>
    );
};

export default ViewModeToggle;
