import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Alert} from '@material-tailwind/react';
import {useSelector} from 'react-redux';
import {RootState} from '../../store';
import api from '../../services/api';
import {Restaurant, Location, Order, Menu, MenuItem} from '../../types';
import RestaurantHeader from './components/RestaurantHeader';
import RestaurantStats from './components/RestaurantStats';
import LocationsTable from '../../components/Tables/LocationsTable.tsx';
import MenusTable from '../../components/Tables/MenusTable.tsx';
import MenuItemsTable from '../../components/Tables/MenuItemsTable.tsx';
import OrdersTable from '../../components/Tables/OrdersTable.tsx';
import OrdersCards from "../../components/Cards/OrdersCards.tsx";
import MenuItemsCards from "../../components/Cards/MenuItemsCards.tsx";
import MenusCards from "../../components/Cards/MenusCards.tsx";
import LocationsCards from "../../components/Cards/LocationsCards.tsx";

const RestaurantDetail: React.FC = () => {
    const {id} = useParams<{ id: string }>();
    const navigate = useNavigate();
    const userRole = useSelector((state: RootState) => state.auth.user?.role?.name);
    const currentLanguage = useSelector((state: RootState) => state.language.current);
    const isAdmin = userRole === 'Admin';

    // State
    const [restaurant, setRestaurant] = useState<Restaurant | null>(null);
    const [locations, setLocations] = useState<Location[]>([]);
    const [orders, setOrders] = useState<Order[]>([]);
    const [menus, setMenus] = useState<Menu[]>([]);
    const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);

    // set is small when the width bellow 1024 and set fasel when the width above 1024
    const [isSmall, setIsSmall] = useState(window.innerWidth < 1024);

    useEffect(() => {
        window.addEventListener('resize', () => {
            setIsSmall(window.innerWidth < 1024);
        });
    }, []);

    // Fetch all data
    useEffect(() => {
        const fetchRestaurantData = async () => {
            setIsLoading(true);
            setError(null);

            try {
                const [
                    restaurantRes,
                    locationsRes,
                    ordersRes,
                    menusRes,
                    menuItemsRes
                ] = await Promise.all([
                    api.get(`/restaurants/${id}`),
                    api.get(`/restaurants/${id}/locations`),
                    api.get(`/restaurants/${id}/orders`),
                    api.get(`/restaurants/${id}/menus`),
                    api.get(`/restaurants/${id}/menu-items`)
                ]);

                setRestaurant(restaurantRes.data);
                setLocations(locationsRes.data);
                setOrders(ordersRes.data);
                setMenus(menusRes.data);
                setMenuItems(menuItemsRes.data);
            } catch (err) {
                console.error('Error fetching restaurant data:', err);
                setError('Failed to load restaurant data. Please try again later.');
            } finally {
                setIsLoading(false);
            }
        };

        if (id) {
            fetchRestaurantData();
        }
    }, [id]);

    // Order update handler
    const handleFieldUpdate = async (id: string, field: string, value: string) => {
        let endpoint = '';
        let payload: Record<string, string> = {};

        switch (field) {
            case 'status':
                endpoint = `/orders/${id}/update/status`;
                payload = { status: value };
                break;
            case 'paymentStatus':
                endpoint = `/orders/${id}/update/payment-status`;
                payload = { paymentStatus: value };
                break;
            case 'paymentMethod':
                endpoint = `/orders/${id}/update/payment-method`;
                payload = { paymentMethod: value };
                break;
            default:
                return;
        }

        try {
            await api.put(endpoint, payload);
            setOrders((prevOrders) =>
                prevOrders.map((order) =>
                    order.id === id ? { ...order, [field]: value } : order
                )
            );
            setSuccessMessage(`Successfully updated ${field.replace(/([A-Z])/g, ' $1').toLowerCase()}.`);
            setTimeout(() => setSuccessMessage(null), 3000);
        } catch (error) {
            setUpdateError('Failed to update. Please try again.');
            setTimeout(() => setUpdateError(null), 3000);
        }
    };

    if (isLoading) {
        return (
            <div className="container mx-auto px-4 py-8">
                <div className="flex justify-center items-center min-h-[400px]">
                    <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary"></div>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="container mx-auto px-4 py-8">
                <Alert color="red" className="mb-4">
                    {error}
                </Alert>
            </div>
        );
    }

    if (!restaurant) {
        return (
            <div className="container mx-auto px-4 py-8">
                <Alert color="amber" className="mb-4">
                    Restaurant not found
                </Alert>
            </div>
        );
    }

    // Calculate statistics
    const totalRevenue = orders.reduce((sum, order) => sum + order.totalAmount, 0);
    const activeMenusCount = menus.filter(menu => menu.isActive).length;

    return (
        <div className="container mx-auto px-4 py-8">
            {/* Success Messages */}
            {successMessage && (
                <Alert color="green" className="mb-4">
                    {successMessage}
                </Alert>
            )}

            {/* Restaurant Header */}
            <RestaurantHeader
                restaurant={restaurant}
                currentLanguage={currentLanguage}
            />

            {/*/!* Statistics Dashboard *!/*/}
            <RestaurantStats
                restaurant={restaurant}
                totalLocations={locations.length}
                totalOrders={orders.length}
                totalRevenue={totalRevenue}
                activeMenus={activeMenusCount}
            />

            {/*/!* Locations Section *!/*/}
            {isSmall ?
                <LocationsCards locations={locations} isAdmin={isAdmin} setLocations={setLocations} currentLanguage={currentLanguage}/>
                : <LocationsTable
                    locations={locations}
                    isAdmin={isAdmin}
                    restaurantId={restaurant.id}
                    setLocations={setLocations}
                    currentLanguage={currentLanguage}
                    onAddLocation={() => navigate(`/locations/create`)}
                />
            }

            {/* Menus Section */}
            {isSmall ?
                <MenusCards menus={menus} isAdmin={isAdmin} setMenus={setMenus}/>
                : <MenusTable
                    menus={menus}
                    isAdmin={isAdmin}
                    restaurantId={restaurant.id}
                    setMenus={setMenus}
                    onAddMenu={() => navigate(`/menus/create`)}
                    />
            }


            {isSmall ?
                <MenuItemsCards menuItems={menuItems} isAdmin={isAdmin} restaurant={restaurant} setMenuItems={setMenuItems}/>
                : <MenuItemsTable
                    menuItems={menuItems}
                    isAdmin={isAdmin}
                    restaurant={restaurant}
                    restaurantId={restaurant.id}
                    setMenuItems={setMenuItems}
                    onAddMenuItem={() => navigate(`/menu-items/create`)}
                />
            }

            {isSmall ?
                <OrdersCards orders={orders} isAdmin={isAdmin} onOrderUpdate={handleFieldUpdate}/>
                : <OrdersTable
                    restaurant={restaurant}
                    orders={orders}
                    isAdmin={isAdmin}
                    setOrders={setOrders}
                    onFieldUpdate={handleFieldUpdate}/>
            }
        </div>
    );
};

export default RestaurantDetail;
