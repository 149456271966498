export const PREVIEW_LANGUAGES = [
    { code: 'pl', label: 'Polish', originalLabel: 'Polski' },
    { code: 'en', label: 'English', originalLabel: 'English' },
    { code: 'it', label: 'Italian', originalLabel: 'Italiano' },
    { code: 'es', label: 'Spanish', originalLabel: 'Español' },
    { code: 'jp', label: 'Japanese', originalLabel: '日本語' },
    // Add more languages as needed
];

export const I18N_SUPPORTED_LANGUAGES = [
    { code: 'en', label: 'English' },
    { code: 'pl', label: 'Polski' },
];
