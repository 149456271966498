import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import {
    Card,
    CardBody,
    Button,
    Typography,
    Alert,
    Tabs,
    TabsHeader,
    Tab,
    Chip,
    IconButton,
    Tooltip
} from '@material-tailwind/react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { MenuItem, Translation } from "../../types";
import api from "../../services/api";
import {
    ArrowLeftIcon,
    PencilIcon,
    ClockIcon,
    BuildingStorefrontIcon,
    ListBulletIcon
} from '@heroicons/react/24/solid';
import {PREVIEW_LANGUAGES} from "../../store/slices/languageSlice.ts";
import {GlobeAltIcon} from "@heroicons/react/24/outline";
import {getTranslation} from "../../utils/translation.utils.tsx";
import Price from "../../components/Common/Price.tsx";
import PriceTag from "../../components/Common/Price.tsx";

interface MenuItemDetailData extends MenuItem {
    createdAt: string;
    updatedAt: string;
    restaurant: {
        id: string;
        name: string;
        translations: Translation[];
    };
    menu?: {
        id: string;
        name: string;
        translations: Translation[];
    };
}

const MenuItemDetail: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const [menuItem, setMenuItem] = useState<MenuItemDetailData | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedLanguage, setSelectedLanguage] = useState<string>('');

    const currentLanguage = useSelector((state: RootState) => state.language.current);
    const userRole = useSelector((state: RootState) => state.auth.user?.role?.name);

    useEffect(() => {
        setSelectedLanguage(currentLanguage);
    }, [currentLanguage]);

    useEffect(() => {
        const fetchMenuItem = async () => {
            setIsLoading(true);
            try {
                const response = await api.get(`/menu-items/${id}`);
                setMenuItem(response.data);
            } catch (err: any) {
                setError(err.response?.data?.message || 'Failed to fetch menu item');
            } finally {
                setIsLoading(false);
            }
        };

        if (id) fetchMenuItem();
    }, [id]);

    if (error) {
        return (
            <div className="flex items-center justify-center min-h-[400px]">
                <Alert color="red" className="max-w-md">
                    {error}
                </Alert>
            </div>
        );
    }

    if (isLoading) {
        return (
            <div className="flex justify-center items-center min-h-[400px]">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary"></div>
            </div>
        );
    }

    const apiUrl = import.meta.env.VITE_API_URL;

    // Get available languages from item translations
    const availableLanguages = menuItem?.translations
        ? Array.from(new Set(menuItem.translations.map(t => t.language)))
        : [];

    return (
        <div className="container mx-auto px-4 py-6">
            {menuItem && (
                <Card>
                    <CardBody>
                        <div className="flex flex-col lg:flex-row justify-between mb-6">
                            <div className="flex flex-col gap-4">
                                <div className="flex items-center gap-4">
                                    <Button
                                        variant="text"
                                        className="flex items-center gap-2 text-neutralLight"
                                        onClick={() => navigate(`/menu-items`)}
                                    >
                                        <ArrowLeftIcon className="h-4 w-4" />
                                        Back
                                    </Button>
                                </div>
                                <Typography variant="h4" color="blue-gray">
                                    {getTranslation(menuItem.translations, 'name', menuItem.name, currentLanguage)}
                                </Typography>
                            </div>
                            <div className="flex flex-col gap-4 mt-4 lg:mt-0">
                                <div className="flex justify-cente items-center lg:justify-end gap-4 align-middle">
                                    <GlobeAltIcon className="h-5 w-5 text-blue-800"/>

                                    <div className="flex flex-wrap gap-2">
                                        {availableLanguages.map((lang) => {
                                            const langInfo = PREVIEW_LANGUAGES.find(l => l.code === lang);
                                            return (
                                                <Chip
                                                    key={lang}
                                                    value={langInfo?.label || lang.toUpperCase()}
                                                    variant="outlined"
                                                    color={selectedLanguage === lang ? "blue" : "blue-gray"}
                                                />
                                            );
                                        })}
                                    </div>
                                </div>


                                {userRole === 'Admin' && (
                                    <div className="flex justify-end gap-2">
                                        <Button
                                            onClick={() => navigate(`/menu-items/edit/${menuItem.id}`)}
                                            className="bg-primary hover:bg-opacity-90 flex items-center gap-2"
                                        >
                                            <PencilIcon className="h-4 w-4"/>
                                            Edit Item
                                        </Button>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="mt-6 flex flex-col lg:flex-row gap-8">
                            <div className="lg:flex lg:gap-8 w-full">
                                {/* Image Section */}
                                <div className="lg:w-1/3 flex flex-col gap-4 mb-6 lg:mb-0">
                                    {menuItem.imageUrl ? (
                                        <img
                                            src={`${menuItem.imageUrl}`}
                                            alt={getTranslation(menuItem.translations, 'name', menuItem.name, currentLanguage)}
                                            className="rounded-lg shadow-lg max-h-64 w-full object-cover"
                                        />
                                    ) : (
                                        <div
                                            className="w-full h-64 bg-gray-200 flex items-center justify-center rounded-lg shadow-lg">
                                            <Typography variant="body2" className="text-gray-500">
                                                No Image Available
                                            </Typography>
                                        </div>
                                    )}

                                    {/* Restaurant and Menu Links */}
                                    {menuItem.restaurant && (
                                        <Link
                                            to={`/restaurants/${menuItem.restaurant.id}`}
                                            className="flex items-center gap-2 text-primary hover:text-primary-dark p-2 rounded-lg hover:bg-gray-100"
                                        >
                                            <BuildingStorefrontIcon className="h-5 w-5" />
                                            <Typography>
                                                {getTranslation(menuItem.restaurant.translations, 'name', menuItem.restaurant.name, currentLanguage)}
                                            </Typography>
                                        </Link>
                                    )}

                                    {menuItem.menu && (
                                        <Link
                                            to={`/menus/${menuItem.menu.id}`}
                                            className="flex items-center gap-2 text-primary hover:text-primary-dark p-2 rounded-lg hover:bg-gray-100"
                                        >
                                            <ListBulletIcon className="h-5 w-5" />
                                            <Typography>
                                                {getTranslation(menuItem.menu.translations, 'name', menuItem.menu.name, currentLanguage)}
                                            </Typography>
                                        </Link>
                                    )}
                                </div>

                                {/* Details Section */}
                                <div className="lg:w-2/3 bg-white p-6 rounded-lg shadow flex flex-col justify-between">
                                    {/* Status and Timestamps */}
                                    <div className="flex flex-wrap items-center justify-between gap-4 mb-6">
                                        <div className="flex items-center gap-2">
                                            <Chip
                                                value={menuItem.isAvailable ? 'Available' : 'Unavailable'}
                                                color={menuItem.isAvailable ? 'green' : 'red'}
                                            />
                                        </div>
                                        <div className="flex flex-col items-center gap-4 text-sm text-gray-600">
                                            <div className="flex items-center gap-1">
                                                <ClockIcon className="h-4 w-4" />
                                                <span>
                                                    Created: {new Date(menuItem.createdAt).toLocaleDateString()}
                                                </span>
                                            </div>
                                            <div className="flex items-center gap-1">
                                                <ClockIcon className="h-4 w-4" />
                                                <span>
                                                    Updated: {new Date(menuItem.updatedAt).toLocaleDateString()}
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Description Section */}
                                    <div className="mb-6 text-left flex-grow">
                                        <Typography variant="h5" className="mb-2">
                                            Description
                                        </Typography>
                                        <Typography variant="body1" className="text-gray-700">
                                            {getTranslation(menuItem.translations, 'description', menuItem.description || 'No description provided.', currentLanguage)}
                                        </Typography>
                                    </div>

                                    {/* Price Section */}
                                    <div className="flex items-center gap-4 self-start mt-4">
                                        <Typography variant="h6">Price:</Typography>
                                        <Typography variant="h5" color="blue-gray" className="font-bold">
                                            <PriceTag item={menuItem}/>

                                        </Typography>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            )}
        </div>
    );
};

export default MenuItemDetail;
