// src/pages/Locations/components/StatsTimeRange.tsx

import React from 'react';
import { Tabs, TabsHeader, Tab } from '@material-tailwind/react';
import { useIsMobile } from "../../../hooks/useIsMobile";
import Select from "react-select";

type TimeRange = 'today' | 'yesterday' | 'last7days' | 'last30days' | 'all';

interface Props {
    timeRange: TimeRange;
    onChange: (value: TimeRange) => void;
}

const StatsTimeRange: React.FC<Props> = ({ timeRange, onChange }) => {
    const timeRanges: { value: TimeRange; label: string }[] = [
        { value: 'today', label: 'Today' },
        { value: 'yesterday', label: 'Yesterday' },
        { value: 'last7days', label: 'Last 7 Days' },
        { value: 'last30days', label: 'Last 30 Days' },
        { value: 'all', label: 'All Time' },
    ];

    const isMobile = useIsMobile();

    return (
        <div className="mb-6">
            {isMobile ? (
                <Select
                    value={timeRanges.find((range) => range.value === timeRange)}
                    onChange={(option) => onChange(option?.value as TimeRange)}
                    options={timeRanges}
                    className="w-full"
                    styles={{
                        control: (provided) => ({
                            ...provided,
                            padding: '2px',
                            borderColor: '#d1d5db',
                            boxShadow: 'none',
                        }),
                        option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isSelected ? '#e5e7eb' : '#fff',
                            color: '#374151',
                            '&:hover': {
                                backgroundColor: '#f3f4f6',
                            },
                        }),
                    }}
                />
            ) : (
                <Tabs value={timeRange}>
                    <TabsHeader className={`flex flex-col gap-4 lg:flex-row`}>
                        {timeRanges.map(({ value, label }) => (
                            <Tab
                                key={value}
                                value={value}
                                onClick={() => onChange(value)}
                                className={`${
                                    timeRange === value
                                        ? 'text-neutralDark'
                                        : 'text-gray-700'
                                } transition-colors`}
                            >
                                {label}
                            </Tab>
                        ))}
                    </TabsHeader>
                </Tabs>
            )}
        </div>
    );
};

export default StatsTimeRange;
