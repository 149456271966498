import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../../services/api';
import { MenuItem, RestaurantOption, Translation } from '../types';

export const useMenuItemForm = (itemId?: string) => {
    const navigate = useNavigate();

    const [imageFile, setImageFile] = useState<File | null>(null);
    const [formData, setFormData] = useState<MenuItem>({
        restaurantId: '',
        isAvailable: true,
        translations: [],
        price: '0.00',
        imageUrl: '',
        categoryIds: [],
    });
    const [restaurants, setRestaurants] = useState<RestaurantOption[]>([]);
    const [supportedLanguages, setSupportedLanguages] = useState<string[]>(['en']);
    const [error, setError] = useState<string>('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                // If editing, fetch menu item details
                if (itemId) {
                    const menuItemResponse = await api.get(`/menu-items/${itemId}`);
                    const menuItemData = menuItemResponse.data;

                    // Set form data
                    setFormData({
                        id: menuItemData.id,
                        restaurantId: menuItemData.restaurantId,
                        isAvailable: menuItemData.isAvailable,
                        translations: menuItemData.translations,
                        price: menuItemData.price,
                        imageUrl: menuItemData.imageUrl || '',
                        categoryIds: menuItemData.categories.map((cat: any) => cat.id),
                    });

                    // Fetch restaurant details
                    const restaurantResponse = await api.get(`/restaurants/${menuItemData.restaurantId}`);
                    const restaurantData = restaurantResponse.data;

                    setSupportedLanguages(restaurantData.supportedLanguages || ['en']);
                }

                // Fetch all restaurants
                const response = await api.get('/restaurants');
                const data = Array.isArray(response.data) ? response.data : [response.data];

                const formattedRestaurants = data.map((r: any) => ({
                    value: r.id,
                    label: r.name,
                    supportedLanguages: r.supportedLanguages,
                }));

                setRestaurants(formattedRestaurants);
            } catch (error: any) {
                console.error('Error fetching data:', error);
                setError('Failed to load data.');
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [itemId]);

    const handleTranslationChange = (language: string, field: 'name' | 'description', value: string) => {
        setFormData(prev => {
            const existingTranslationIndex = prev.translations.findIndex(t => t.language === language);

            if (existingTranslationIndex !== -1) {
                // Update existing translation
                const updatedTranslations = [...prev.translations];
                updatedTranslations[existingTranslationIndex] = {
                    ...updatedTranslations[existingTranslationIndex],
                    [field]: value
                };
                return { ...prev, translations: updatedTranslations };
            } else {
                // Add new translation
                const newTranslation: Translation = {
                    language,
                    name: field === 'name' ? value : '',
                    description: field === 'description' ? value : ''
                };
                return { ...prev, translations: [...prev.translations, newTranslation] };
            }
        });
    };

    const handleRestaurantSelect = (selected: any) => {
        const restaurant = restaurants.find(r => r.value === selected?.value);
        if (restaurant) {
            setSupportedLanguages(restaurant.supportedLanguages || ['en']);
            setFormData(prev => ({
                ...prev,
                restaurantId: restaurant.value,
                translations: restaurant.supportedLanguages.map(lang => {
                    // Preserve existing translations if they exist
                    const existingTranslation = prev.translations.find(t => t.language === lang);
                    return existingTranslation || {
                        language: lang,
                        name: '',
                        description: '',
                    };
                }),
            }));
        }
    };

    const handleToggle = () => {
        setFormData(prev => ({
            ...prev,
            isAvailable: !prev.isAvailable,
        }));
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            let savedMenuItem;
            if (itemId) {
                const response = await api.put(`/menu-items/${itemId}`, formData);
                savedMenuItem = response.data;
            } else {
                const response = await api.post('/menu-items', formData);
                savedMenuItem = response.data;
            }

            // Handle image upload if there's a new image
            if (imageFile) {
                const formData = new FormData();
                formData.append('file', imageFile);
                await api.post(
                    `/menu-items/${savedMenuItem.id}/upload-image`,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                );
            }

            navigate('/menu-items');
        } catch (err: any) {
            setError(err.response?.data?.message || 'Failed to save menu item');
        } finally {
            setIsLoading(false);
        }
    };


    return {
        formData,
        setFormData,
        restaurants,
        supportedLanguages,
        error,
        setError,
        isLoading,
        setIsLoading,
        handleTranslationChange,
        handleRestaurantSelect,
        handleToggle,
        handleSubmit,
        imageFile,
        setImageFile,
    };
};
