// File: src/store/viewSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ViewMode = 'list' | 'card';

interface ViewState {
    mode: ViewMode;
}

const getInitialViewMode = (): ViewMode => {
    const storedViewMode = localStorage.getItem('viewMode');
    if (storedViewMode === 'list' || storedViewMode === 'card') {
        return storedViewMode;
    }
    return 'list';
};

const initialState: ViewState = {
    mode: getInitialViewMode(),
};

const viewSlice = createSlice({
    name: 'view',
    initialState,
    reducers: {
        setViewMode(state, action: PayloadAction<ViewMode>) {
            state.mode = action.payload;
            localStorage.setItem('viewMode', action.payload);
        },
        toggleViewMode(state) {
            state.mode = state.mode === 'card' ? 'list' : 'card';
            localStorage.setItem('viewMode', state.mode);
        },
        enforceCardView(state) {
            if (state.mode !== 'card') {
                state.mode = 'card';
                localStorage.setItem('viewMode', 'card');
            }
        },
    },
});

export const { setViewMode, toggleViewMode, enforceCardView } = viewSlice.actions;

export default viewSlice.reducer;
