// File: src/pages/Users/UserDetail.tsx
import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Typography, Button } from '@material-tailwind/react'
import api from '../../services/api.ts'

interface User {
    id: string
    email: string
    firstName: string
    lastName: string
    role: {
        name: string
    }
    // Add other fields as necessary
}

const UserDetail: React.FC = () => {
    const { id } = useParams<{ id: string }>()
    const [user, setUser] = useState<User | null>(null)
    const navigate = useNavigate()

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await api.get(`/users/${id}`)
                setUser(response.data)
            } catch (error) {
                console.error('Error fetching user:', error)
            }
        }

        if (id) {
            fetchUser()
        }
    }, [id])

    if (!user) {
        return <p>Loading...</p>
    }

    return (
        <div>
            <Typography variant="h4" color="blue-gray" className="mb-4">
                {user.firstName} {user.lastName}
            </Typography>
            <Typography variant="body1" className="mb-2">
                Email: {user.email}
            </Typography>
            <Typography variant="body1" className="mb-4">
                Role: {user.role.name}
            </Typography>
            <Button
                color="yellow"
                onClick={() => navigate(`/users/edit/${user.id}`)}
            >
                Edit
            </Button>
            <Button
                color="red"
                onClick={() => {
                    // Implement delete functionality
                }}
                className="ml-2"
            >
                Delete
            </Button>
        </div>
    )
}

export default UserDetail
