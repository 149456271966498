// File: src/components/Tables/ActionButtons.tsx

import React, { useState } from 'react';
import { IconButton, Tooltip } from '@material-tailwind/react';
import { EyeIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import api from '../../services/api';
import ConfirmDelete from '../Common/ConfirmDelete';
import {t} from "i18next";

interface ActionButtonsProps {
    id: string;
    name: string;
    type: 'restaurant' | 'location' | 'menu' | 'user' | 'order' | 'menu-item' | 'category';
    setItems: React.Dispatch<React.SetStateAction<any[]>>;
    disableView?: boolean;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({ id, name, type, setItems, disableView }) => {
    const navigate = useNavigate();
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);

    // Determine if Edit and Delete buttons should be displayed
    const canView = type !== 'menu';
    const canEdit = type !== 'order';
    const canDelete = type !== 'order';

    const handleDelete = async () => {
        alert(`Delete ${type} with ID ${id}`);
        try {
            await api.delete(`/${type}s/${id}`);
            setItems((prev) => prev.filter((item) => item.id !== id));
            setIsDeleteOpen(false);
        } catch (error) {
            console.error(`Error deleting ${type}:`, error);
            // Optionally, you can add error handling UI here
        }
    };

    return (
        <div className="flex justify-center space-x-2">
            {/* View Button - Always Visible */}
            {canView && (
                <Tooltip content={t('common.viewDetails')}>
                    <IconButton
                        variant="filled"
                        color="blue"
                        onClick={() => navigate(`/${type}s/${id}`)}
                        aria-label={`View details of ${name}`}
                        disabled={disableView}
                    >
                        <EyeIcon className="h-5 w-5" />
                    </IconButton>
                </Tooltip>
            )}

            {/* Edit Button - Visible Only if Can Edit */}
            {canEdit && (
                <Tooltip content={t('common.edit')}>
                    <IconButton
                        variant="filled"
                        color="green"
                        onClick={() => navigate(`/${type}s/edit/${id}`)}
                        aria-label={`Edit ${name}`}
                    >
                        <PencilIcon className="h-5 w-5" />
                    </IconButton>
                </Tooltip>
            )}

            {/* Delete Button - Visible Only if Can Delete */}
            {canDelete && (
                <>
                    <Tooltip content={t('common.delete')}>
                        <IconButton
                            variant="filled"
                            color="red"
                            onClick={() => setIsDeleteOpen(true)}
                            aria-label={`Delete ${name}`}
                        >
                            <TrashIcon className="h-5 w-5" />
                        </IconButton>
                    </Tooltip>

                    {/* Confirm Delete Modal */}
                    <ConfirmDelete
                        isOpen={isDeleteOpen}
                        onClose={() => setIsDeleteOpen(false)}
                        onConfirm={handleDelete}
                        title={`Delete ${type.charAt(0).toUpperCase() + type.slice(1)}`}
                        // message={`Are you sure you want to delete "${name}"? This action cannot be undone.`}
                        message={t('common.deleteConfirmation', {name})}
                    />
                </>
            )}
        </div>
    );
};

export default ActionButtons;
