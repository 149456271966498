import React from 'react';
import {Card, CardBody, Typography} from '@material-tailwind/react';
import {
    BuildingStorefrontIcon,
    DocumentTextIcon,
    ShoppingBagIcon,
    CurrencyDollarIcon,
} from '@heroicons/react/24/outline';
import {Restaurant} from "../../../types";
import PriceTag from "../../../components/Common/Price.tsx";

interface Props {
    restaurant: Restaurant;
    totalLocations: number;
    totalOrders: number;
    totalRevenue: number;
    activeMenus: number;
}

const RestaurantStats: React.FC<Props> = ({
                                              restaurant,
                                              totalLocations,
                                              totalOrders,
                                              totalRevenue,
                                              activeMenus,
                                          }) => {
    const stats = [
        {
            title: 'Total Locations',
            value: totalLocations,
            icon: BuildingStorefrontIcon,
            color: 'text-blue-500',
            bgColor: 'bg-blue-50',
        },
        {
            title: 'Active Menus',
            value: activeMenus,
            icon: DocumentTextIcon,
            color: 'text-green-500',
            bgColor: 'bg-green-50',
        },
        {
            title: 'Total Orders',
            value: totalOrders,
            icon: ShoppingBagIcon,
            color: 'text-purple-500',
            bgColor: 'bg-purple-50',
        },
        {
            title: 'Total Revenue',
            value: <PriceTag item={{
                price: `${parseFloat(totalRevenue.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                })).toFixed(2)}`,
                restaurant: restaurant as any,
            }} />,
            icon: CurrencyDollarIcon,
            color: 'text-amber-500',
            bgColor: 'bg-amber-50',
            type: 'price'
        },
    ];

    return (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
            {stats.map((stat) => (
                <Card key={stat.title} className="bg-white">
                    <CardBody className="p-4">
                        <div className="flex items-center gap-4">
                            <div className={`p-3 rounded-xl ${stat.bgColor}`}>
                                <stat.icon className={`h-6 w-6 ${stat.color}`}/>
                            </div>
                            <div className={`flex justify-between w-full items-center`}>
                                <Typography className="text-gray-600 text-sm">
                                    {stat.title}
                                </Typography>
                                <Typography className="font-bold text-xl">
                                    {stat.value}
                                </Typography>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            ))}
        </div>
    );
};

export default RestaurantStats;
