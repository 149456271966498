// src/pages/Profile/UserProfile.tsx

import React, { useState } from 'react';
import { Typography, Input, Button, Card, CardBody, CardHeader } from '@material-tailwind/react';
import api from '../../services/api';

const UserProfile: React.FC = () => {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const handlePasswordChange = async (e: React.FormEvent) => {
        e.preventDefault();
        setError('');
        setSuccess('');

        if (newPassword !== confirmPassword) {
            setError('New password and confirm password do not match');
            return;
        }

        try {
            await api.put('/users/me/password', { currentPassword, newPassword });
            setSuccess('Password updated successfully');
            setCurrentPassword('');
            setNewPassword('');
            setConfirmPassword('');
        } catch (err: any) {
            setError(err.response?.data?.message || 'Password update failed');
        }
    };

    return (
        <div className="container mx-auto px-4 py-6">
            <Card className="max-w-3xl mx-auto">
                <CardHeader className="bg-primary text-white p-4">
                    <Typography variant="h5" color="white">
                        Edit Profile
                    </Typography>
                </CardHeader>
                <CardBody>
                    {error && <p className="text-red-500 mb-4">{error}</p>}
                    {success && <p className="text-green-500 mb-4">{success}</p>}
                    <form onSubmit={handlePasswordChange} className="space-y-6">
                        <Input
                            label="Current Password"
                            type="password"
                            value={currentPassword}
                            onChange={(e) => setCurrentPassword(e.target.value)}
                            required
                            className="mb-4"
                        />
                        <Input
                            label="New Password"
                            type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            required
                            className="mb-4"
                        />
                        <Input
                            label="Confirm New Password"
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                            className="mb-4"
                        />
                        <Button type="submit" fullWidth className="bg-primary hover:bg-opacity-90">
                            Update Password
                        </Button>
                    </form>
                </CardBody>
            </Card>
        </div>
    );
};

export default UserProfile;
