import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

const supportedLanguages = ['en', 'pl']; // Define supported languages
const fallbackLanguage = 'en'; // Define the fallback language

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: fallbackLanguage,
        debug: import.meta.env.NODE_ENV === 'development',
        backend: {
            loadPath: '/locales/{{lng}}.json',
        },
        detection: {
            order: ['localStorage', 'cookie', 'querystring', 'navigator', 'htmlTag'],
            caches: ['localStorage', 'cookie'], // Persist detected language in localStorage or cookies
            lookupLocalStorage: 'language',
        },
        interpolation: {
            escapeValue: false, // React handles escaping
        },
        supportedLngs: supportedLanguages,
        nonExplicitSupportedLngs: true,
        compatibilityJSON: 'v4',
        lng: getInitialLanguage(), // Dynamically set the initial language
    });

function getInitialLanguage() {
    // Check for saved language in localStorage
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage && supportedLanguages.includes(savedLanguage)) {
        return savedLanguage;
    }

    // Detect language from browser
    const detectedLanguage = navigator.language || navigator.userLanguage ;
    const matchedLanguage = supportedLanguages.find((lang) =>
        detectedLanguage.toLowerCase().startsWith(lang)
    );

    // Use the matched language or default to English
    const initialLanguage = matchedLanguage || fallbackLanguage;

    // Save detected language in localStorage for future visits
    localStorage.setItem('language', initialLanguage);
    return initialLanguage;
}

export default i18n;
