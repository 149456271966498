import { createSlice, PayloadAction } from '@reduxjs/toolkit';


// Global constant for available languages
export const PREVIEW_LANGUAGES = [
    { code: 'en', label: 'English', originalLabel: 'English' },
    { code: 'pl', label: 'Polish', originalLabel: 'Polski' },
    { code: 'it', label: 'Italian', originalLabel: 'Italiano' },
    { code: 'es', label: 'Spanish', originalLabel: 'Español' },
    { code: 'jp', label: 'Japanese', originalLabel: '日本語' },
];


interface LanguageState {
    current: string;
    languages: string[];
}

// Get initial language from localStorage or default to 'en'
const getInitialLanguage = (): string => {
    const savedLanguage = localStorage.getItem('currentLanguage');
    if (savedLanguage && PREVIEW_LANGUAGES.some(lang => lang.code === savedLanguage)) {
        return savedLanguage;
    }
    return 'en';
};

const initialState: LanguageState = {
    current: getInitialLanguage(),
    languages: PREVIEW_LANGUAGES.map(lang => lang.code),
};

const languageSlice = createSlice({
    name: 'language',
    initialState,
    reducers: {
        setCurrentLanguage(state, action: PayloadAction<string>) {
            if (PREVIEW_LANGUAGES.some(lang => lang.code === action.payload)) {
                state.current = action.payload;
                // Save to localStorage
                localStorage.setItem('currentLanguage', action.payload);
            }
        },
        setSupportedLanguages(state, action: PayloadAction<string[]>) {
            // Filter out any unsupported languages
            const validLanguages = action.payload.filter(
                code => PREVIEW_LANGUAGES.some(lang => lang.code === code)
            );
            state.languages = validLanguages;
        }
    },
});

export const { setCurrentLanguage, setSupportedLanguages } = languageSlice.actions;
export default languageSlice.reducer;
