// File: src/components/Toggle.tsx

import React from 'react';

interface ToggleProps {
    enabled: boolean;
    onToggle: () => void;
    label: string;
}

const Toggle: React.FC<ToggleProps> = ({ enabled, onToggle, label }) => {
    return (
        <div className="flex justify-between w-full">
            <span className="mr-3 text-gray-700">{label}</span>
            <button
                onClick={onToggle}
                className={`relative inline-flex items-center h-6 rounded-full w-11 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${
                    enabled ? 'bg-blue-600' : 'bg-gray-300'
                }`}
                role="switch"
                aria-checked={enabled}
                aria-label={label}
            >
                <span
                    className={`transform transition-transform absolute left-1 top-1 bg-white rounded-full h-4 w-4 ${
                        enabled ? 'translate-x-5' : 'translate-x-0'
                    }`}
                />
            </button>
        </div>
    );
};

export default Toggle;
