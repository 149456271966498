import React, { useMemo, useState } from 'react';
import {
    Card,
    CardBody,
    Chip,
    Dialog,
    DialogBody,
    DialogFooter,
    DialogHeader,
    Button,
    Alert,
} from '@material-tailwind/react';
import { Link } from 'react-router-dom';
import SortIndicator from './SortIndicator';
import ActionButtons from './ActionButtons';
import {Order, Restaurant} from '../../types';
import dayjs from 'dayjs';
import {
    statusBadgeColor,
    paymentStatusColor,
    paymentMethodBadgeColor,
    statusOptions,
    paymentStatusOptions,
    paymentMethodOptions,
} from '../../utils';
import {t} from "i18next";
import PriceTag from "../Common/Price.tsx";

interface Props {
    restaurant?: Restaurant;
    orders: Order[];
    isAdmin: boolean;
    setOrders: React.Dispatch<React.SetStateAction<Order[]>>;
    onFieldUpdate: (id: string, field: string, value: string) => Promise<void>;
}

type FieldToUpdate = 'status' | 'paymentStatus' | 'paymentMethod';

const OrdersTable: React.FC<Props> = ({ restaurant, orders, isAdmin, setOrders, onFieldUpdate }) => {
    const [sortConfig, setSortConfig] = useState<{
        key: keyof Order | string;
        direction: 'ascending' | 'descending';
    } | null>(null);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [fieldToUpdate, setFieldToUpdate] = useState<FieldToUpdate | null>(null);
    const [selectedOrderId, setSelectedOrderId] = useState<string | null>(null);
    const [updateError, setUpdateError] = useState<string | null>(null);
    const [updating, setUpdating] = useState(false);

    const handleSort = (key: keyof Order) => {
        let direction: 'ascending' | 'descending' = 'ascending';
        if (sortConfig?.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const sortedOrders = useMemo(() => {
        let sortableOrders = [...orders];
        if (sortConfig) {
            sortableOrders.sort((a, b) => {
                const aKey = a[sortConfig.key as keyof Order] || '';
                const bKey = b[sortConfig.key as keyof Order] || '';

                if (aKey < bKey) return sortConfig.direction === 'ascending' ? -1 : 1;
                if (aKey > bKey) return sortConfig.direction === 'ascending' ? 1 : -1;
                return 0;
            });
        }
        return sortableOrders;
    }, [orders, sortConfig]);

    const openModal = (field: FieldToUpdate, orderId: string) => {
        setFieldToUpdate(field);
        setSelectedOrderId(orderId);
        setIsModalOpen(true);
        setUpdateError(null);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setFieldToUpdate(null);
        setSelectedOrderId(null);
        setUpdateError(null);
    };

    const handleUpdate = async (newValue: string) => {
        if (!selectedOrderId || !fieldToUpdate) return;

        setUpdating(true);
        setUpdateError(null);

        try {
            await onFieldUpdate(selectedOrderId, fieldToUpdate, newValue);
            setOrders((prevOrders) =>
                prevOrders.map((order) =>
                    order.id === selectedOrderId ? { ...order, [fieldToUpdate]: newValue } : order
                )
            );
            closeModal();
        } catch (err) {
            setUpdateError('Failed to update. Please try again.');
        } finally {
            setUpdating(false);
        }
    };

    const getOptionsForField = () => {
        switch (fieldToUpdate) {
            case 'status':
                return statusOptions;
            case 'paymentStatus':
                return paymentStatusOptions;
            case 'paymentMethod':
                return paymentMethodOptions;
            default:
                return [];
        }
    };

    return (
        <Card className="shadow-sm">
            <CardBody className="overflow-x-auto p-0">
                <table className="w-full min-w-max table-auto text-left">
                    <thead className="border-b-2">
                    <tr>
                        <th
                            className="px-6 py-3 text-left text-xs font-medium text-neutralDark uppercase tracking-wider cursor-pointer"
                            onClick={() => handleSort('orderNumber')}
                        >
                            {t('common.orderNumber')}{' '}
                            <SortIndicator
                                direction={sortConfig?.key === 'orderNumber' ? sortConfig.direction : null}
                            />
                        </th>
                        <th
                            className="px-6 py-3 text-left text-xs font-medium text-neutralDark uppercase tracking-wider cursor-pointer"
                            onClick={() => handleSort('createdAt')}
                        >
                            {t('common.created')}{' '}
                            <SortIndicator
                                direction={sortConfig?.key === 'createdAt' ? sortConfig.direction : null}
                            />
                        </th>
                        <th
                            className="px-6 py-3 text-left text-xs font-medium text-neutralDark uppercase tracking-wider cursor-pointer"
                            onClick={() => handleSort('status')}
                        >
                            {t('common.status')}{' '}
                            <SortIndicator
                                direction={sortConfig?.key === 'status' ? sortConfig.direction : null}
                            />
                        </th>
                        <th
                            className="px-6 py-3 text-left text-xs font-medium text-neutralDark uppercase tracking-wider cursor-pointer"
                            onClick={() => handleSort('paymentStatus')}
                        >
                            {t('common.paymentStatus')}{' '}
                            <SortIndicator
                                direction={sortConfig?.key === 'paymentStatus' ? sortConfig.direction : null}
                            />
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-neutralDark uppercase tracking-wider">
                            {t('common.totalAmount')}
                        </th>
                        {isAdmin && (
                            <th className="px-6 py-3 text-left text-xs font-medium text-neutralDark uppercase tracking-wider">
                                {t('common.actions')}
                            </th>
                        )}
                    </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-100">
                    {sortedOrders.map((order) => (
                        <tr key={order.id} className="hover:bg-gray-50">
                            <td className="px-6 py-4">
                                <Link to={`/orders/${order.id}`} className="text-primary font-bold text-lg">
                                    #{order.orderNumber}
                                </Link>
                            </td>
                            <td className="px-6 py-4">{dayjs(order.createdAt).fromNow()}</td>
                            <td className="px-6 py-4">
                                <Chip
                                    value={order.status}
                                    color={statusBadgeColor(order.status)}
                                    className="cursor-pointer"
                                    onClick={() => openModal('status', order.id)}
                                />
                            </td>
                            <td className="px-6 py-4">
                                <Chip
                                    value={order.paymentStatus}
                                    color={paymentStatusColor(order.paymentStatus)}
                                    className="cursor-pointer"
                                    onClick={() => openModal('paymentStatus', order.id)}
                                />
                            </td>
                            <td className="px-6 py-4"><PriceTag item={{
                                price: order.totalAmount,
                                restaurant: {
                                    currencySymbol: restaurant? restaurant.currencySymbol : order.currencySymbol,
                                    currencySymbolPosition: restaurant? restaurant.currencySymbolPosition : order.currencySymbolPosition
                                }} as any} />
                            </td>
                            {isAdmin && (
                                <td className="px-6 py-4">
                                    <ActionButtons
                                        id={order.id}
                                        name={`Order #${order.orderNumber}`}
                                        type="order"
                                        setItems={setOrders}
                                    />
                                </td>
                            )}
                        </tr>
                    ))}
                    {sortedOrders.length === 0 && (
                        <tr>
                            <td
                                colSpan={isAdmin ? 6 : 5}
                                className="px-6 py-4 text-center text-gray-500"
                            >
                                {t('common.noOrdersFound')}
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </CardBody>

            <Dialog size="sm" open={isModalOpen} handler={closeModal}>
                <DialogHeader>Update {fieldToUpdate}</DialogHeader>
                <DialogBody>
                    {updateError && <Alert color="red">{updateError}</Alert>}
                    <div className="flex flex-col space-y-3">
                        {getOptionsForField().map((option) => (
                            <Button
                                key={option.value}
                                color={option.color}
                                onClick={() => handleUpdate(option.value)}
                                disabled={updating}
                            >
                                {option.label}
                            </Button>
                        ))}
                    </div>
                </DialogBody>
                <DialogFooter>
                    <Button variant="text" color="red" onClick={closeModal}>
                        {t('common.cancel')}
                    </Button>
                </DialogFooter>
            </Dialog>
        </Card>
    );
};

export default OrdersTable;
